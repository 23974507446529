<app-loading *ngIf="loading" class="full-loader"></app-loading>
<div class="hol-modal hol-dialog-wrapper group-details-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      <i *ngIf="isCreate" class="fa fa-plus"></i>
      <i *ngIf="!isCreate" aria-hidden="true" class="fa fa-pencil"></i>
      <span class="title"> {{ modalTitle }}</span>
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div class="content-grid">
        <div class="content-column full-width">
          <!-- Sticky Header -->
          <div class="col full-width content-row content-title">
            <div class="content-row full-width">
              <mat-form-field *ngIf="isCreate" class="description-form-field full-width" tabindex="-1">
                <textarea
                  [matAutocomplete]="auto"
                  class="description-textarea"
                  formControlName="titleForm"
                  #autosize="cdkTextareaAutosize"
                  #message
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                  cdkAutosizeMinRows="1"
                  maxlength="{{ contentTextLength }}"
                  rows="1"
                  tabindex="1"
                  placeholder="{{
                    moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
                  }}"
                >
                </textarea>
                <mat-hint align="end">{{ message.value.length }} / {{ contentTextLength }}</mat-hint>
                <mat-autocomplete #auto="matAutocomplete" isOpen="filteredTitleList.length !== 0">
                  <mat-option (onSelectionChange)="selectGroup(option)" *ngFor="let option of filteredTitleList" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <mat-form-field *ngIf="!isCreate" class="description-form-field full-width" tabindex="-1">
                <textarea
                  class="description-textarea"
                  formControlName="titleForm"
                  matInput
                  #autosize="cdkTextareaAutosize"
                  #message
                  cdkTextareaAutosize
                  cdkAutosizeMaxRows="10"
                  cdkAutosizeMinRows="1"
                  rows="1"
                  tabindex="1"
                  maxlength="{{ contentTextLength }}"
                  placeholder="{{
                    moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.TITLE_PLACEHOLDER' | translate
                  }}"
                >
                </textarea>
                <mat-hint align="end">{{ message.value.length }} / {{ contentTextLength }}</mat-hint>
              </mat-form-field>
            </div>
            <div class="content-row full-width content-head-column">
              <div class="content-column">
                <span class="selection-text">{{
                  moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.SELECT_TEXT' | translate
                }}</span>
              </div>
              <div *ngIf="isInSummary && anyItemSelected" class="content-column">
                <span class="selection-text">
                  {{ 'ECL.DASHBOARD.STATUS_DECISIONS.MODALS.GROUP_MODAL.SYNTHESIS_PUBLISH' | translate }}
                </span>
              </div>
            </div>
          </div>
          <!-- List Item -->
          <div class="content-row full-width">
            <div *ngIf="displayItems.length; else noItem" class="event-group full-width content-column">
              <ul class="hol-list-grid">
                <ng-container *ngIf="displayItems.length > 0; else noItem">
                  <li *ngFor="let item of displayItems; let i = index">
                    <ng-container [formGroup]="getFormGroupById(item.objectId)">
                      <div class="div1">
                        <mat-checkbox
                          [formControlName]="'inGroup'"
                          [labelPosition]="'after'"
                          class="checkbox"
                          color="primary"
                          (click)="onSelectedItem(item.objectId)"
                        ></mat-checkbox>
                      </div>
                      <div class="div2">
                        <app-hol-list-item
                          class="itemcard"
                          [canUpdateItem]="false"
                          [isReadOnly]="true"
                          [item]="item"
                          [fromModule]="item.fromERP ? 'ERP' : null"
                          [toModule]="item.toERP ? 'ERP' : null"
                        >
                          <div class="header-addon">
                            <ng-container *ngIf="type === 'EVENT'">
                              <span *ngIf="item" class="checkbox-label scenario">{{ item.scenario.code }}{{ item.order }}</span>
                              <span *ngIf="item" class="checkbox-label description">{{ item.description }}</span>
                            </ng-container>
                            <ng-container *ngIf="type === 'LOGBOOK' || type === 'DECISION'">
                              <app-tag-chips [tags]="item.tags"></app-tag-chips>
                            </ng-container>
                            <div class="header-right">
                              <div *ngIf="item.isPinned" class="pinned">
                                <img class="pinned-item-icon-mini" src="/assets/images/pin--on.png" />
                              </div>
                              <div *ngIf="!item.isPinned" class="un-pinned"></div>
                              <div
                                *ngIf="item['function'] && item['function'].objectId && moduleConfig.config.moduleName === 'ecl'"
                                style="margin-bottom: 5px"
                              >
                                <app-ecl-user-function-detail
                                  (onSelectFunction)="onSelectFunction($event)"
                                  [localFilter]="true"
                                  [readonly]="true"
                                  [function]="item['function']"
                                ></app-ecl-user-function-detail>
                              </div>
                            </div>
                          </div>

                          <div class="hol-spaced-line addon">
                            <div class="col-attachements">
                              <app-file-and-image-icons-display [attachments]="item.attachments"></app-file-and-image-icons-display>
                            </div>
                            <div class="hol-spaced-line">
                              <div *ngIf="!item.done">
                                <i class="icon icon-check"></i>
                              </div>
                              <div class="hol-done" *ngIf="item.done">
                                <strong> {{ item.doneBy.monogram() }} - {{ item.updatedAt | time : isUtc }} </strong>
                                <i class="icon icon-check"></i>
                              </div>
                              <div
                                *ngIf="item.nextInfoDate"
                                [class.almost-elapsed]="isAlmostElapsed(item.nextInfoDate)"
                                [class.elapsed]="isElapsed(item.nextInfoDate)"
                                [class.hol-done]="item.done"
                                class="col-next-info"
                              >
                                <span>{{ item.nextInfoDate | time : isUtc }}</span>
                              </div>
                            </div>
                          </div>
                        </app-hol-list-item>
                        <div *ngIf="getAlreadyInGroups(item).length > 0" class="already-group-label div4">
                          <p *ngFor="let g of getAlreadyInGroups(item)" class="">
                            <mat-icon>scatter_plot</mat-icon>
                            <span>{{ g.title }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="div3">
                        <ng-container *ngIf="(type === 'LOGBOOK' || type === 'DECISION') && isInGroup(item.objectId); else emptySummary">
                          <mat-checkbox *ngIf="isInSummary" [formControlName]="'inSummaryGroup'" color="primary"></mat-checkbox>
                        </ng-container>
                        <ng-template #emptySummary>&nbsp;</ng-template>
                      </div>
                    </ng-container>
                  </li>
                </ng-container>
              </ul>
            </div>
            <ng-template #noItem>
              <div class="no-items">{{ 'COMMON.MODALS.NO_ITEMS' | translate }}</div>
            </ng-template>
          </div>
        </div>
        <div class="content-column full-width">
          <!-- Filters -->
          <div class="filter-action content-column full-width content-grid-button">
            <div class="filter-action-bloc">
              <p>{{ 'COMMON.MODALS.GROUP_BUTTONS.FILTER' | translate }}</p>
              <app-search-bar
                (newSearchCriteria)="onChangeSearchEvent($event)"
                [placeholder]="'.DASHBOARD.SEARCH'"
                class="search-group-modal"
                style="margin-bottom: 8px"
              ></app-search-bar>
              <app-tag-filter
                [(selectedTags)]="selectedTags"
                (selectedTagsChange)="filterItems()"
                [isFilterMode]="true"
                [isFilterButton]="true"
                [addForm]="true"
                [canAddTag]="false"
                [readOnly]="false"
              ></app-tag-filter>
              <ng-container>
                <button (click)="filterSelectedItemChange()" [ngClass]="{ toggled: filterSelectedItemActive }">
                  <span class="material-symbols-outlined iconsize">filter_list</span>
                  <span>{{
                    moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.ONLY_SELECTED' | translate
                  }}</span>
                </button>
              </ng-container>
              <ng-container>
                <button (click)="filterPinnedItemChange()" [ngClass]="{ toggled: filterPinnedItemActive }">
                  <span class="material-symbols-outlined iconsize">filter_list</span>
                  <span>{{
                    moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.ONLY_PINNED' | translate
                  }}</span>
                </button>
              </ng-container>
              <ng-container>
                <button (click)="filterNonCheckedItemChange()" [ngClass]="{ toggled: filterNonCheckedItemActive }">
                  <span class="material-symbols-outlined iconsize">filter_list</span>
                  <span>{{
                    moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.ONLY_NON_CHECKED' | translate
                  }}</span>
                </button>
              </ng-container>
            </div>

            <div *ngIf="isSummaryActivated" class="filter-action-bloc">
              <p>{{ 'COMMON.MODALS.GROUP_BUTTONS.ACTION' | translate }}</p>
              <app-ocl-summary
                *ngIf="isSummaryActivated"
                [(isInBriefing)]="isInBriefing"
                [(isInSummary)]="isInSummary"
                [invertedStyle]="true"
              >
              </app-ocl-summary>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions *ngIf="!isReadOnly">
    <div class="action-bar">
      <button (click)="onDeleteGroup()" *ngIf="!isCreate" class="action action-refuse" tabindex="-1">
        <span class="material-symbols-outlined">delete</span>
        <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.MODALS.GROUP_MODAL.DELETE_GROUP_TEXT' | translate }}</span>
      </button>
      <div class="spacer"></div>
      <button (click)="isCreate ? onCreateGroup() : onUpdateGroup()" [disabled]="isDisableAddButton()" class="action action-validate">
        <span><i aria-hidden="true" class="fa fa-check"></i></span>
        <span>{{ (isCreate ? addButtonTrad : updateButtonTrad) | translate }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
