import { HolUser, HolUserWithCompanies } from 'src/app/common/models/hol-user.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { HolTask } from '../../common/models/hol-task';
import { EclHistoryLog } from './ecl-history-log.model';
import { EclCrisis } from './ecl-crisis';
import { EclDecision } from './ecl-decision';

export class EclCrisisTaskRef extends HolTask {
  outputDataHint?: string;
  isDocumentOnly?: boolean;
  keywords?: string[];
  customOutputDataLabel?: string;
  crisisTypeId: string;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]) {
    super(parseObject, tags, defaultTags);
    if (!parseObject) {
      return;
    }

    this.isConditionalTask = parseObject.get('isConditionalTask') ? parseObject.get('isConditionalTask') : false;
    this.visibleBy = parseObject.get('visibleBy') ? parseObject.get('visibleBy') : 'ALL';

    this.outputDataLabel = parseObject.get('outputDataLabel') ? parseObject.get('outputDataLabel') : null; // 'Libellé de la valeur de sortie de la tâche. Ex : "Nom du CEO :"',
    this.customOutputDataLabel = parseObject.get('customOutputDataLabel');
    this.outputDataHint = parseObject.get('outputDataHint'); // " Texte utilisé dans le placeholder lorsqu'un outputDataLabel est requis. ",
    this.keywords = parseObject.get('keywords');
    this.crisisTypeId = parseObject.get('crisisTypeId');
    this.updatedBy = new HolUser(parseObject.get('updatedBy'));
    this.isDocumentOnly = parseObject.get('isDocumentOnly');
    this.subOrder = parseObject.get('subOrder') ? parseObject.get('subOrder') : '';
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('isDocumentOnly')
      ? parseObject.get('outputDataLabel') || parseObject.get('customOutputDataLabel')
      : parseObject.get('taskTitle');
  }
}

export class EclCrisisTask extends EclCrisisTaskRef {
  crisis?: EclCrisis;
  isEditable: boolean = false;
  decision?: EclDecision;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]) {
    super(parseObject, tags, defaultTags);
    if (!parseObject) {
      return;
    }
    this.crisis = parseObject.get('crisis') ? new EclCrisis(parseObject.get('crisis')) : null;
    this.isEditable = parseObject.get('isEditable') ? parseObject.get('isEditable') : false;
    this.decision = parseObject.get('decision') ? new EclDecision(parseObject.get('decision')) : null;
  }

  get isRequired() {
    return !!(this.outputDataLabel && this.outputDataLabel?.trim());
  }

  get isMissingFile() {
    return this.isRequired && this.attachments?.isEmpty();
  }
}

/*
export class ErpCrisisDocument extends ErpCrisisTask {
  constructor(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]) {
    super(parseObject, tags, defaultTags);
  }

  protected getContentText?(parseObject: Parse.Object): string {
    return parseObject.get('outputDataLabel') || parseObject.get('customOutputDataLabel');
  }
}

*/

export interface EclCrisisTaskStream {
  shortTitle: string;
  title: string;
  hasHolder: boolean;
  holder: HolUserWithCompanies;
  tasksDetails: {
    total: number;
    done: number;
    frozen: number;
    notApplicable: number;
    todo: number;
    completionRate: number;
  };
}

export interface EclCrisisUserTask {
  functionInfos: FunctionInfos[];
  tasks: EclCrisisTask[];
}

export interface FunctionInfos {
  shortTitle: string;
  title: string;
  isHolder: boolean;
  functionId: string;
}

/*
export interface ErpCrisisDocumentByCategory {
  category: ErpFunctionCategory;
  documents: ErpCrisisDocument[];
}
*/
export enum EclVisiblityStatus {
  all = 'ALL',
  group = 'GROUP',
  erd = 'ERD',
}
