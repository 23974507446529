import { Inject, Injectable } from '@angular/core';
import { RequestService } from '../../../common/services/request.service';
import { OclHistoryService } from '../../../ocl/services/ocl-history-service/ocl-history.service';
import { EclOptionsService } from '../ecl-options-service/ecl-options.service';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { EclHistoryLog } from '../../models/ecl-history-log.model';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';

@Injectable({
  providedIn: 'root',
})
export class EclHistoryService extends OclHistoryService<EclHistoryLog> {
  // tslint:disable:variable-name
  protected ParseHistory = Parse.Object.extend('ECLLogs');
  protected ParseEvents = Parse.Object.extend('ECLEvents');
  protected ParseDecisions = Parse.Object.extend('ECLDecisions');
  protected ParseLogbooks = Parse.Object.extend('ECLLogBook');
  protected ParseEclCrisis = Parse.Object.extend('ECLCrisis');
  protected ParseEclCrisisTask = Parse.Object.extend('ECLTask');
  protected eclOpenCrisis: EclCrisis;

  // tslint:enable

  public constructor(
    protected requestService: RequestService,
    @Inject('$rootScope') protected $rootScope,
    protected optionsService: EclOptionsService,
    protected parseMapper: ParseMapperService,
    protected moduleConfig: EclModuleConfigService,
    protected eclCrisisStore: EclCrisisStoreManager,
  ) {
    super(requestService, $rootScope, optionsService, parseMapper, moduleConfig);
    this.eclCrisisStore.$eclSelectedCrisis.subscribe(c => (this.eclOpenCrisis = c));
  }

  protected setAdditionalFields(inputLog: EclHistoryLog, parseObject: Parse.Object) {
    super.setAdditionalFields(inputLog, parseObject);
    parseObject.set(
      'crisis',
      new this.ParseEclCrisis({
        id: this.eclOpenCrisis.objectId,
      }),
    );
  }

  protected setAdditionalFilters(query: Parse.Query) {
    super.setAdditionalFilters(query);
    const queryLinkedCrisis = new Parse.Query(this.ParseEclCrisis);
    queryLinkedCrisis.equalTo('objectId', this.eclOpenCrisis.objectId);

    query.matchesQuery('crisis', queryLinkedCrisis);
  }

  protected newHistoryLog(parseObject: Parse.Object): EclHistoryLog {
    return new EclHistoryLog(parseObject);
  }

  public async getLastHistoryLog(crisisId: string): Promise<Date> {
    const query = new Parse.Query(this.ParseHistory);
    const crisisQuery = new Parse.Query(this.ParseEclCrisis);
    crisisQuery.equalTo('objectId', crisisId);
    query.matchesQuery('crisis', crisisQuery);
    query.include('crisis');
    query.addDescending('updatedAt');
    const result = await this.requestService.performFirstQuery(query);
    if (result) {
      return result.get('updatedAt');
    }
    return null;
  }

  getHistoryByObjectIdAndType(objectId: string, type: string): Promise<EclHistoryLog[]> {
    let historyLogs: EclHistoryLog[];
    let parseType;
    if (type === 'logbook') {
      parseType = new this.ParseLogbooks({ id: objectId });
    }
    if (type === 'decision') {
      parseType = new this.ParseDecisions({ id: objectId });
    }
    if (type === 'event') {
      parseType = new this.ParseEvents({ id: objectId });
    }
    if (type === 'holTask') {
      parseType = new this.ParseEclCrisisTask({ id: objectId });
      //  parseType = new this.ParseTask({ id: objectId });
    }
    if (type === 'task') {
      parseType = new this.ParseEclCrisisTask({ id: objectId });
    }

    const historyLogsQuery = new Parse.Query(this.ParseHistory);
    historyLogsQuery.include('ACL');
    historyLogsQuery.equalTo('type', type);
    historyLogsQuery.equalTo(type == 'holTask' ? 'task' : type, parseType);
    historyLogsQuery.descending('createdAt');
    return this.requestService.performFindAllQuery(historyLogsQuery).then(historyLogsFromApi => {
      if (historyLogsFromApi) {
        historyLogs = historyLogsFromApi.map(historyLogFromApi => this.newHistoryLog(historyLogFromApi));
        return historyLogs;
      } else {
        return [];
      }
    });
  }

  postLog(log: EclHistoryLog): Promise<Parse.Object> {
    const parseLog = new this.ParseHistory();
    parseLog.set('comment', log.comment);
    parseLog.set('type', log.type);
    parseLog.set('subType', log.subType);
    parseLog.set('user', Parse.User.current());
    parseLog.set('attachments', log.attachments);
    parseLog.set('jsonObject', { ...log.jsonObject });
    if (log.details && log.details.length > 0) {
      parseLog.set('details', log.details.join('|'));
    }
    if (log.acl) {
      parseLog.setACL(log.acl);
    }
    if (log.decision) {
      parseLog.set('decision', new this.ParseDecisions({ id: log.decision.objectId }));
    }
    if (log.logbook) {
      parseLog.set('logbook', new this.ParseLogbooks({ id: log.logbook.objectId }));
    }
    if (log.event) {
      parseLog.set('event', new this.ParseEvents({ id: log.event.objectId }));
    }
    if (log.task) {
      parseLog.set('holTask', new this.ParseTask({ id: log.task.objectId }));
    }
    if (log.eclTask) {
      parseLog.set('task', new this.ParseEclCrisisTask({ id: log.eclTask.objectId }));
    }
    if (log.vacation) {
      parseLog.set('holVacation', new this.ParseVacation({ id: log.vacation.objectId }));
    }

    this.setAdditionalFields(log, parseLog);
    return this.requestService.performSaveQuery(parseLog).then(savedLog => {
      this.logs.next(this.newHistoryLog(savedLog));
      return savedLog;
    });
  }
}
