<!--PREVIEW used for pdf-->
<div class="content-row">
  <ng-container *ngIf="myFiles && myFiles.length > 0; else empty">
    <div class="attachment-toolbar">
      <div>
        <button
          *ngIf="!disabled"
          (click)="openAttachmentModal('files')"
          [disabled]="disabled"
          class="square upload"
          matTooltip="{{ 'COMMON.ATTACHMENT.TOOLTIP.UPLOAD' }}"
        >
          <span class="material-symbols-outlined">upload</span>
        </button>
      </div>
    </div>
    <div *ngFor="let myFile of myFiles" class="row-file">
      <div class="picture-preview">
        <picture (click)="openFile(myFile.url)">
          <img
            [ngClass]="{ files: myFile.type !== TypeFile.image, image: myFile.type === TypeFile.image }"
            [src]="myFile.urlImage"
            alt="{{ myFile.title }}"
          />
        </picture>
      </div>
      <div class="row-description">
        <div class="file-description">
          <span>{{ myFile.title }}</span>
        </div>
        <div class="file-name">
          <span *ngIf="!myFile.title">{{ myFile.fileName }}</span>
        </div>
        <div class="file-action">
          <button
            *ngIf="!disabled"
            (click)="removeHolFilesData($event, myFile)"
            [disabled]="disabled"
            class="square upload"
            matTooltip="{{ 'COMMON.ATTACHMENT.TOOLTIP.DELETE' }}"
          >
            <span class="material-symbols-outlined">delete</span>
          </button>
          <button
            *ngIf="!disabled"
            (click)="editHolFilesTitle($event, myFile)"
            [disabled]="disabled"
            class="square edit"
            matTooltip="{{ 'COMMON.ATTACHMENT.TOOLTIP.EDIT_TITLE' }}"
          >
            <span class="material-symbols-outlined">edit</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #empty>
    <div *ngIf="!disabled" (click)="!disabled && openAttachmentModal('files')" class="no-attachment">
      <div class="no-attachment-content">
        <span [ngClass]="{ required: required && attachments && attachments.isEmpty }" class="material-symbols-outlined">upload</span>
        <span [ngClass]="{ required: required && attachments && attachments.isEmpty }">{{ 'COMMON.ATTACHMENT_EMTPY' | translate }}</span>
      </div>
    </div>
  </ng-template>
</div>
