import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { UpperCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { ModuleConfigService } from '../../services/module-config/module-config.service';

@Component({
  selector: 'app-edition-title-document-modal',
  templateUrl: './edition-title-document-modal.component.html',
  styleUrls: ['./edition-title-document-modal.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    TranslateModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    UpperCasePipe,
  ],
})
export class EditionTitleDocumentModalComponent implements OnInit {
  public titleTranslateKey: string;
  public title: string;
  public tmpTitle: string;
  public placeholderTranslateKey: string;
  public fileName: string;
  public maxLengthTitle = 64;
  public maxLengthPlaceholder = 32;

  constructor(
    public moduleConfig: ModuleConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditionTitleDocumentModalComponent>,
  ) {}

  ngOnInit(): void {
    this.titleTranslateKey = this.moduleConfig.config.translateKey + '.DASHBOARD.DOCUMENT.EDIT_TITLE_DOCUMENT';
    this.title = this.data.title;
    this.tmpTitle = cloneDeep(this.title);
    this.placeholderTranslateKey = this.moduleConfig.config.translateKey + '.DASHBOARD.DOCUMENT.PLACEHOLDER_TITLE_DOCUMENT';
    this.fileName =
      this.data.fileName.length > this.maxLengthPlaceholder
        ? this.data.fileName.slice(0, this.maxLengthPlaceholder - 4) + ' ... ' + this.data.fileName.slice(-4)
        : this.data.fileName;
  }

  public closeModal(): void {
    this.dialogRef.close({ title: this.title });
  }

  public saveAttachment(): void {
    if (this.tmpTitle) {
      this.title = this.tmpTitle;
    }
    this.closeModal();
  }
}
