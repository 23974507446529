import { MailTemplateService } from '../../../common/services/mail/mail-template.service';
import { OclTask } from '../../models/ocl-task';
import { inject, Inject, Injectable } from '@angular/core';

import { map } from 'lodash';
import { MailSenderService } from 'src/app/common/services/mail/mail-sender.service';

import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclDecision } from '../../models/ocl-decision.model';
import { EventInfos, HolEvent } from '../../../common/models/hol-event';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { OclGlobalInstruction } from '../../models/ocl-global-instruction.model';
import { TranslateService } from '@ngx-translate/core';
import { EclCrisisTask } from '../../../ecl/models/ecl-crisis-task-ref';

@Injectable({
  providedIn: 'root',
})
export abstract class OclMailService {
  protected SENDER_SUFFIX = '';

  protected readonly translate = inject(TranslateService);

  protected constructor(
    protected readonly mailSenderService: MailSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected mailTemplateService: MailTemplateService,
    protected moduleConfig: ModuleConfigService,
  ) {}

  public sendNewDecisionMail<T extends OclDecision>(decision: T, mails: string[]) {
    if (!decision) {
      console.warn('MailService::sendNewDecisionMail: decision empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewDecisionMail: no mailing list found');
    }
    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_DECISION.SUBJECT'),
        contentHtml: this.mailTemplateService.getDecisionMessage(decision),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  public sendNewGlobalInstructionMail<T extends OclGlobalInstruction>(globalInstruction: T, mails: string[]) {
    if (!globalInstruction) {
      console.warn('MailService::sendNewGlobalInstructionMail: globalInstruction empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewGlobalInstructionMail: no mailing list found');
    }
    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_GLOBAL_INSTRUCTION.SUBJECT'),
        contentHtml: this.mailTemplateService.getGlobalInstructionMessage(globalInstruction),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  public sendEclActivatedMail(event: HolEvent, infos: EventInfos[], mails: string[]) {
    if (!event) {
      console.warn('MailService::sendEclActivatedMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendEclActivatedMail: no mailing list found');
    }
    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.ECL_ACTIVATED_SUBJECT', {
          code: event.scenario ? event.scenario.code : undefined,
          order: event.order,
          scenario: event.scenario ? '- ' + event.scenario.title : undefined,
        }),
        contentHtml: this.mailTemplateService.getEclActivatedMessage(event, infos, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  public sendNewEventMail(event: HolEvent, infos: EventInfos[], mails: string[]) {
    if (!event) {
      console.warn('MailService::sendNewEventMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewEventMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_EVENT.SUBJECT', {
          code: event.scenario ? event.scenario.code : undefined,
          order: event.order,
          scenario: event.scenario ? '- ' + event.scenario.title : undefined,
        }),
        contentHtml: this.mailTemplateService.getEventMessage(event, infos, false, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  public sendNewEventInfoMail(event: HolEvent, eventInfo: HolNextInfo, mails: string[]) {
    if (!event) {
      console.warn('MailService::sendNewEventInfoMail: event empty');
    }
    if (!eventInfo) {
      console.warn('MailService::sendNewEventInfoMail: eventInfo empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewEventInfoMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_INFO.SUBJECT', {
          code: event.scenario ? event.scenario.code : undefined,
          order: event.order,
          scenario: event.scenario ? ' - ' + event.scenario.title : undefined,
          nbInfos: event.infos.length,
        }),
        contentHtml: this.mailTemplateService.getNewEventInfoMessage(event, eventInfo, false, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  public sendCloseEventMail(event, mails, closeDate) {
    if (!event) {
      console.warn('MailService::sendCloseEventMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendCloseEventMail: no mailing list found');
    }
    let closeReason;
    if (event.closeReason === 0) {
      closeReason = this.translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_1');
    } else if (event.closeReason === 1) {
      closeReason = this.translate.instant(this.moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CLOSE_REASON_2');
    } else {
      closeReason = event.closeReasonText;
    }

    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.CLOSE_EVENT.SUBJECT', {
          code: event.scenario ? event.scenario.code : undefined,
          order: event.order,
          scenario: event.scenario ? ' - ' + event.scenario.title : undefined,
        }),
        contentHtml: this.mailTemplateService.getCloseEventMessage(event, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
        variables: {
          subtitle: closeReason,
        },
      },
      true,
      true,
    );
  }

  public sendNewEventChecklistActivatedMail(event, infos, mails) {
    if (!event) {
      console.warn('MailService::sendNewEventChecklistActivatedMail: event empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewEventChecklistActivatedMail: no mailing list found');
    }

    const subject = this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_EVENT.SUBJECT', {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      scenario: event.scenario ? '- ' + event.scenario.title : undefined,
    });

    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject,
        contentHtml: this.mailTemplateService.getEventMessage(event, infos, true, this.moduleConfig.config.translateKey),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  public sendNewEventInfoChecklistActivatedMail(event, infos, mails) {
    if (!event) {
      console.warn('MailService::sendNewEventInfoChecklistActivatedMail: event empty');
    }
    if (!infos) {
      console.warn('MailService::sendNewEventInfoChecklistActivatedMail: eventInfo empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewEventInfoChecklistActivatedMail: no mailing list found');
    }

    const subject = this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_EVENT_INFO.SUBJECT', {
      code: event.scenario ? event.scenario.code : undefined,
      order: event.order,
      scenario: event.scenario ? ' - ' + event.scenario.title : undefined,
      nbInfos: event.infos.length,
    });

    infos.forEach(eventInfo => {
      this.mailSenderService.sendMail(
        {
          recipients: mails.map(m => {
            return { email: m };
          }),
          subject,
          contentHtml: this.mailTemplateService.getNewEventInfoMessage(event, eventInfo, true, this.moduleConfig.config.translateKey),
          sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
        },
        true,
        true,
      );
    });
  }

  public sendNewLogbookMail(logbook, mails) {
    if (!logbook) {
      console.warn('MailService::sendNewLogbookMail: logbook empty');
    }
    if (!mails || !mails.length) {
      console.warn('MailService::sendNewLogbookMail: no mailing list found');
    }
    this.mailSenderService.sendMail(
      {
        recipients: mails.map(m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.NEW_LOGBOOK.SUBJECT'),
        contentHtml: this.mailTemplateService.getLogbookMessage(logbook),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  sendTaskDoneMail(task: OclTask | EclCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!task) {
      console.warn('OclMailService::sendTaskDoneMail: task empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('OclMailService::sendTaskDoneMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_DONE.SUBJECT'),
        contentHtml: this.mailTemplateService.getTaskDoneMail(task, this.moduleConfig.config.translateKey, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  sendTaskFrozenMail(task: OclTask | EclCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!task) {
      console.warn('OclMailService::sendTaskFrozenMail: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('OclMailService::sendTaskFrozenMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_FROZEN.SUBJECT'),
        contentHtml: this.mailTemplateService.getTaskFrozenMail(task, this.moduleConfig.config.translateKey, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }

  sendTaskBackTodoMail(task: OclTask | EclCrisisTask, functionTitle: string, comment: string, mailsToSend: string[]) {
    if (!task) {
      console.warn('OclMailService::sendTaskBackTodoMail: crisisTask empty');
    }
    if (!mailsToSend || !mailsToSend.length) {
      console.warn('OclMailService::sendTaskBackTodoMail: no mailing list found');
    }

    this.mailSenderService.sendMail(
      {
        recipients: map(mailsToSend, m => {
          return { email: m };
        }),
        subject: this.translate.instant('MAIL.' + this.moduleConfig.config.translateKey + '.TASK_TO_TODO.SUBJECT'),
        contentHtml: this.mailTemplateService.getTaskBackTodoMail(task, this.moduleConfig.config.translateKey, functionTitle, comment),
        sender: this.CONSTANTS.COMPANY_NAME + ' ' + this.SENDER_SUFFIX,
      },
      true,
      true,
    );
  }
}
