import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModuleConfigService } from '../../services/module-config/module-config.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  @Output() newSearchCriteria = new EventEmitter<string>();
  @Input() placeholder: string;
  public localSearchCriteria: string;

  constructor(public moduleConfig: ModuleConfigService) {}

  public onSearchEvent(newValue): void {
    this.newSearchCriteria.emit(newValue);
  }

  public onNgModelChange(): void {
    this.newSearchCriteria.emit(this.localSearchCriteria);
  }
}
