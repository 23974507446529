import { Action } from '@ngrx/store';
import { EclLayout } from '../../ecl.model';

export enum LayoutActionTypes {
  SET_TASKS_FILTER = '[ECL LAYOUT] Set Task Filter',
  SET_SEARCH_VALUE_FILTER = '[ECL LAYOUT] Set Search Value Filter',
  SET_FILTERS_PANEL_STATUS = '[ECL LAYOUT] Set Filter Panel Status (mobile)',
}

export class SetTaskFilter implements Action {
  readonly type = LayoutActionTypes.SET_TASKS_FILTER;

  constructor(public payload: Partial<EclLayout>) {}
}

export class SetSearchValueFilter implements Action {
  readonly type = LayoutActionTypes.SET_SEARCH_VALUE_FILTER;

  constructor(public payload: string) {}
}

export class SetFiltersPanelStatus implements Action {
  readonly type = LayoutActionTypes.SET_FILTERS_PANEL_STATUS;

  constructor(public payload: boolean) {}
}

export type LayoutActions = SetTaskFilter | SetSearchValueFilter | SetFiltersPanelStatus;
