import { OclEvent } from 'src/app/ocl/models/ocl-event.model';
import { HolUser } from 'src/app/common/models/hol-user.model';
import { HolHistoryLog } from '../../common/models/hol-history-log.model';
import { HolAttachments } from '../../common/models/hol-attachments.model';
import { OclDecision } from './ocl-decision.model';
import { OclLogbook } from './ocl-logbook.model';
import { OclTask } from './ocl-task';
import { HolVacation } from 'src/app/common/models/hol-vacation.model';

export class OclHistoryLog extends HolHistoryLog {
  comment: string;
  moment: Date;
  shiftSupervisor?: HolUser;
  task?: OclTask;
  event?: OclEvent;
  logbook?: OclLogbook;
  decision?: OclDecision;
  vacation?: HolVacation;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.comment = parseObject.get('comment');
    this.moment = parseObject.get('createdAt');
    if (parseObject.get('shiftSupervisorManagerUser')) {
      this.shiftSupervisor = new HolUser(parseObject.get('shiftSupervisorManagerUser'));
    } else if (parseObject.get('shiftSupervisorUser')) {
      this.shiftSupervisor = new HolUser(parseObject.get('shiftSupervisorUser'));
    }
    if (parseObject.get('event')) {
      this.event = new OclEvent(parseObject.get('event'));
    }
    if (parseObject.get('logbook')) {
      this.logbook = new OclLogbook(parseObject.get('logbook'));
    }
    if (parseObject.get('decision')) {
      this.decision = new OclDecision(parseObject.get('decision'));
    }
    if (parseObject.get('holTask')) {
      this.task = new OclTask(parseObject.get('holTask'));
    }
    if (parseObject.get('holVacation')) {
      this.vacation = new HolVacation(parseObject.get('holVacation'));
    }
  }

  static create(
    comment: string,
    type: 'logbook' | 'decision' | 'event' | 'managers' | 'holTask' | 'flightsStatus' | 'holVacation' | 'task',
    subType?: string,
    attachments?: HolAttachments,
    jsonObject?: any,
    acl?: Parse.ACL,
    object?: Parse.Object,
  ): OclHistoryLog {
    const log = new OclHistoryLog();
    log.comment = comment;
    log.type = type;
    log.subType = subType;
    log.attachments = attachments;
    log.jsonObject = jsonObject;
    log.acl = acl;
    if (type === 'logbook') {
      log.logbook = new OclLogbook(object);
    } else if (type === 'decision') {
      log.decision = new OclDecision(object);
    } else if (type === 'event') {
      log.event = new OclEvent(object);
    } else if (type === 'holTask') {
      log.task = new OclTask(object);
    } else if (type === 'holVacation') {
      log.vacation = new HolVacation(object);
    }

    return log;
  }
}
