import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-file-upload-attachment-modal',
  templateUrl: './file-upload-attachment-modal.component.html',
  styleUrls: ['./file-upload-attachment-modal.component.scss'],
})
export class FileUploadAttachmentModalComponent implements OnInit {
  public attachment: any;
  public tmpFile: any;
  public type: string;
  public isEdition: boolean;
  public titleTranslateKey: string;
  public placeholderTranslateKey: string;
  public saveButtonIsDisable: boolean;
  public onUploadSuccess: EventEmitter<any> = new EventEmitter<any>();
  public context: any;
  public isOnDashboard: boolean;
  public isDataOutputLabel: boolean;
  public displayImageTab: boolean = true;
  public displayLinkTab: boolean = true;
  public column: any;
  public disabled: boolean = false;
  public uploading: boolean = false;
  public hasTemp: boolean = false;
  public maxLengthTitle = 64;

  @Output() uploadingState: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('tabgroup', { static: true }) tabgroup: MatTabGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FileUploadAttachmentModalComponent>) {
    this.context = this.data.context;
    this.isOnDashboard = this.data.isOnDashboard;
  }

  public ngOnInit(): void {
    this.context = this.data.context;
    this.isEdition = this.checkIfDataAttachmentExist();
    //  this.saveButtonIsDisable = !this.isEdition;
    //  this.disabled = !this.isEdition;
    this.type = this.data.type;
    this.displayImageTab = this.data.displayImageTab;
    this.displayLinkTab = this.data.displayLinkTab;
    this.titleTranslateKey = 'COMMON.MODALS.ATTACHMENT.ADD.' + this.type.toUpperCase();
    this.placeholderTranslateKey = 'COMMON.MODALS.ATTACHMENT.PLACEHOLDER_TITLE.' + this.type.toUpperCase();
    this.tmpFile = this.type !== 'files' ? this.data.attachment : { title: '', url: '' };

    if (this.context.task) {
      this.isDataOutputLabel = !!this.context.task.outputDataLabel;
    } else {
      this.isDataOutputLabel = false;
    }
  }

  public checkIfHttps(event: string): void {
    this.saveButtonIsDisable = !event || !event.startsWith('https://');
  }

  public checkIfDataAttachmentExist(): boolean {
    return this.data.attachment.length > 0 && !!this.data.attachment[0].url;
  }

  public onUploadFileSuccess($event): void {
    this.uploading = false;
    this.uploadingState.emit(this.uploading);
    this.saveButtonIsDisable = false;
    this.tmpFile = { ...$event, title: this.tmpFile.title };
    this.hasTemp = true;
  }

  public startUpload($event: boolean): void {
    this.saveButtonIsDisable = $event;
    this.uploading = true;
    this.uploadingState.emit(this.uploading);
  }

  public saveAttachment(): void {
    if (this.tmpFile.uploadedUrl || this.type === 'link') {
      this.attachment = this.tmpFile;
    }
    this.closeModal();
  }

  public closeModal(): void {
    this.dialogRef.close({ attachment: this.attachment, type: this.type });
  }

  public indexOld: number = 0;

  public changeIndexTab(tab: MatTabGroup, event: number): void {
    if (tab.selectedIndex != this.indexOld) {
      if (this.hasTemp || this.uploading) {
        tab.selectedIndex = this.indexOld;
      } else {
        tab.selectedIndex = event;
        this.indexOld = event;
      }
    }

    switch (event) {
      case 0:
        this.type = 'files';
        break;
      case 1:
        this.type = 'link';
        break;
      case 2:
        this.type = 'image';
        break;
    }
  }
}
