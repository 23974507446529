import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HolObject } from '../../models/hol-object';
import { ModuleConfigService } from '../../services/module-config/module-config.service';
import { nextInfoType } from '../hol-next-info/hol-next-info.component';
import { HolAttachments } from '../../models/hol-attachments.model';
import { HolUTCDisplay, utcEval } from '../../enums/hol-utc-display.enum';
import { HolTask } from '../../models/hol-task';
import { EclLogbook } from '../../../ecl/models/ecl-logbook';
import { EclDecision } from '../../../ecl/models/ecl-decision';
import { EclSummary } from '../../../ecl/models/ecl-summary';
import { OclGroup } from '../../../ocl/models/ocl-group.model';
import { EclLogbookGroup } from '../../../ecl/models/ecl-logbook-group.model';
import { EclDecisionGroup } from '../../../ecl/models/ecl-decision-group.model';

@Component({
  selector: 'app-hol-list-item',
  templateUrl: './hol-list-item.component.html',
  styleUrls: ['./hol-list-item.component.scss'],
})
export class HolListItemComponent implements OnInit {
  @Input() item: HolObject;
  @Input() utcDisplay: HolUTCDisplay = HolUTCDisplay.LOCAL_IF_READONLY;
  @Input() isReadOnly = false;
  @Input() displayAllTextInItem = false;
  @Input() isHistory = false;
  @Input() fromModule: string;
  @Input() toModule: string;
  @Input() nextInfo: nextInfoType;
  @Input() canUpdateItem = false;
  @Input() userFunctionsList = [];
  @Input() displayUser = true;
  @Input() isRestricted = false;
  @Input() isRestrictedInfos = '';
  @Input() hideCreatedBy = false;
  @Input() public summaryGroup?: EclSummary;
  @Input() public group?: OclGroup<any>;
  @Input() attachments: HolAttachments = new HolAttachments();

  public userTooltipContent = '';
  @Output() saveNextInfo = new EventEmitter<nextInfoType>();

  constructor(public moduleConfig: ModuleConfigService) {}

  public get isUtc(): boolean {
    if (this.isHistory) {
      return true;
    }
    return utcEval(this.utcDisplay, this.isReadOnly);
  }

  ngOnInit() {
    this.userTooltipContent = this.item.createdBy ? this.item.createdBy.fullName : '';

    if (this.userFunctionsList) {
      let userFunctionTitles = '';
      userFunctionTitles += this.userFunctionsList.map(f => {
        if (f) {
          return f.shortTitle;
        }
      });
      this.userTooltipContent += userFunctionTitles.trim().length > 0 ? ' (' + userFunctionTitles + ')' : '';
    }

    if (!this.item) {
      throw new Error('HolListItemComponent::item is required');
    }
  }

  isOnSummary(): boolean {
    return !!this.item['summary'];
  }

  isOnSummaryGroup(): boolean {
    if (this.group) {
      if (this.group['logbooksInSummary']) {
        const logbook: EclLogbook = this.group['logbooksInSummary'].find((logbook: EclLogbook): boolean => {
          return logbook.objectId === this.item.objectId;
        });
        if (logbook) {
          return true;
        }
      } else if (this.group['decisionsInSummary']) {
        const decision: EclDecision = this.group['decisionsInSummary'].find((decision: EclDecision): boolean => {
          return decision.objectId === this.item.objectId;
        });
        if (decision) {
          return true;
        }
      }
      return false;
    }
  }

  isOnSummaryBriefing(): boolean {
    if (this.item['summary']) {
      return !!this.item['summary'].isDisplayInBriefing;
    }
    return false;
  }

  markAsDone() {
    this.saveNextInfo.emit(this.nextInfo);
  }

  isDisplayDate(): boolean {
    if (this.item.customCreatedAt) {
      return true;
    } else if (this.item.createdAt) {
      return true;
    }
    return false;
  }

  getDisplayDate(): Date {
    if (this.item.customCreatedAt) {
      return this.item.customCreatedAt;
    }
    return this.item.createdAt;
  }
}
