<div class="hol-search-container hol-search-container--align-left sm">
  <input
    (ngModelChange)="onSearchEvent($event)"
    [(ngModel)]="localSearchCriteria"
    [placeholder]="moduleConfig.config.translateKey + placeholder | translate"
    type="text"
    class="mw-20"
  />
  <i class="icon icon-glass"></i>
</div>
