import { OclEvent } from './ocl-event.model';
import { HolNextInfo } from '../../common/models/hol-next-info.model';
import moment from 'moment';
import { OclGroup } from './ocl-group.model';

export class OclEventGroup<T extends OclEvent = OclEvent> extends OclGroup<T> {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = parseObject.get('crisis'); // Add this line to assign the 'crisis' property

    const itemsParse: T[] =
      parseObject.get('events') && parseObject.get('events').length ? parseObject.get('events').map(lg => new OclEvent(lg) as T) : [];
    this.items = itemsParse.sort((a, b) => {
      const dateA = new Date(a.customCreatedAt || a.createdAt).getTime() || 0;
      const dateB = new Date(b.createdAt).getTime() || 0;
      return dateB - dateA;
    });
  }

  public get getMostCriticalNextInfo(): HolNextInfo | null {
    let mostRecent: HolNextInfo = null;
    this.items.forEach(event => {
      if (event.infos) {
        event.infos.forEach(info => {
          if (!info.done) {
            if (mostRecent === null) {
              mostRecent = info;
            }

            if (moment(info.nextInfoTime).isBefore(mostRecent.nextInfoTime)) {
              mostRecent = info;
            }
          }
        });
      } else {
        mostRecent = null;
      }
    });

    return mostRecent;
  }

  protected newItem(parseObject?: Parse.Object): T {
    return new OclEvent(parseObject) as T;
  }
}
