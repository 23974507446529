<app-loading *ngIf="loading" class="full-loader"></app-loading>
<app-hol-modal
  (changeItems)="onChangeHolModalConfig($event)"
  (saveItem)="saveDecision($event)"
  *ngIf="config$ | async as config"
  [canArchive]="false"
  [canEditTitle]="!isHistory"
  [config$]="config$"
  [config]="config"
  [contentTextLength]="200"
  [context]="context"
  [disabledValidateActions]="decision.isTodo && !nextInfo?.nextInfoTime"
  [fastMode]="isFastTyping()"
  [historyItemComponent]="data.itemComponent"
  [historyItemListMode]="true"
  [historyItemObjectId]="decision.objectId"
  [historyItemType]="'decision'"
  [historyTitle]="moduleConfig.config.translateKey + '.MODALS.HISTORY.DECISION'"
  [isCreate]="isCreate"
  [isHistory]="isHistory"
  [isReadOnly]="isReadOnly || isHistory"
  [isUTC]="isUtc"
  [item]="decision"
  [limitTo]="limitTo"
  [nextInfo]="nextInfo"
  [notifications]="notifications"
  [type]="'DECISION'"
  (actionClicked)="actionClicked($event)"
>
  <ng-container class="template_GROUP_LINK">
    <app-group-selector
      (groupChange)="updateLinkedGroup($event)"
      [disabledLinks]="disabledLinks"
      [isReadOnly]="isReadOnly"
      [listGroup]="decisionGroup"
      [modeLinkIsActivate]="modeLinkIsActivate"
      [type]="'decision'"
      class="contentSectionAdd"
    ></app-group-selector>
  </ng-container>

  <ng-container class="template_EVENT_LINK contentSectionAdd">
    <!-- LINKED EVENTS -->
    <div *ngIf="!isFromLogBook && moduleConfig.config.moduleName != 'ecl'" class="hol-dialog-section">
      <div *ngIf="linkedEvents; else linkedEventLoadingTemplate" class="linked-section">
        <div class="linked-section__top">
          <div class="left">
            <span *ngIf="(isReadOnly || modeLinkIsActivate) && linkedEventsDisplay.length" class="title-read-only">{{
              moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LABEL' | translate
            }}</span>
            <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
              <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LABEL' | translate }}</mat-label>
              <mat-select
                (ngModelChange)="changelinkedEvent($event)"
                [(ngModel)]="linkedEvents"
                [disabled]="events.length === 0"
                [ngModelOptions]="{ standalone: true }"
                multiple
                ngModel
              >
                <mat-select-trigger>
                  <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_SEARCH' | translate }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let event of events" [value]="event.objectId"
                  >{{ event.scenario.code + event.order + ' - ' + event.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="right">
            <button
              (click)="addEvent($event)"
              *ngIf="!isReadOnly && isCreate && !modeLinkIsActivate"
              class="add-button mat-icon-button"
              color="primary"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="linked-section__bottom">
          <div *ngFor="let event of linkedEventsDisplay" class="linked-section__bottom__row">
            <div class="left">
              {{ event.scenario.code + event.order + ' - ' + event.description }}
              <span *ngIf="disabledLinks[event.objectId]">{{
                moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
              }}</span>
            </div>
            <div class="right">
              <button
                (click)="removeEvent(event)"
                *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[event.objectId]"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #linkedEventLoadingTemplate>
        <div class="linked-section">
          <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.EVENT_LOADING' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </ng-container>

  <ng-container class="template_FLIGHT_LINK contentSectionAdd">
    <div class="contentSectionAdd">
      <div *ngIf="moduleConfig.config.showLinkToFlightOcc && !decision.isTodo" class="hol-dialog-section">
        <div class="linked-flight">
          <label id="favoriteStateLabel">{{
            moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.FLIGHT' | translate
          }}</label>
          <div class="date input-group">
            <mat-form-field class="full-width no-label">
              <input
                (ngModelChange)="changeDate($event)"
                [disabled]="isReadOnly"
                [matDatepicker]="flightPicker"
                [ngModelOptions]="{ name: 'utc', standalone: true }"
                [ngModel]="searchData.date"
                matInput
                placeholder="DD/MM/YYYY"
                tabindex="-1"
              />
              <mat-datepicker-toggle [for]="flightPicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #flightPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="flight">
            <mat-form-field>
              <span class="icon-container" matPrefix>
                <i class="hol-icon-airplane-mode-active"></i>
              </span>
              <input
                (ngModelChange)="querySearch($event)"
                [disabled]="!searchData.date || searchData.flights.length === 0 || isReadOnly"
                [matAutocomplete]="auto"
                [matAutocomplete]="auto"
                [ngModelOptions]="{ standalone: true }"
                [ngModel]="searchData.flightNumber"
                [value]="searchData.flightNumber"
                matInput
                name="searchDataFight"
                placeholder="{{ autocompletePlaceHolder }}"
                type="text"
              />
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedItemChange($event)">
                <mat-option *ngFor="let item of searchData.flightsFiltered" [value]="item.objectId">
                  {{ item.flightNumber }} {{ item.std | date : 'HH:mm ddMMM' : 'UTC' }}
                </mat-option>
                <mat-option *ngIf="searchData.flightsFiltered.length === 0 && searchData.date"
                  >No results found with this search.
                </mat-option>
              </mat-autocomplete>
              <button
                (click)="resetFlight()"
                *ngIf="selectedFlight && !isReadOnly"
                aria-label="Clear"
                mat-icon-button
                mat-icon-button
                matSuffix
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container class="template_LOGBOOK_LINK contentSectionAdd">
    <!-- LINKED LOGBOOKS -->
    <div *ngIf="!isFromLogBook" class="contentSectionAdd">
      <div *ngIf="linkedLogBooks; else linkedLogBooksLoadingTemplate" class="linked-section">
        <div class="linked-section__top">
          <div class="left">
            <span *ngIf="(isReadOnly || modeLinkIsActivate) && linkedLogBooksDisplay.length" class="title-read-only">{{
              moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.LOGBOOK_LABEL' | translate
            }}</span>
            <mat-form-field *ngIf="!isReadOnly && !modeLinkIsActivate">
              <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.LOGBOOK_LABEL' | translate }}</mat-label>
              <mat-select
                (ngModelChange)="changelinkedLogBook($event)"
                [(ngModel)]="linkedLogBooks"
                [disabled]="logBookItems.length === 0"
                [ngModelOptions]="{ standalone: true }"
                multiple
                ngModel
              >
                <mat-select-trigger>
                  <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.LOGBOOK_SEARCH' | translate }}</span>
                </mat-select-trigger>
                <mat-option *ngFor="let logBook of logBookItems" [value]="logBook.objectId">{{ logBook.text }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="right">
            <button
              (click)="addLogbook($event)"
              *ngIf="!isReadOnly && isCreate && !modeLinkIsActivate"
              class="add-button mat-icon-button"
              color="primary"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="linked-section__bottom">
          <div *ngFor="let logBook of linkedLogBooksDisplay" class="linked-section__bottom__row">
            <div class="left">
              {{ logBook.text }}
              <span *ngIf="disabledLinks[logBook.objectId]">{{
                moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.ARCHIVE_ITEM' | translate
              }}</span>
            </div>
            <div class="right">
              <button
                (click)="removeLogBook(logBook)"
                *ngIf="!isReadOnly && !modeLinkIsActivate && !disabledLinks[logBook.objectId]"
                mat-icon-button
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #linkedLogBooksLoadingTemplate>
        <div class="linked-section">
          <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.LOGBOOK_LOADING' | translate }}</span>
        </div>
      </ng-template>
    </div>
  </ng-container>

  <ng-container class="template_MAKE_TODO contentSectionAdd">
    <div *ngIf="isTodoConfigActivated" class="hol-dialog-section--col">
      <mat-checkbox
        (ngModelChange)="toggleIsTodo($event)"
        [(ngModel)]="decision.isTodo"
        [disabled]="isReadOnly"
        aria-label="is todo"
        color="accent"
      >
        {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.CONVERT_TO_TODO' | translate }}
      </mat-checkbox>
      <p>
        <i *ngIf="decision.isTodo" class="text-muted">
          {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.TODO_HINT' | translate }}
        </i>
      </p>
    </div>
  </ng-container>

  <div class="logbook-modal">
    <!-- TRANSFORM TO LOGBOOK
    <div *ngIf="!isCreate" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="changeToLogbook"
        [disabled]="decision.readOnly || isReadOnly || (data.isSummaryActivated && isInSummary)"
        [labelPosition]="'after'"
        color="primary"
      >
        Transform to logbook
      </mat-checkbox>
    </div>
    -->

    <!-- FROM OTHER MODULE -->
    <div *ngIf="decision.fromERP && hasCrisisInProgress" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="decision.fromERP"
        [disabled]="isHistory || decision.readOnly || isReadOnly"
        [labelPosition]="'after'"
        color="primary"
      >
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
        {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.FROM_ERP' | translate }}
      </mat-checkbox>
    </div>
    <!-- TO OTHER MODULE
    <div *ngIf="moduleConfig.config.displayToERP && !decision.fromERP" class="hol-dialog-section">
      <mat-checkbox
        [(ngModel)]="config.transfertToERP"
        [disabled]="isHistory || decision.readOnly || !hasCrisisInProgress || !hasErpAccess"
        [labelPosition]="'after'"
        color="primary"
      >
        {{ moduleConfig.config.translateKey + '.DASHBOARD.STATUS_DECISIONS.MODALS.TO_ERP' | translate }}
        <img alt="icon-interne" src="/assets/images/icon_paperplan.svg" />
      </mat-checkbox>
    </div>
    -->
  </div>

  <app-section-with-title
    *ngIf="decisionTasks && decisionTasks.length"
    icon="task"
    [titleKey]="moduleConfig.config.translateKey + '.MODALS.DECISION.TASK_TITLE'"
  >
    <!-- LIST -->
    <div class="tasks-list">
      <div>
        <ul class="hol-list">
          <li *ngFor="let task of decisionTasks" class="task {{ task.status }}">{{ task | eclTaskCategoryTitle }}: {{ task.taskTitle }}</li>
        </ul>
      </div>
    </div>
  </app-section-with-title>
  <ng-template #linkedTasksLoadingTemplate>
    <div class="linked-section">
      <span>{{ moduleConfig.config.translateKey + '.MODALS.DECISION.TASKS_LOADING' | translate }}</span>
    </div>
  </ng-template>
</app-hol-modal>
