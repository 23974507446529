import { Inject, Injectable } from '@angular/core';
import { HolRole } from 'src/app/common/models/hol-role';
import { HolUser, HolUserWithCompanies } from 'src/app/common/models/hol-user.model';
import { RequestService } from 'src/app/common/services/request.service';
import { MailSenderService } from '../../common/services/mail/mail-sender.service';
import { SmsSenderService } from '../../common/services/sms/sms-sender.service';
import { RolesService } from '../../common/services/roles.service';
import { HolUserWithRoles } from '../../admin/pages/admin-roles/admin-roles.component';
import { HelperService } from '../../common/services/helper.service';
import { HolOptionsService } from '../../common/services/hol-options.service';

@Injectable({
  providedIn: 'root',
})
export class EclUserService {
  constructor(
    private requestService: RequestService,
    private readonly mailSenderService: MailSenderService,
    private readonly smsSenderService: SmsSenderService,
    private rolesService: RolesService,
    private helperService: HelperService,
    private holOptionsService: HolOptionsService,
    @Inject('CONSTANTS') private CONSTANTS,
  ) {}

  public async getAll(): Promise<HolUser[]> {
    const parseRoles = await this.getEclRoles();
    const userArray: HolUser[] = [];
    for (const parseRole of parseRoles) {
      const parseUsers = await this.requestService.performFindAllQuery<Parse.User>(parseRole.getUsers().query());
      const users = parseUsers.map(u => new HolUser(u));
      userArray.push(...users);
    }
    return userArray.filter((user, index, array) => index === array.findIndex(u => u.userId === user.userId));
  }

  async getAllWithCompanies(): Promise<HolUserWithCompanies[]> {
    const roles = await this.getEclRoles();
    const rolesWithUsers = await Promise.all(
      roles.map(async r => {
        const users = await this.requestService.performFindAllQuery<Parse.User>(r.getUsers().query());
        return {
          role: new HolRole(r),
          users,
        };
      }),
    );
    return this.reduceRolesWithUsers(rolesWithUsers);
  }

  async createMember(userToCreate: Partial<HolUserWithRoles>): Promise<HolUserWithCompanies> {
    const holOptions = await this.holOptionsService.get();
    const generatedPassword = this.helperService.generatePassword(holOptions.passwordLength);

    const user = new Parse.User();
    user.set('username', userToCreate.email);
    user.set('password', generatedPassword);
    user.set('email', userToCreate.email);
    user.set('userEmail', userToCreate.email);
    user.set('firstName', userToCreate.firstName);
    user.set('lastName', userToCreate.lastName);
    user.set('phone', userToCreate.phone);
    user.set('userId', userToCreate.email);
    user.set('createdBy', Parse.User.current());
    user.set('isExternal', userToCreate.isExternal);

    const acl = new Parse.ACL();
    acl.setRoleReadAccess('Admin', true);
    acl.setRoleWriteAccess('Admin', true);
    acl.setPublicReadAccess(true);
    user.setACL(acl);

    const parseUser = await this.requestService.performSaveQuery(user);
    const newUser = new HolUserWithCompanies(parseUser);
    newUser.companies = [];
    const roles = await Promise.all(userToCreate.roles.map(r => this.rolesService.addUsers([newUser], r)));
    roles.forEach(role => {
      const bufferUserComp = newUser.companies.find(el => role.company === el.name);
      if (!bufferUserComp) {
        newUser.companies.push({ name: role.company, read: role.read, write: role.write });
      } else {
        bufferUserComp.write = bufferUserComp.write || role.write;
      }
    });
    this.mailSenderService.sendMail(
      {
        recipients: [{ email: newUser.email }],
        subject: 'Account created',
        contentHtml: [
          '<strong>A new account has been created for you.</strong>',
          'Here are you credentials :<br/>' + 'Username : ' + newUser.email + '<br/>' + 'Access code : ' + generatedPassword,
          'You can login at ' + location.origin,
        ].join('<br/><br/>'),
      },
      true,
      false,
    );
    this.smsSenderService
      .sendSms(
        newUser.phone,
        [
          'A new account has been created for you. Here are you credentials :',
          'Username : ' + newUser.email + '\n' + 'Access code : ' + generatedPassword,
          'You can login at ' + location.origin,
        ].join('\n'),
        true,
        this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      )
      .then();
    return newUser;
  }

  async updateMember(user: HolUserWithRoles): Promise<HolUserWithCompanies> {
    const userToSave = new Parse.User();
    userToSave.id = user.objectId;
    userToSave.set('firstName', user.firstName);
    userToSave.set('lastName', user.lastName);
    userToSave.set('phone', user.phone);
    userToSave.set('isExternal', user.isExternal);
    const parseUser = await this.requestService.performSaveQuery(userToSave);
    const newUser = new HolUserWithCompanies(parseUser);
    await this.mailSenderService.sendMail(
      {
        recipients: [{ email: newUser.email }],
        subject: 'Account updated',
        contentHtml: [
          '<strong>Your account has been updated.</strong>',
          'Username : ' +
            newUser.email +
            '<br/>First name : ' +
            newUser.firstName +
            '<br/>Last name : ' +
            newUser.lastName +
            '<br/>Mail : ' +
            newUser.email +
            '<br/>Tel : ' +
            newUser.phone,
          'You can login at ' + location.origin,
        ].join('<br/><br/>'),
      },
      true,
      false,
    );
    if (newUser.phone) {
      await this.smsSenderService.sendSms(
        newUser.phone,
        [
          'Your account has been updated.',
          'Username : ' +
            newUser.email +
            '\nFirst name : ' +
            newUser.firstName +
            '\nLast name : ' +
            newUser.lastName +
            '\nMail : ' +
            newUser.email +
            '\nTel : ' +
            newUser.phone,
          'You can login at ' + location.origin,
        ].join('\n'),
        true,
        this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX,
      );
    }
    return newUser;
  }

  private getEclRoles() {
    const rolesQuery = new Parse.Query(Parse.Role);
    rolesQuery.startsWith('name', 'ECL_');
    return this.requestService.performFindQuery<Parse.Role>(rolesQuery);
  }

  private reduceRolesWithUsers(rolesWithUsers: { role: HolRole; users: Parse.User[] }[]): HolUserWithCompanies[] {
    const userMap = new Map<string, HolUserWithCompanies>();
    rolesWithUsers.forEach(rwu => {
      const { role, users } = rwu;
      if (role.universe === 'ECL') {
        users.forEach(u => {
          const userFromMap = userMap.get(u.id);
          if (userFromMap) {
            const bufferUserComp = userFromMap.companies.find(el => role.company === el.name);
            if (!bufferUserComp) {
              userFromMap.companies.push({ name: role.company, read: role.read, write: role.write });
            } else {
              bufferUserComp.write = bufferUserComp.write || role.write;
            }
          } else {
            const user = new HolUserWithCompanies(u);
            user.companies = [{ name: role.company, read: role.read, write: role.write }];
            userMap.set(u.id, user);
          }
        });
      }
    });
    return [...userMap.values()];
  }
}
