<div [ngClass]="isFilterButton ? 'new-holis-theme tag-container content-grid' : 'new-holis-theme tag-container'">
  <!-- Template: Loading State -->
  <div *ngIf="eclTagsIsLoading; else toggleButton">
    <ng-container *ngTemplateOutlet="loadingState"></ng-container>
  </div>
  <!-- Section: Tag Selection Pop-up -->
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>

<ng-template #loadingState>
  <ng-container *ngIf="!isFilterMode; else filterLoad">
    <div class="loading-spinner"></div>
  </ng-container>
  <ng-template #filterLoad>
    <button
      [ngClass]="{
        isOnModal: !isFilterMode
      }"
      class="css-button"
      type="button"
    >
      <span class="css-button-text">
        <span>{{ 'TAG.TITLE' | translate }}</span>
      </span>
      <span class="css-button-icon">
        <div class="loading-spinner"></div>
      </span>
    </button>
  </ng-template>
</ng-template>

<ng-template #toggleButton>
  <ng-container *ngIf="isVisibleButton()">
    <div
      (click)="toggleSelector()"
      *ngIf="!eclTagsIsLoading && selectedTags.length === 0 && !isFilterMode; else displayButton"
      class="empty-box"
    >
      <div class="empty-content" [ngClass]="{ required: !readOnly && required && selectedTags.length === 0 }">
        <span class="material-symbols-outlined iconsize">shoppingmode</span>
        <span>{{ 'TAG.EMPTY_ADD' | translate }}</span>
      </div>
    </div>
    <ng-template #displayButton>
      <button
        (click)="!readOnly && toggleSelector()"
        *ngIf="!eclTagsIsLoading && (isFilterMode || selectedTags.length >= 0)"
        [ngClass]="
          isFilterButton ? (selectedTags.length === 0 ? '' : 'toggled') : displayTagLine ? 'css-button displayTagLine' : 'css-button'
        "
        type="button"
      >
        <span *ngIf="!readOnly && isFilterButton" class="material-symbols-outlined iconsize">filter_list</span>
        <span *ngIf="selectedTags.length === 0 && isFilterMode" [ngClass]="isFilterButton ? '' : 'css-button-text'">
          <span>{{ 'TAG.TITLE' | translate }}</span>
        </span>
        <span *ngIf="!readOnly && !isFilterButton" class="css-button-icon">
          <mat-icon>{{ !isSelectorVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</mat-icon>
        </span>
        <!-- Section: Selected Tags Display -->
        <div
          *ngIf="selectedTags.length > 0"
          [ngStyle]="{ 'flex-direction': $rootScope.isPhone ? 'column' : 'row' }"
          [ngClass]="displayTagLine ? 'tag-selector tag-selector-display-tag-line' : 'tag-selector'"
        >
          <!-- Sub-section: Tags List -->
          <div [ngClass]="{ 'mt-1': $rootScope.isPhone, 'no-expansion': !isExpanded }" class="filters-container" id="tagList">
            <ng-container *ngFor="let tag of selectedTags; trackBy: trackByFunction">
              <button
                (click)="toggleTag($event, tag)"
                *ngIf="tag.name && tag.name.length"
                [disabled]="readOnly"
                [ngClass]="{
                  'mb-1': $rootScope.isPhone,
                  'mb-0': !$rootScope.isPhone,
                  isFilterButtonTag: isFilterButton,
                  displayTagLine: displayTagLine
                }"
                [ngStyle]="buttonStyle(tag)"
                aria-label="tag.name"
                class="btn"
                mat-raised-button
              >
                <span class="tag-name">{{ tag.name }}</span>
              </button>
            </ng-container>
          </div>
          <!-- Sub-section: Uncheck All Button -->
          <div *ngIf="selectedTags.length > 1">
            <div
              (click)="unsetAllTag($event)"
              *ngIf="showUntoggleAll() && !$rootScope.isPhone"
              class="uncheck-all-btn"
              [ngClass]="isFilterButton ? 'uncheck-all-btn--alt' : ''"
            >
              <mat-icon>close</mat-icon>
            </div>
          </div>
        </div>
      </button>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #content>
  <ng-container *ngIf="isSelectorVisible">
    <div
      [ngClass]="{
        isOnModal: !isFilterMode,
        isFilterButton: isFilterButton
      }"
      class="tag-select-popin"
    >
      <!-- Sub-section: Header with Close Icon and Mode Toggle -->
      <div class="header">
        <!-- Back/Cancel Icon, shown in create mode -->
        <button (click)="toggleMode()" *ngIf="isCreateMode" class="back-icon">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button (click)="toggleSelector()" class="close-icon">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="tag-modal-content">
        <div *ngIf="!isCreateMode">
          <div class="container-tag tag-selector">
            <div *ngFor="let tag of tags; trackBy: trackByFunction" class="filters-container">
              <button
                (click)="toggleTag($event, tag)"
                *ngIf="tag.name && tag.name.length"
                [ngClass]="{
                  'mb-1': $rootScope.isPhone,
                  'mb-0': !$rootScope.isPhone,
                  isFilterButtonTag: isFilterButton
                }"
                [ngStyle]="buttonStyle(tag)"
                aria-label="tag.name"
                class="btn"
                mat-raised-button
              >
                <span class="tag-name">{{ tag.name }}</span>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="isCreateMode" class="create-tag-form">
          <!-- Step 1: Choose Tag Type -->
          <div class="tag-type-selection">
            <mat-button-toggle-group [(ngModel)]="selectedTagType" aria-label="Tag Type">
              <mat-button-toggle
                (change)="tagTypeChange()"
                *ngFor="let tagType of tagTypes | keyvalue"
                [value]="tagType.value"
                class="btn-group-tag"
              >
                <!-- Adding both prefix and tag type in button -->
                <!-- {{ tagTypesPrefix[tagType.key] }}{{ tagType.value }}-->
                {{ tagTypesPrefix[tagType.key] }}{{ 'TAG.TYPE.' + tagType.value | translate }}
                <!-- FAIRE LA TRAD -->
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <!-- Step 2: Input Tag Name -->
          <div class="tag-name-input">
            <mat-form-field type="text">
              <mat-label>{{ 'TAG.NAME' | translate }}</mat-label>
              <span matPrefix>{{ getTagPrefix(selectedTagType) }}&nbsp;</span>
              <input (input)="processTagName()" [(ngModel)]="newTagNameRaw" matInput placeholder="{{ 'TAG.NAME' | translate }}" />
              <button (click)="newTagNameRaw = ''" *ngIf="newTagNameRaw" aria-label="Clear" mat-button mat-icon-button matSuffix>
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <span *ngIf="selectedTagType === enumTagTypes.remaining" class="color-picker">
              <app-color-picker (colorChange)="selectColor($event)" [selectedColor]="selectedColor"></app-color-picker>
            </span>
          </div>
          <div class="tag-preview">
            <h6>{{ 'TAG.PREVIEW' | translate }}</h6>
            <div class="container-tag tag-selector">
              <div class="filters-container">
                <button
                  *ngIf="newTagNameRaw && newTagNameRaw.length"
                  [ngClass]="{
                    'mb-1': $rootScope.isPhone,
                    'mb-0': !$rootScope.isPhone,
                    isFilterButtonTag: isFilterButton
                  }"
                  [ngStyle]="buttonStylePreview(false)"
                  aria-label="tag.name"
                  class="btn"
                  mat-raised-button
                >
                  <span class="tag-name">{{ getTagPrefix(selectedTagType) }}{{ newTagName }}</span>
                </button>
                <button
                  *ngIf="newTagNameRaw && newTagNameRaw.length"
                  [ngClass]="{
                    'mb-1': $rootScope.isPhone,
                    'mb-0': !$rootScope.isPhone
                  }"
                  [ngStyle]="buttonStylePreview(true)"
                  aria-label="tag.name"
                  class="btn"
                  mat-raised-button
                >
                  <span class="tag-name">{{ getTagPrefix(selectedTagType) }}{{ newTagNameRaw }}</span>
                </button>
              </div>
            </div>
          </div>
          <!-- Step 3: Color Picker (only if "Autre" is selected) -->
        </div>
      </div>
      <div *ngIf="addForm && canAddTag" class="footer">
        <button (click)="toggleMode()" *ngIf="!isCreateMode" class="tag-button">{{ 'TAG.CREATE' | translate }}</button>
        <button (click)="createNewTag()" *ngIf="isCreateMode" [disabled]="disableCreate()" class="tag-button">
          {{ 'TAG.SAVE' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>
<!--</ng-template>-->
