import { FilesService } from 'src/app/common/services/files.service';
import { MarkdownService } from 'src/app/common/components/markdown-editor/markdown.service';
import { Inject, Injectable } from '@angular/core';
import { catchError, take } from 'rxjs/operators';

import { CommonStoreManager } from 'src/app/common/store/common.store-manager';
import { RolesService } from 'src/app/common/services/roles.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { isEqual, map, orderBy, remove } from 'lodash';
import { ModuleConfigService } from 'src/app/common/services/module-config/module-config.service';
import { EclInfos } from 'src/app/common/store/common.model';
import { HolContext } from 'src/app/common/models/hol-context.model';
import { UserService } from 'src/app/common/services/user.service';

import { HolAttachments } from '../../../common/models/hol-attachments.model';
import { HolNextInfo } from '../../../common/models/hol-next-info.model';
import { HolNotification } from '../../../common/models/hol-notification.model';
import { HolScenario } from '../../../common/models/hol-scenario';
import { HolTag } from '../../../common/models/hol-tag';
import { ParseMapperService } from '../../../common/services/parse-mapper.service';
import { RequestService } from '../../../common/services/request.service';
import { TagsService } from '../../../common/services/tags.service';
import { MccOptionsService } from '../../../mcc/services/mcc-options.service';
import { OclEvent } from '../../models/ocl-event.model';
import { OclHistoryLog } from '../../models/ocl-history-log.model';
import { OclTask, OclTaskRef } from '../../models/ocl-task';
import { OclEventsStoreManager } from '../../store/events/ocl-events-store-manager.service';
import { OclScenariosStoreManager } from '../../store/scenarios/ocl-scenarios.store-manager';
import { OclTagsStoreManager } from '../../store/tags/ocl-tags.store-manager';
import { OclTasksStoreManager } from '../../store/tasks/ocl-tasks.store-manager';
import { OclEventTagService } from '../ocl-event-tag-service/ocl-event-tag.service';
import { OclHistoryService } from '../ocl-history-service/ocl-history.service';
import { OclMailService } from '../ocl-mail-service/ocl-mail.service';
import { OclSmsService } from '../ocl-sms-service/ocl-sms.service';
import { OclTasksService } from '../ocl-tasks-service/ocl-tasks.service';
import { EventInfos } from '../../../common/models/hol-event';
import { HolChecklist } from '../../../common/models/hol-checklist';
import { from, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class OclEventService<T extends OclEvent = OclEvent> {
  // tslint:disable:variable-name
  protected ParseEvents;
  protected ParseScenario;
  protected ParseEventInfos;
  protected ParseTaskRef;
  protected ParseTask = Parse.Object.extend('HOLTask');
  protected ParseTag;
  protected ParseEventTag;
  protected ParseFlight = Parse.Object.extend('GOCFlight');
  protected ParseGocEvent = Parse.Object.extend('GOCEvents');
  private ParseGocEventInfos = Parse.Object.extend('GOCEventInfos');
  private ParseGocEventTag = Parse.Object.extend('GOCEventTag');
  private ParseGocScenario = Parse.Object.extend('GOCScenario');
  protected ParseGocFlightEvents = Parse.Object.extend('GOCFlightEvents');
  protected ParseOccEvents = Parse.Object.extend('OCCEvents');
  private ParseOccEventInfos = Parse.Object.extend('OCCEventInfos');
  private ParseOccEventTag = Parse.Object.extend('OCCEventTag');
  private ParseOccScenario = Parse.Object.extend('OCCScenario');
  // tslint:enable
  protected abstract taskEventColumn;
  protected _events; // @cache

  protected constructor(
    protected requestService: RequestService,
    protected mccOptionsService: MccOptionsService,
    protected userService: UserService,
    protected parseMapper: ParseMapperService,
    protected notificationsService: NotificationsService,
    protected historyService: OclHistoryService<OclHistoryLog>,
    protected eventTagService: OclEventTagService,
    protected eventsStoreManager: OclEventsStoreManager,
    protected mailService: OclMailService,
    protected smsService: OclSmsService,
    @Inject('ChatService') protected chatService,
    @Inject('$rootScope') protected $rootScope,
    public moduleConfig: ModuleConfigService,
    protected tasksStoreManager: OclTasksStoreManager,
    protected tasksService: OclTasksService,
    protected tagsService: TagsService,
    protected rolesService: RolesService,
    protected commonStoreManager: CommonStoreManager,
    protected tagsStoreManager: OclTagsStoreManager,
    protected scenariosStoreManager: OclScenariosStoreManager,
    protected readonly filesService: FilesService,
    protected markdownService: MarkdownService,
  ) {}

  public async getScenarios(): Promise<HolScenario[]> {
    const _scenarios = await this.scenariosStoreManager.scenariosState.pipe(take(1)).toPromise();

    if (_scenarios && _scenarios.length) {
      return _scenarios;
    } else {
      const query = new Parse.Query(this.ParseScenario);
      return this.requestService.performFindQuery(query).then(data => {
        return data.map(s => new HolScenario(s));
      });
    }
  }

  public getByData(searchedData: { archived?: boolean; scenario?: string; description?: string }): Promise<any[]> {
    const query = new Parse.Query(this.ParseEvents);
    query.descending('createdAt');
    query.include('scenario');
    if (!searchedData.archived) {
      query.doesNotExist('closeReason');
    }
    if (searchedData.scenario) {
      query.equalTo('scenario', new this.ParseScenario({ id: searchedData.scenario }));
    }
    if (searchedData.description) {
      query.contains('description', searchedData.description);
    }
    return this.requestService.performFindQuery(query).then(results => {
      const promises = [];

      // Event tags
      promises.push(this.getEventTags(query));

      // Event infos
      promises.push(this.getEventInfos(query));
      return Promise.all(promises).then(data => {
        const [eventTags, eventInfos] = data;

        return results.map(r => {
          const infos = eventInfos.filter(ei => {
            return ei.get('event').id === r.id;
          });
          const tags = eventTags.filter(et => {
            return et.get('event').id === r.id;
          });
          return this.newEvent(r, tags, infos);
        });
      });
    });
  }

  protected getEventInfos(query: Parse.Query<Parse.Object>): Promise<Parse.Object[]> {
    const eventInfoQuery = new Parse.Query(this.ParseEventInfos);
    eventInfoQuery.matchesQuery('event', query);
    eventInfoQuery.descending('createdAt');
    return this.requestService.performFindQuery(eventInfoQuery);
  }

  public async getCheckListTaskByEvent(objectId: string, module: string): Promise<HolChecklist[]> {
    const checkListTaskQuery: Parse.Query<Parse.Object> = new Parse.Query(this.ParseTask);
    try {
      checkListTaskQuery.equalTo(module.toLowerCase() + 'Event', new this.ParseEvents({ id: objectId }));
      checkListTaskQuery.select('outputTitle', 'outputDataLabel', 'attachments');
      const results: Parse.Object[] = await this.requestService.performFindQuery(checkListTaskQuery);
      return results.map(result => ({
        outputTitle: result.get('outputTitle'),
        outputDataLabel: result.get('outputDataLabel'),
        attachments: result.get('attachments') as HolAttachments,
      }));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  protected getFromGOCEventInfos(parseGocEvents: Parse.Object[]): Promise<Parse.Object[]> {
    const parseEvents = parseGocEvents.filter(pi => pi.get('gocEvent')).map(e => new this.ParseGocEvent({ id: e.get('gocEvent').id }));
    const eventInfoQuery = new Parse.Query(this.ParseGocEventInfos);
    eventInfoQuery.containedIn('event', parseEvents);
    eventInfoQuery.descending('createdAt');
    return this.requestService.performFindQuery(eventInfoQuery);
  }

  protected getFromOCCEventInfos(parseOccEvents: Parse.Object[]): Promise<Parse.Object[]> {
    const parseEvents = parseOccEvents.filter(pi => pi.get('occEvent')).map(e => new this.ParseOccEvents({ id: e.get('occEvent').id }));
    const eventInfoQuery = new Parse.Query(this.ParseOccEventInfos);
    eventInfoQuery.containedIn('event', parseEvents);
    eventInfoQuery.descending('createdAt');
    return this.requestService.performFindQuery(eventInfoQuery);
  }

  protected getEventTags(query: Parse.Query<Parse.Object>): Promise<Parse.Object[]> {
    const eventTagQuery = new Parse.Query(this.ParseEventTag);
    eventTagQuery.include('tag');
    eventTagQuery.matchesQuery('event', query);
    eventTagQuery.descending('createdAt');
    return this.requestService.performFindQuery(eventTagQuery);
  }

  protected getFromOCCEventTags(parseOccEvents: Parse.Object[]): Promise<Parse.Object[]> {
    const parseEvents = parseOccEvents.filter(pi => pi.get('occEvent')).map(e => new this.ParseOccEvents({ id: e.get('occEvent').id }));
    const eventTagQuery = new Parse.Query(this.ParseOccEventTag);
    eventTagQuery.include('tag');
    eventTagQuery.containedIn('event', parseEvents);
    eventTagQuery.descending('createdAt');
    return this.requestService.performFindQuery(eventTagQuery);
  }

  protected getFromGOCEventTags(parseGocEvents: Parse.Object[]): Promise<Parse.Object[]> {
    const parseEvents = parseGocEvents.filter(pi => pi.get('gocEvent')).map(e => new this.ParseGocEvent({ id: e.get('gocEvent').id }));
    const eventTagQuery = new Parse.Query(this.ParseGocEventTag);
    eventTagQuery.include('tag');
    eventTagQuery.containedIn('event', parseEvents);
    eventTagQuery.descending('createdAt');
    return this.requestService.performFindQuery(eventTagQuery);
  }

  public getAll(forceToRefresh?: boolean, isPrivate?: boolean, filterDataStartDate?: Date): Promise<T[]> {
    if (this._events !== undefined && this._events.length && !forceToRefresh) {
      return Promise.resolve(this._events);
    } else {
      const query = new Parse.Query(this.ParseEvents);

      let today: Date;
      if (this.moduleConfig.config.canChooseDataStartDate) {
        if (filterDataStartDate instanceof Date && filterDataStartDate !== undefined) {
          today = filterDataStartDate;
        } else {
          today = new Date();
        }
      }

      const eventQuery = this.getAdditionnalQueries(query, today);
      eventQuery.descending('createdAt');
      eventQuery.include('scenario');
      if (isPrivate) {
        eventQuery.notEqualTo('isPrivate', true);
      }
      eventQuery.include('gocEvent');
      eventQuery.include('occEvent');
      eventQuery.include('applFlights');

      return this.requestService.performFindQuery(eventQuery).then(results => {
        const promises = [];

        const fromGocResults = results.filter(r => {
          return !!r.get('gocEvent');
          // skip
        });
        const fromOccResults = results.filter(r => {
          return !!r.get('occEvent');
          // skip
        });

        // Event tags
        promises.push(this.getEventTags(eventQuery));
        // For all modules except GOC
        if (this.moduleConfig.config.moduleName.toUpperCase() !== 'GOC') {
          promises.push(this.getFromGOCEventTags(fromGocResults));
        }
        // For all modules except OCC
        if (this.moduleConfig.config.moduleName.toUpperCase() !== 'OCC') {
          promises.push(this.getFromOCCEventTags(fromOccResults));
        }

        // Event infos
        promises.push(this.getEventInfos(eventQuery));
        // For all modules except GOC
        if (this.moduleConfig.config.moduleName.toUpperCase() !== 'GOC') {
          promises.push(this.getFromGOCEventInfos(fromGocResults));
        }
        // For all modules except OCC
        if (this.moduleConfig.config.moduleName.toUpperCase() !== 'OCC') {
          promises.push(this.getFromOCCEventInfos(fromOccResults));
        }

        // MCC events which has to be displayed on OCC
        promises.push(this.getAllMCCEvents());

        // Get all scenarios
        promises.push(this.getScenarios());
        // For all modules except GOC
        if (this.moduleConfig.config.moduleName.toUpperCase() !== 'GOC') {
          promises.push(this.requestService.performFindQuery(new Parse.Query(this.ParseGocScenario)));
        }
        // For all modules except OCC
        if (this.moduleConfig.config.moduleName.toUpperCase() !== 'OCC') {
          promises.push(this.requestService.performFindQuery(new Parse.Query(this.ParseOccScenario)));
        }

        return Promise.all(promises).then(data => {
          const eventTags = data[0];
          let fromGocEventTags;
          let fromOccEventTags;

          let eventInfos;
          let fromGocEventInfos;
          let fromOccEventInfos;

          let mccEvents;

          let allScenarios;
          let fromGocEventScenarios;
          let fromOccEventInfosScenarios;

          // // For all modules except GOC and OCC
          if (this.moduleConfig.config.moduleName.toUpperCase() !== 'GOC' && this.moduleConfig.config.moduleName.toUpperCase() !== 'OCC') {
            fromGocEventTags = data[1];
            fromOccEventTags = data[2];

            eventInfos = data[3];
            fromGocEventInfos = data[4];
            fromOccEventInfos = data[5];

            mccEvents = data[6];

            allScenarios = data[7];
            fromGocEventScenarios = data[8];
            fromOccEventInfosScenarios = data[9];
          }

          // Only for GOC
          if (this.moduleConfig.config.moduleName.toUpperCase() === 'GOC') {
            fromOccEventTags = data[1];

            eventInfos = data[2];
            fromOccEventInfos = data[3];

            mccEvents = data[4];

            allScenarios = data[5];
            fromOccEventInfosScenarios = data[6];
          }

          // Only for OCC
          if (this.moduleConfig.config.moduleName.toUpperCase() === 'OCC') {
            fromGocEventTags = data[1];

            eventInfos = data[2];
            fromGocEventInfos = data[3];

            mccEvents = data[4];

            allScenarios = data[5];
            fromGocEventScenarios = data[6];
          }

          // OCL events
          let events = results.map(r => {
            if (r.get('gocEvent')) {
              const infos = fromGocEventInfos.filter(ei => {
                return ei.get('event').id === r.get('gocEvent').id;
              });
              const tags = fromGocEventTags.filter(et => {
                return et.get('event').id === r.get('gocEvent').id;
              });
              return this.newEvent(r, tags, infos, fromGocEventScenarios);
            } else if (r.get('occEvent')) {
              const infos = fromOccEventInfos.filter(ei => {
                return ei.get('event').id === r.get('occEvent').id;
              });
              const tags = fromOccEventTags.filter(et => {
                return et.get('event').id === r.get('occEvent').id;
              });
              return this.newEvent(r, tags, infos, fromOccEventInfosScenarios);
            } else {
              const infos = eventInfos.filter(ei => {
                return ei.get('event').id === r.id;
              });
              const tags = eventTags.filter(et => {
                return et.get('event').id === r.id;
              });
              return this.newEvent(r, tags, infos);
            }
          });

          // MCC events
          if (mccEvents && mccEvents.length) {
            const code = this.mccOptionsService.getDefaultOCCScenario();
            const scenario = allScenarios.find(s => s.code === code);
            events = events.concat(
              mccEvents.map(me => {
                return this.parseMapper.mccEventToOCCEventObject(me, scenario);
              }),
            );
          }

          this._events = events;
          return events;
        });
      });
    }
  }

  public create(
    event: T,
    infos: EventInfos[],
    notifications: HolNotification[],
    hasToActivateECL: boolean = false,
    eclInfos?: EclInfos,
    eclName?: string,
    context?: HolContext,
    duplicateToOtherModule?: boolean,
  ): Promise<T> {
    if (!event.description) {
      return Promise.reject(`Can't create an event without a description`);
    }
    return this._getNextScenarioOrder(event.scenario).then(order => {
      const parseEvent = new this.ParseEvents();
      parseEvent.set('description', event.description);
      parseEvent.set('order', order);
      parseEvent.set('createdBy', Parse.User.current());
      parseEvent.set('checklistActivated', event.checklistActivated);
      parseEvent.set('toGOC', event.toGOC);
      parseEvent.set('toOCC', event.toOCC);
      parseEvent.setACL(event.acl);

      if (event.scenario) {
        const scenarioParse = new this.ParseScenario();
        scenarioParse.id = event.scenario.objectId;
        parseEvent.set('scenario', scenarioParse);
      }
      if (event.attachments) {
        parseEvent.set('attachments', JSON.stringify(event.attachments));
      }
      this.setAdditionalFields(event, parseEvent);

      return this.requestService.performSaveQuery(parseEvent).then(async ev => {
        let savedEvent;
        const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
        const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);

        if (event.attachments && event.attachments.noteFile && event.attachments.note && (context.htmlTitle === '' || !context.htmlTitle)) {
          const content = event.attachments.note;
          const nameFile = event.attachments.noteFile.fileName;
          context.htmlTitle = event.description;
          const htmlContent = this.markdownService.parseMdToHtml(content);
          const htmlTemplate = this.markdownService.createHtmlContent(htmlContent, context);

          const blob = new Blob([htmlTemplate], { type: 'text/html' });
          const reader = new FileReader();

          savedEvent = await new Promise((resolve, reject) => {
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
              await this.filesService.uploadFile(nameFile, { base64: reader.result }).then(
                async url => {
                  event.attachments.noteFile.url = url;
                  event.attachments.noteFile.fileName = nameFile;
                  event.attachments.note = content;
                  const parseUpdateObject = new this.ParseEvents();
                  parseUpdateObject.id = ev.id;
                  parseUpdateObject.set('attachments', JSON.stringify(event.attachments));
                  await this.requestService.performSaveQuery(parseUpdateObject).then(ud => resolve(ud));
                },
                err => {
                  reject(err);
                },
              );
            };
          });
        } else {
          savedEvent = ev;
        }

        let savedTags = [];
        if (event.tags && event.tags.length) {
          const parseEventTags = event.tags.map(tag => {
            return new this.ParseEventTag({
              event: savedEvent,
              tag: new this.ParseTag({ id: tag.objectId }),
            });
          });
          savedTags = await this.requestService.performSaveAllQuery(parseEventTags);
        }

        const newEvent = this.newEvent(savedEvent, savedTags, undefined);

        if (addressMailToSend.length) {
          this.mailService.sendNewEventMail(newEvent, infos, addressMailToSend);
        }
        if (phoneNumbersToSend.length) {
          this.smsService.sendNewEventSMS(newEvent, infos, phoneNumbersToSend);
        }
        if (event.checklistActivated && event.scenario) {
          this.activateCheckList(savedEvent).then(() => {
            this.sendNewEventChecklistActivatedNotifications(newEvent, infos);
          });
        }

        if (hasToActivateECL) {
          this.activateECLModule(
            eclInfos.eclOptionsId,
            eclName,
            newEvent,
            event.infos.map(info => ({ info })),
          );
        }

        if (this._events) {
          this._events.unshift(newEvent);
        }

        this.historyService
          .postLog(OclHistoryLog.create('Event opened', 'event', 'open', event.attachments, newEvent, savedEvent.getACL(), savedEvent))
          .then();

        if (duplicateToOtherModule) {
          this.duplicateEventToOtherModule(newEvent);
        }

        return newEvent;
      });
    });
  }

  public async update(
    event: T,
    activateCheckLists?: boolean,
    context?: HolContext,
    newDuplicateToOtherModuleValue?: boolean,
    oldDuplicateToOtherModuleValue?: boolean,
  ): Promise<T> {
    const parseEvent = new this.ParseEvents({ id: event.objectId });
    const oldAcl = parseEvent.getACL();
    const oldDescription = parseEvent.get('description');
    parseEvent.set('description', event.description);
    parseEvent.set('attachments', JSON.stringify(event.attachments));
    parseEvent.set('checklistActivated', activateCheckLists);
    parseEvent.set('toGOC', event.toGOC);
    parseEvent.set('toOCC', event.toOCC);
    parseEvent.setACL(event.acl);
    this.setAdditionalFields(event, parseEvent);
    await this.eventTagService.updateEventWithTags(event);

    if (event.scenario) {
      const newScenario = new this.ParseScenario({ id: event.scenario.objectId });
      const oldScenario = parseEvent.get('scenario');

      if (!oldScenario || newScenario.id !== oldScenario.id) {
        const order = await this._getNextScenarioOrder(event.scenario);
        parseEvent.set('order', order);
        parseEvent.set('scenario', newScenario);
      }
    }

    if (event.infos && event.infos.length && !!parseEvent.getACL() && !isEqual(oldAcl, parseEvent.getACL())) {
      const parseInfos = event.infos.map(ni => {
        const eventInfo = new this.ParseEventInfos({ id: ni.objectId });
        eventInfo.setACL(parseEvent.getACL());
        return eventInfo;
      });
      await this.requestService.performSaveAllQuery(parseInfos);
    }

    return this.requestService.performSaveQuery(parseEvent).then(res => {
      if (oldDescription !== event.description) {
        this.historyService.postLog(
          OclHistoryLog.create(
            'Old title : ' + oldDescription,
            'event',
            'title_update',
            event.attachments,
            event,
            parseEvent.getACL(),
            parseEvent,
          ),
        );
      } else {
        this.historyService.postLog(
          OclHistoryLog.create('Event updated', 'event', 'update', event.attachments, event, parseEvent.getACL(), parseEvent),
        );
      }

      if (activateCheckLists && event.scenario) {
        this.activateCheckList(parseEvent);
      }

      const newEvent = this.newEvent(res);

      if (!isEqual(newDuplicateToOtherModuleValue, oldDuplicateToOtherModuleValue)) {
        // Supprimer
        if (!newDuplicateToOtherModuleValue && oldDuplicateToOtherModuleValue) {
          this.deleteDuplicateEventFromModule(newEvent);
        }
        // Ajouter
        if (newDuplicateToOtherModuleValue && !oldDuplicateToOtherModuleValue) {
          this.duplicateEventToOtherModule(newEvent);
        }
      }

      return newEvent;
    });
  }

  public addInfo(
    event: T,
    message: string,
    nextInfoTime: Date,
    notifications: HolNotification[],
    attachments: HolAttachments,
  ): Promise<HolNextInfo> {
    const eventInfo = new this.ParseEventInfos();
    const eventParse = new this.ParseEvents({ id: event.objectId });
    eventInfo.set('event', eventParse);
    eventInfo.set('message', message);
    eventInfo.set('nextInfoTime', nextInfoTime);
    eventInfo.set('createdBy', Parse.User.current());
    if (eventParse.getACL()) {
      eventInfo.setACL(eventParse.getACL());
    }
    if (attachments) {
      eventInfo.set('attachments', JSON.stringify(attachments));
    }

    return this.requestService.performSaveQuery(eventInfo).then(parseData => {
      return this.markPreviousInfosAsDone(event, nextInfoTime).then(() => {
        const newInfo = new HolNextInfo(parseData);
        if (!event.infos) {
          event.infos = [];
        }
        event.infos.unshift(newInfo);
        const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
        if (addressMailToSend.length) {
          this.mailService.sendNewEventInfoMail(event, newInfo, addressMailToSend);
        }
        const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);
        if (phoneNumbersToSend.length) {
          this.smsService.sendNewEventInfoSMS(event, newInfo, phoneNumbersToSend);
        }
        this.historyService
          .postLog(OclHistoryLog.create(newInfo.message, 'event', 'info', event.attachments, event, eventParse.getACL(), eventParse))
          .then();
        return newInfo;
      });
    });
  }

  public updateInfo(info: HolNextInfo): Promise<HolNextInfo> {
    const parseEventInfo = new this.ParseEventInfos();
    parseEventInfo.id = info.objectId;
    parseEventInfo.set('attachments', JSON.stringify(info.attachments));
    parseEventInfo.set('nextInfoTime', info.nextInfoTime);
    return this.requestService.performSaveQuery(parseEventInfo).then(res => {
      return new HolNextInfo(res);
    });
  }

  public markInfoAsDone(info: HolNextInfo, event: OclEvent, done, manual): Promise<HolNextInfo> {
    const eventInfo = new this.ParseEventInfos({ id: info.objectId });
    const parseEvent = new this.ParseEvents({ id: event.objectId });
    eventInfo.set('done', done);
    eventInfo.set('manual', manual);
    return this.requestService.performSaveQuery(eventInfo).then(parseData => {
      const newInfo = new HolNextInfo(parseData);
      if (manual) {
        this.historyService
          .postLog(
            OclHistoryLog.create(
              newInfo.message,
              'event',
              done ? 'info-done' : 'info-backtodo',
              event.attachments,
              event,
              parseEvent.getACL(),
              parseEvent,
            ),
          )
          .then();
      }
      Object.assign(info, newInfo);
      return newInfo;
    });
  }

  public close(event: T, reason: number, reasonText: string, notifications: HolNotification[]): Promise<void> {
    const parseEvent = new this.ParseEvents({ id: event.objectId });
    return this.requestService
      .performSaveQuery(parseEvent, {
        closeReason: reason,
        closeReasonText: reasonText,
        closeDate: new Date(),
      })
      .then(savedEvent => {
        this.eventsStoreManager.deleteOneEvent(event.objectId);
        remove(this._events, { objectId: event.objectId });
        if (event.channelId) {
          this.chatService.archiveChannel(event.channelId).then(
            () => {
              console.log('channel archived !');
            },
            error => {
              console.error('Error while archiving channel', error.data.body.error);
            },
          );
        }
        event.closeReason = reason;
        event.closeReasonText = reasonText;
        event.closeDate = savedEvent.get('closeDate');
        const addressMailToSend = this.notificationsService.getAddressMailToSend(notifications);
        if (addressMailToSend.length) {
          this.mailService.sendCloseEventMail(event, addressMailToSend, savedEvent.get('updatedAt'));
        }
        const phoneNumbersToSend = this.notificationsService.getPhoneNumbersToSend(notifications);
        if (phoneNumbersToSend.length) {
          this.smsService.sendCloseEventSMS(event, phoneNumbersToSend);
        }
        this.removeCheckList(savedEvent);
        this.historyService
          .postLog(OclHistoryLog.create('Event closed', 'event', 'close', event.attachments, event, parseEvent.getACL(), parseEvent))
          .then();
      });
  }

  public getCheckListForCurrentUser(): Promise<any[]> {
    return Promise.all([this.userService.getCurrentUserFunctions(), this.getAll()]).then(results => {
      const functions = results[0];
      const events = results[1];
      const query = new Parse.Query(this.ParseTask);
      query.containedIn(
        'code',
        functions.map(f => f.shortTitle),
      );
      query.containedIn(
        'scenario',
        events.map(e => e.scenario && e.scenario.code),
      );

      return this.requestService.performFindQuery(query).then(parseTasks => {
        return parseTasks.map(pt => new OclTask(pt));
      });
    });
  }

  public activateCheckList(parseEvent: Parse.Object): Promise<Parse.Object[]> {
    const code = parseEvent.get('scenario').get('code');
    const query = new Parse.Query(this.ParseTaskRef);
    query.equalTo('scenario', code);
    return Promise.all([this.requestService.performFindQuery(query), this.tagsStoreManager.tagsState.pipe(take(1)).toPromise()]).then(
      ([results, allTags]) => {
        const tasksRefs = results.map(t => {
          const defaultTagCodes: string[] = t.get('defaultTags') ? t.get('defaultTags').split('|') : [];
          const defaultTags: HolTag[] = this.tasksService.getDefaultTagsForTask(defaultTagCodes, allTags);
          return new OclTaskRef(t, defaultTags);
        });
        return Promise.all(
          tasksRefs.map(taskRef => {
            const task = new this.ParseTask();
            task.set(this.taskEventColumn, parseEvent);
            task.set('fromModule', this.moduleConfig.config.moduleName);
            task.set('code', taskRef.code);
            task.set('order', taskRef.order);
            task.set('taskTitle', taskRef.taskTitle);
            task.set('taskDescription', taskRef.taskDescription);
            task.set('outputTitle', taskRef.outputTitle);
            task.set('outputDataLabel', taskRef.outputDataLabel);
            task.set('customOutputDataLabel', taskRef.customOutputDataLabel);
            task.set('formIoFormRef', taskRef.formIoFormRef);
            task.set('defaultTags', taskRef.defaultTags.map(t => t.name).join('|'));
            task.set('isConditionalTask', taskRef.isConditionalTask);
            task.set('functionId', taskRef.functionId);
            task.set('status', 'TODO');
            // Compute task ACL from taskRef module + event companies
            if (taskRef.modules.length) {
              const event = new OclEvent(parseEvent);
              return this.rolesService.getACLForCompanies(taskRef.modules, event.companies).then(acl => {
                task.setACL(acl);
                return task;
              });
            } else {
              task.setACL(parseEvent.getACL());
              return task;
            }
          }),
        ).then(tasks => {
          return this.requestService.performSaveAllQuery(tasks);
        });
      },
    );
  }

  public removeCheckList(parseEvent): Promise<Parse.Object[]> {
    const query = new Parse.Query(this.ParseTask);
    query.equalTo(this.taskEventColumn, parseEvent);
    return this.requestService.performFindQuery(query).then(results => {
      return this.requestService.performDestroyAllQuery(results);
    });
  }

  public setChannelId(event: T): Promise<any> {
    const parseEvent = new this.ParseEvents({ id: event.objectId });
    parseEvent.set('channelId', event.channelId);
    return this.requestService.performSaveQuery(parseEvent);
  }

  public fetchNewData(filterDataStartDate?: Date) {
    return this.getAll(true, false, filterDataStartDate).then(events => {
      // STORE
      this.eventsStoreManager.updateEventsFromPooling(events, this.moduleConfig.config.moduleName);
    });
  }

  public fetchNewCheckListData() {
    return this.getCheckListForCurrentUser().then(checkList => {
      this.$rootScope.$broadcast('occPoolService-check-list', checkList);
    });
  }

  private sendNewEventChecklistActivatedNotifications(event: T, infos: any[]) {
    const query = new Parse.Query(Parse.User);
    let emails = [];
    let phoneNumbers = [];
    query.containedIn('username', event.scenario.notifications);
    this.requestService.performFindQuery(query).then(res => {
      if (res.length) {
        emails = res.map(el => el.get('userEmail'));
        phoneNumbers = res.map(el => el.get('phone'));
      }
      if (emails.length) {
        this.mailService.sendNewEventChecklistActivatedMail(event, infos, emails);
      }
      if (phoneNumbers.length) {
        this.smsService.sendNewEventChecklistActivatedSMS(event, infos, phoneNumbers);
      }
    });
  }

  public sendNewEventInfoChecklistActivatedNotifications(event: OclEvent, infos: any[]) {
    const query = new Parse.Query(Parse.Object.extend('User'));
    let emails = [];
    let phoneNumbers = [];
    query.containedIn('username', event.scenario.notifications);
    this.requestService.performFindQuery(query).then(res => {
      if (res.length) {
        emails = res.map(el => el.get('userEmail'));
        phoneNumbers = res.map(el => el.get('phone'));
      }
      if (emails.length) {
        this.mailService.sendNewEventInfoChecklistActivatedMail(event, infos, emails);
      }
      if (phoneNumbers.length) {
        this.smsService.sendNewEventInfoChecklistActivatedSMS(event, infos, phoneNumbers);
      }
    });
  }

  protected abstract markPreviousInfosAsDone(event: T, nextInfoTime: Date): Promise<HolNextInfo[]>;

  protected abstract getAllMCCEvents(): Promise<any[]>;

  // protected abstract getAllGOCEvents(): Promise<any[]>;
  public abstract activateECLModule(eclOptionsId: string, moduleName: string, event: OclEvent, infos: EventInfos[]): void;

  public abstract deactivateECLModule(eclOptionsId: string): void;

  private _getNextScenarioOrder(scenario: HolScenario): Promise<number> {
    if (!scenario) {
      return Promise.resolve(0);
    } else {
      const parseScenario = new this.ParseScenario({ id: scenario.objectId });
      const scenarioOrder = parseScenario.get('nextOrder') || 1;
      let nextScenarioOrder = scenarioOrder + 1;
      if (nextScenarioOrder >= 10) {
        nextScenarioOrder = 1;
      }
      parseScenario.set('nextOrder', nextScenarioOrder);
      return this.requestService.performSaveQuery(parseScenario).then(() => {
        return scenarioOrder;
      });
    }
  }

  protected newEvent(
    parseObject?: Parse.Object,
    eventTags?: Parse.Object[],
    infosToMap?: [],
    scenariosFromOtherModule?: Parse.Object[],
  ): T {
    if (!parseObject) {
      return null;
    }
    const infos = map(infosToMap, info => new HolNextInfo(info));
    const tags = orderBy(
      map(eventTags, et => new HolTag(et.get('tag'))),
      'name',
    );
    return new OclEvent(parseObject, tags, infos, scenariosFromOtherModule) as T;
  }

  protected setAdditionalFields(event: T, parseEvent: Parse.Object) {
    return;
  }

  deleteDuplicateGocEvent(occEvent): void {
    const parseOccEvent = new this.ParseEvents({ id: occEvent.objectId });
    const parseGocEvent = new this.ParseGocEvent({ id: occEvent.gocEvent.objectId });
    parseGocEvent.set('toOCC', false);
    this.requestService.performSaveQuery(parseGocEvent).then(() => {
      this.requestService.performDestroyQuery(
        parseOccEvent,
        () => {
          // STORE
          this.eventsStoreManager.deleteOneEvent(occEvent.objectId);
        },
        error => {
          console.log(error);
        },
      );
    });
  }

  deleteDuplicateOccEvent(event: T): Promise<void> {
    const parseGocEvent = new this.ParseEvents({ id: event.objectId });
    const parseOccEvent = new this.ParseOccEvents({ id: event.occEvent.objectId });
    parseOccEvent.set('toGOC', false);
    const parseFlight = new Parse.Query(this.ParseFlight);
    const parseGocFltEvents = new Parse.Query(this.ParseGocFlightEvents);
    parseGocFltEvents.equalTo('event', parseGocEvent);
    parseGocFltEvents.matchesQuery('flight', parseFlight);
    return Promise.all([
      this.requestService.performSaveQuery(parseOccEvent),
      this.requestService.performDestroyQuery(parseGocEvent),
      this.requestService.performFindAllQuery(parseGocFltEvents),
    ]).then(result => {
      if (result) {
        const fltEventsToDelete = result[2];
        return this.requestService.performDestroyAllQuery(fltEventsToDelete).then(
          () => {
            // STORE
            this.eventsStoreManager.deleteOneEvent(event.objectId);
          },
          error => {
            console.log(error);
          },
        );
      }
    });
  }

  protected duplicateEventToOtherModule(event: T) {
    return;
  }

  protected deleteDuplicateEventFromModule(event: T) {
    return;
  }

  protected getAdditionnalQueries(query: Parse.Query, today) {
    query.doesNotExist('closeReason');
    return query;
  }
}
