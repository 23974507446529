import { Injectable } from '@angular/core';
import { TasksService } from '../../../common/services/tasks.service';
import { HolTask } from '../../../common/models/hol-task';
import { HolTag } from '../../../common/models/hol-tag';
import { RequestService } from '../../../common/services/request.service';
import { EclHistoryService } from '../ecl-history-service/ecl-history.service';
import { EclTagsService } from '../ecl-tags-service/ecl-tags.service';
import { EclTaskTagService } from '../ecl-task-tag.service';
import { EclCrisisTask, EclCrisisTaskRef, EclCrisisUserTask } from '../../models/ecl-crisis-task-ref';
import { EclCrisis } from '../../models/ecl-crisis';
import { isEmpty, isEqual, orderBy } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { RolesService } from '../../../common/services/roles.service';
import { TagTypeColor } from '../../../common/enums/hol-tag-type-color.enum';
import { TagTypesPrefix } from 'src/app/common/enums/hol-tag-types-prefix.enum';
import { HolNotification, HolNotifyFunction } from '../../../common/models/hol-notification.model';
import { EclHistoryLog } from '../../models/ecl-history-log.model';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';
import { take } from 'rxjs/operators';
import { EclMailService } from '../ecl-mail-service/ecl-mail.service';
import { EclSmsService } from '../ecl-sms-service/ecl-sms.service';
import { EclUsersService } from '../ecl-users-service/ecl-users.service';
import { EclDecision } from '../../models/ecl-decision';
import { OclDecision } from '../../../ocl/models/ocl-decision.model';

@Injectable({
  providedIn: 'root',
})
export class EclCrisisTaskService extends TasksService {
  protected ParseCrisis = Parse.Object.extend('ECLCrisis');
  protected ParseTag = Parse.Object.extend('ECLTag');
  protected ParseDecision = Parse.Object.extend('ECLDecisions');
  protected ParseTask = Parse.Object.extend('ECLTask');
  protected ParseTaskRef = Parse.Object.extend('ECLTask_REF');
  protected ParseTaskTag = Parse.Object.extend('ECLTaskTag');
  protected ParseUser = Parse.Object.extend('_User');

  constructor(
    protected requestService: RequestService,
    protected historyService: EclHistoryService,
    protected eclTagsService: EclTagsService,
    protected crisisTaskTagService: EclTaskTagService,
    protected functionStoreManager: EclFunctionStoreManager,
    protected rolesService: RolesService,
    protected eclCrisisStore: EclCrisisStoreManager,
    protected serviceMail: EclMailService,
    protected serviceSMS: EclSmsService,
    protected eclUsersService: EclUsersService,
  ) {
    super(requestService, historyService, eclTagsService, crisisTaskTagService);
  }

  async getCrisisTasksRefByCrisisTypeId(): Promise<EclCrisisTaskRef[]> {
    const crisisTaskQuery = new Parse.Query(this.ParseTaskRef);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.ascending('createdAt');
    crisisTaskQuery.limit(5000);

    const resultBrut = await this.requestService.performFindAllQuery(crisisTaskQuery);
    if (resultBrut.length === 0) {
      return resultBrut.map(item => {
        return new EclCrisisTaskRef(item);
      });
    } else {
      return [];
    }
  }

  async createTaskForCrisis(crisis: EclCrisis): Promise<EclCrisisTask[]> {
    if (crisis.erpType === null || !crisis.erpType.crisisTypeId) {
      throw new Error('Crisis type is null');
    }

    const crisisTaskQuery = new Parse.Query(this.ParseTaskRef);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.equalTo('crisisTypeId', crisis.erpType.crisisTypeId);
    crisisTaskQuery.ascending('createdAt');
    crisisTaskQuery.limit(5000);

    const resultBrut = await this.requestService.performFindAllQuery(crisisTaskQuery);
    if (resultBrut) {
      // Extraire les tags uniques
      /*
      const uniqueTags = Array.from(
        new Set(
          resultBrut
            .map(obj => (obj.get('defaultTags') ? obj.get('defaultTags') : ''))
            .reduce<string[]>((acc, tags) => acc.concat(tags.split('|')), []),
        ),
      );
      */
      const uniqueTags = new Set<string>();

      for (const obj of resultBrut) {
        const tags = obj.get('defaultTags')?.split('|') ?? [];
        tags.forEach(tag => uniqueTags.add(tag.trim()));
      }

      const existingTag = await this.eclTagsService.getAllTagByCrisis(crisis, true);
      const tagStringToAdd = [...uniqueTags].filter(tag => !existingTag.some(t => t.name === tag) && tag.length > 0);

      const tagPromise = tagStringToAdd.map(tag => {
        const color = (() => {
          switch (tag.substring(0, 1)) {
            case TagTypesPrefix.status:
              return TagTypeColor.status;
            case TagTypesPrefix.department:
              return TagTypeColor.department;
            case TagTypesPrefix.stakeholder:
              return TagTypeColor.stakeholder;
            default:
              return TagTypeColor.remaining;
          }
        })();
        return this.eclTagsService.create(color, tag, crisis, true);
      });

      const newTag = await Promise.all(tagPromise);

      existingTag.push(...newTag);
      //Copie dans la base

      const eclCrisisTaskRef = resultBrut.map(item => {
        const listStringTag = item.get('defaultTags') ? item.get('defaultTags').split('|') : [];
        const defaultTags = this.getDefaultTagsForTask(listStringTag, existingTag);
        return new EclCrisisTaskRef(item, [], defaultTags);
      });
      const parseToSaved = eclCrisisTaskRef.map(item => {
        return this.taskRefToTaskParseObject(item, crisis);
      });

      const savedObject = await this.requestService.performSaveAllQuery(parseToSaved);

      return savedObject.map(item => {
        // const eclTaskRef = eclCrisisTaskRef.find(ref => ref.code === item.get('code') && ref.functionId == item.get('functionId') && ref.order == item.get('order') && ref.acl == item.getACL() && ref.crisisTypeId == item.get('crisisTypeId'));
        const listStringTag = item.get('defaultTags') ? item.get('defaultTags').split('|') : [];
        const defaultTags = this.getDefaultTagsForTask(listStringTag, existingTag);
        return new EclCrisisTask(item, [], defaultTags);
      });
    } else {
      return [];
    }
  }

  public getDefaultTagsForTask(tagsCodes: string[], allTags: HolTag[]): HolTag[] {
    return allTags.filter(tag => tag.name && tag.name.trim() === tagsCodes[tagsCodes.findIndex(tg => tag.name.trim() === tg.trim())]);
  }

  canChangeAcl(task: HolTask) {
    return false;
  }

  isAttachmentsMandatory(task: HolTask) {
    return false;
  }

  newTask(parseObject?: Parse.Object, tags?: HolTag[], defaultTags?: HolTag[]): EclCrisisTask {
    return new EclCrisisTask(parseObject, tags, defaultTags);
  }

  protected taskToParseObject(task: EclCrisisTaskRef | EclCrisisTask): Parse.Object {
    const parseObject = super.taskToParseObject(task);

    if (task instanceof EclCrisisTask) {
      parseObject.set('crisis', task.crisis ? new this.ParseCrisis({ id: task.crisis.objectId }) : null);
      parseObject.set('isEditable', task.isEditable || false);
      if (task.decision) {
        parseObject.set('decision', new this.ParseDecision({ id: task.decision?.objectId }));
      }
    } else {
      parseObject.set('isEditable', false);
    }
    parseObject.set('outputDataLabel', task.outputDataLabel);
    parseObject.set('customOutputDataLabel', task.customOutputDataLabel);
    parseObject.set('crisisTypeId', task.crisisTypeId);
    parseObject.set('isDocumentOnly', task.isDocumentOnly || false);
    parseObject.set('frozenByErd', task.frozenByErd);
    parseObject.set('customVisibleBy', task.customVisibleBy);
    parseObject.set('visibleBy', task.visibleBy);
    parseObject.set('attachments', task.attachments ? JSON.stringify(task.attachments) : undefined);
    return parseObject;
  }

  protected taskRefToTaskParseObject(task: EclCrisisTaskRef, crisis: EclCrisis): Parse.Object {
    let parseObject;
    parseObject = new this.ParseTask();
    parseObject.set('createdBy', Parse.User.current());
    parseObject.set('crisis', crisis ? new this.ParseCrisis({ id: crisis.objectId }) : null);

    parseObject.set('outputDataLabel', task.outputDataLabel);
    parseObject.set('customOutputDataLabel', task.customOutputDataLabel);
    parseObject.set('crisisTypeId', task.crisisTypeId);
    parseObject.set('isDocumentOnly', task.isDocumentOnly);
    parseObject.set('frozenByErd', task.frozenByErd);
    parseObject.set('customVisibleBy', task.customVisibleBy);
    parseObject.set('visibleBy', task.visibleBy);
    if (task.acl) {
      if (isEmpty(task.acl.permissionsById)) {
        task.acl.setPublicReadAccess(true);
        task.acl.setPublicWriteAccess(true);
      }
      parseObject.setACL(task.acl);
    }
    parseObject.set('outputTitle', task.outputTitle);
    parseObject.set('code', task.code);
    parseObject.set('order', task.order);
    parseObject.set('subOrder', task.subOrder);
    parseObject.set('status', task.status);
    parseObject.set('functionId', task.functionId);
    parseObject.set('updatedBy', Parse.User.current());
    parseObject.set('taskTitle', task.taskTitle);
    parseObject.set('keywords', task.keywords?.join('|'));
    parseObject.set('taskDescription', task.taskDescription);
    parseObject.set('formIoFormRef', task.formIoFormRef);
    parseObject.set('formIoFormSubmission', task.formIoFormSubmission);
    parseObject.set('attachments', task.attachments ? JSON.stringify(task.attachments) : undefined);
    parseObject.set('isConditionalTask', task.isConditionalTask);
    // parseObject.set('visibilityByFunctionId', crisisTask.visibilityByFunctionId);
    // parseObject.set('createdByFunctionId', crisisTask.createdByFunctionId);
    parseObject.set('isEditable', false);
    parseObject.set('nextInfoTime', task.nextInfoTime);
    parseObject.set('nextInfoDone', task.nextInfoDone);
    parseObject.set('comment', task.comment);
    parseObject.set('customVisibleBy', task.customVisibleBy);
    parseObject.set('isFunctionNotified', task.isFunctionNotified);
    /*
    parseObject.set(
      'defaultTags',
      task.defaultTags
        ? task.defaultTags
          .map(t => t.name)
          .join('|')
          .trim()
        : null,
    );
    */
    parseObject.set(
      'defaultTags',
      task.defaultTags
        ? Array.from(new Set(task.defaultTags.map(t => t.name))) // Supprime les doublons
            .join('|')
            .trim()
        : null,
    );
    if (task.customCreatedAt) {
      parseObject.set('customCreatedAt', task.customCreatedAt);
    }

    return parseObject;
  }

  async getTaskFromDecision(decision: OclDecision) {
    const tasks = await this.eclCrisisStore.$eclCrisisTasks.pipe(take(1)).toPromise();
    return tasks.filter(task => task.decision && task.decision.objectId === decision.objectId);
  }

  getTasksByUser(crisis: EclCrisis): Observable<EclCrisisUserTask> {
    const $crisisTasksByUserSubject: BehaviorSubject<EclCrisisUserTask> = new BehaviorSubject(undefined);
    combineLatest([
      this.rolesService.$companiesRolesFilter,
      this.functionStoreManager.$userFunctions,
      this.functionStoreManager.$eclFunctionState,
      this.functionStoreManager.allTasks,
    ]).subscribe(([roles, functionsUser, functionsState, allTasks]) => {
      if (functionsUser && functionsUser.length && functionsState && functionsState.allUserFunctions && allTasks && allTasks.length) {
        const eclCrisisTasks = this.rolesService.filterFromCompanyRoles(allTasks.filter(t => t && t.crisis.objectId == crisis.objectId));
        functionsUser = this.rolesService.filterFromCompanyRoles(functionsUser);
        const functionsCrisis = this.rolesService.filterFromCompanyRoles(functionsState.functions);
        const buffercrisisTasksByUser: EclCrisisUserTask = {
          tasks: orderBy(
            eclCrisisTasks.filter(t => functionsUser.findIndex(f => f.functionId === t.functionId) > -1),
            [item => (item.status && item.status !== 'DONE' ? 0 : 1), 'nextInfoTime', 'order', 'subOrder', 'code'],
            ['asc', 'asc', 'asc', 'asc', 'asc'],
          ),
          functionInfos: functionsUser
            .map(fUser => {
              const tempFCrisis = functionsCrisis.find(fCrisis => fCrisis.functionId === fUser.functionId);
              if (tempFCrisis && fUser) {
                return {
                  shortTitle: tempFCrisis.shortTitle,
                  title: tempFCrisis.title,
                  functionId: tempFCrisis.functionId,
                  isHolder: fUser.isHolder,
                };
              }
            })
            .filter(el => el !== undefined),
        };
        $crisisTasksByUserSubject.next(buffercrisisTasksByUser);
      } else {
        $crisisTasksByUserSubject.next({ tasks: [], functionInfos: [] });
      }
    });
    return $crisisTasksByUserSubject;
  }

  async getAllTasks(onlyOpenCrisis: boolean = false, onlyClosedCrisis: boolean = false): Promise<EclCrisisTask[]> {
    const crisisTaskQuery = new Parse.Query(this.ParseTask);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.includeAll();
    crisisTaskQuery.descending('createdAt');
    crisisTaskQuery.limit(5000);
    if (onlyOpenCrisis || onlyClosedCrisis) {
      const crisisQuery = new Parse.Query(this.ParseCrisis);
      crisisQuery.equalTo('inProgress', onlyOpenCrisis);
      crisisTaskQuery.matchesQuery('crisis', crisisQuery);
    }

    const crisisTaskTagsQuery = new Parse.Query(this.ParseTaskTag);
    crisisTaskTagsQuery.include('tag');
    crisisTaskTagsQuery.descending('createdAt');
    crisisTaskTagsQuery.matchesQuery('task', crisisTaskQuery);

    const [crisisTasksFromApi, crisisTasksTags, crisisTags] = await Promise.all([
      this.requestService.performFindAllQuery(crisisTaskQuery),
      this.requestService.performFindAllQuery(crisisTaskTagsQuery),
      this.eclTagsService.getAll(false),
    ]);

    if (crisisTasksFromApi) {
      return crisisTasksFromApi.map(crisisTaskFromApi => {
        const tags = this.getTagsForTask(crisisTasksTags, crisisTaskFromApi);
        const defaultTagCodes: string[] = crisisTaskFromApi.get('defaultTags') ? crisisTaskFromApi.get('defaultTags').split('|') : [];
        const defaultTags: HolTag[] = this.getDefaultTagsForTask(
          defaultTagCodes,
          crisisTags.filter(tag => tag.crisis.objectId === crisisTaskFromApi.get('crisis').id),
        );
        return new EclCrisisTask(crisisTaskFromApi, tags && tags.map(t => new HolTag(t.get('tag'))), defaultTags);
      });
    } else {
      return [];
    }
  }

  async updateTask(task: EclCrisisTask, baseTask: EclCrisisTask, notifyFunction: HolNotifyFunction) {
    let change: string[] = [];

    if (task.nextInfoTime != baseTask.nextInfoTime) {
      change.push('UPDATE_TASK_NEXT_INFO');
      // change += `Next info time changed from ${baseTask.nextInfoTime} to ${task.nextInfoTime}`;
    }
    if (task.nextInfoDone != baseTask.nextInfoDone) {
      change.push('UPDATE_TASK_NEXT_INFO_DONE');
      //  change += `Next info done changed from ${baseTask.nextInfoDone} to ${task.nextInfoDone}`;
    }
    if (task.attachments != baseTask.attachments) {
      change.push('UPDATE_TASK_ATTACHMENTS');
      // change += `Attachments changed`;
    }
    if (task.status != baseTask.status) {
      change.push('UPDATE_TASK_STATUS');
      //change += `Status changed from ${baseTask.status} to ${task.status}`;
    }
    if (task.tags != baseTask.tags) {
      change.push('UPDATE_TASK_TAGS');
      //  change += `Tags changed`;
    }
    if (isEqual(task, baseTask)) {
      return;
    }
    const parseObject = this.taskToParseObject(task);
    await this.requestService.performSaveQuery(parseObject);

    this.eclCrisisStore.updateOneCrisisTask(task);

    if (notifyFunction.sendByMail || notifyFunction.sendBySms) {
      this.functionStoreManager.$eclFunctionState.pipe(take(1)).subscribe(async state => {
        const functions = state.functions;
        const allUserFunction = state.allUserFunctions;
        const functionId = task.functionId;
        const functionSelected = state.functions.find(f => f.functionId === functionId);
        const userToSend = allUserFunction.filter(f => f.functionId === functionId && f.aclModules.some(a => task.aclModules.includes(a)));
        if (userToSend) {
          const uniqueUserIds = Array.from(new Set(userToSend.map(value => value.userId)));
          const users = await this.eclUsersService.getUsersWithFunctionsForCrisis(task.crisis);
          const usersToNotify = users.filter(user => uniqueUserIds.includes(user.userId));

          const userMail = usersToNotify.filter(u => u.email && u.email?.trim().length > 0);
          const userSms = usersToNotify.filter(u => u.phone && u.phone?.trim().length > 0);

          if (task.status === 'DONE') {
            if (notifyFunction.sendByMail && userMail.length > 0) {
              this.serviceMail.sendTaskDoneMail(
                task,
                functionSelected.title,
                task.comment,
                userMail.map(u => u.email),
              );
            }
            if (notifyFunction.sendBySms && userSms.length > 0) {
              this.serviceSMS.sendTaskDoneSms(
                task,
                functionSelected.title,
                task.comment,
                userSms.map(u => u.phone),
              );
            }
            change.push('NOTIFY_TASK_DONE');
          } else if (task.status === 'FROZEN') {
            if (notifyFunction.sendByMail && userMail.length > 0) {
              this.serviceMail.sendTaskFrozenMail(
                task,
                functionSelected.title,
                task.comment,
                userMail.map(u => u.email),
              );
            }
            if (notifyFunction.sendBySms && userSms.length > 0) {
              this.serviceSMS.sendTaskFrozenSms(
                task,
                functionSelected.title,
                task.comment,
                userSms.map(u => u.phone),
              );
            }
            change.push('NOTIFY_TASK_FROZEN');
          } else if (task.status === 'TODO') {
            if (notifyFunction.sendByMail && userMail.length > 0) {
              this.serviceMail.sendTaskBackTodoMail(
                task,
                functionSelected.title,
                task.comment,
                userMail.map(u => u.email),
              );
            }
            if (notifyFunction.sendBySms && userSms.length > 0) {
              this.serviceSMS.sendTaskBackTodoSms(
                task,
                functionSelected.title,
                task.comment,
                userSms.map(u => u.phone),
              );
            }
            change.push('NOTIFY_TASK_TODO');
          }
          let log = EclHistoryLog.create(
            task.comment,
            'holTask',
            'UPDATE_TASK',
            task.attachments,
            task,
            task.acl,
            parseObject,
            change,
          ) as EclHistoryLog;
          await this.historyService.postLog(log);
        }
      });
    } else {
      let log = EclHistoryLog.create(
        task.comment,
        'holTask',
        'UPDATE_TASK',
        task.attachments,
        task,
        task.acl,
        parseObject,
        change,
      ) as EclHistoryLog;
      await this.historyService.postLog(log);
    }

    return task;
  }

  async taskFromPooling(lastDatePooling: Date) {
    const crisisTaskQuery = new Parse.Query(this.ParseTask);
    crisisTaskQuery.include('ACL');
    crisisTaskQuery.includeAll();
    crisisTaskQuery.ascending('createdAt');
    crisisTaskQuery.greaterThanOrEqualTo('updatedAt', lastDatePooling);
    crisisTaskQuery.limit(5000);

    /*
    const crisisQuery = new Parse.Query(this.ParseCrisis);
    crisisQuery.equalTo('inProgress', true);
    crisisTaskQuery.matchesQuery('crisis', crisisQuery);

    */
    const crisisTaskTagsQuery = new Parse.Query(this.ParseTaskTag);
    crisisTaskTagsQuery.include('tag');
    crisisTaskTagsQuery.descending('createdAt');
    crisisTaskTagsQuery.matchesQuery('task', crisisTaskQuery);

    const [crisisTasksFromApi, crisisTasksTags, crisisTags] = await Promise.all([
      this.requestService.performFindAllQuery(crisisTaskQuery),
      this.requestService.performFindAllQuery(crisisTaskTagsQuery),
      this.eclTagsService.getAll(false),
    ]);

    if (crisisTasksFromApi) {
      const values = crisisTasksFromApi.map(crisisTaskFromApi => {
        const tags = this.getTagsForTask(crisisTasksTags, crisisTaskFromApi);
        const defaultTagCodes: string[] = crisisTaskFromApi.get('defaultTags') ? crisisTaskFromApi.get('defaultTags').split('|') : [];
        const defaultTags: HolTag[] = this.getDefaultTagsForTask(defaultTagCodes, crisisTags);
        return new EclCrisisTask(crisisTaskFromApi, tags && tags.map(t => new HolTag(t.get('tag'))), defaultTags);
      });

      this.eclCrisisStore.updateManyCrisisTask(values);
    } else {
      return [];
    }
  }

  async save(
    task: EclCrisisTask,
    notifications: { notifications: HolNotification[]; functionToNotify: HolNotifyFunction } = {
      notifications: [],
      functionToNotify: undefined,
    },
    historyLogComment: string,
    changeStatusNotification?: {
      canSendNotification: boolean;
      comment: string;
      type: string;
      functionTitle: string;
    },
  ): Promise<EclCrisisTask> {
    const isCreate = !task.objectId;
    const parseObject = this.taskToParseObject(task);

    return this.requestService.performSaveQuery(parseObject).then(async parseData => {
      let parseTags;
      if (task.tags) {
        if (isCreate) {
          const crisisTaskTags = task.tags.map(tag => {
            return new this.ParseTaskTag({
              task: parseData,
              tag: new this.ParseTag({ id: tag && tag.objectId }),
            });
          });
          parseTags = await this.requestService.performSaveAllQuery(crisisTaskTags);
        } else {
          parseTags = await this.taskTagService.updateTags(task);
        }
      }

      const tags = this.getTagsForTask(parseTags, parseData);
      const bufferCrisisTask = this.newTask(parseData, tags && tags.map(t => new HolTag(t.get('tag'))), task.defaultTags);

      this.afterSave(bufferCrisisTask, isCreate, notifications, historyLogComment, parseData, changeStatusNotification).then();

      let log = EclHistoryLog.create(task.comment, 'holTask', 'CREATE_TASK', task.attachments, task, task.acl, parseObject, [
        'CREATE_TASK',
      ]) as EclHistoryLog;
      await this.historyService.postLog(log);

      return bufferCrisisTask;
    });
  }
}
