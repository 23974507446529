import { CrewNoteModalComponent } from '../../crew/modals/crew-note-modal/crew-note-modal.component';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErpCrisis } from '../models/erp-crisis';
import { ErpCrisisTaskModalComponent } from '../modales/erp-crisis-task-modal/erp-crisis-task-modal.component';
import { AlertModalComponent } from '../../common/modals/alert-modal/alert-modal.component';
import { ConfirmationModalComponent } from '../../common/modals/confirmation-modal/confirmation-modal.component';
import { FunctionUserForCrisis } from '../models/erp-functionUser';
import { CrisisModalComponent } from '../../common/modals/crisis-modal/crisis-modal.component';
import { EclCrisis } from '../../ecl/models/ecl-crisis';
import { HolModalPromptComponent } from '../../common/modals/hol-modal-prompt/hol-modal-prompt.component';
import { FormModalComponent } from '../../common/modals/form-modal/form-modal.component';
import { EclFunction } from '../../ecl/models/ecl-function';
import { EclDecision } from '../../ecl/models/ecl-decision';
import { EclTaskCreateModalComponent } from '../../ecl/modals/ecl-task-create-modal/ecl-task-create-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  constructor(private dialog: MatDialog) {}

  openCrisisTaskModalForCreation(data: { crisis: ErpCrisis; forFunction: string; functionsUserForCrisis?: FunctionUserForCrisis[] }): void {
    this.dialog.open(ErpCrisisTaskModalComponent, {
      data: {
        crisis: data.crisis,
        functionsUserForCrisis: data.functionsUserForCrisis,
        forFunction: data.forFunction,
      },
    });
  }

  openEclCrisisTaskModalForCreation(crisis: EclCrisis, functions: EclFunction[], decision?: EclDecision): void {
    this.dialog.open(EclTaskCreateModalComponent, {
      data: {
        crisis: crisis,
        functions: functions,
        decision: decision,
      },
    });
  }

  openCrisisModal(data: { erpCrisis: ErpCrisis; eclCrisis: EclCrisis }) {
    this.dialog.open(CrisisModalComponent, {
      disableClose: true,
      data: {
        erpCrisis: data.erpCrisis,
        eclCrisis: data.eclCrisis,
      },
    });
  }

  openConfirmModal(data): Promise<any> {
    return this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: data.title,
          modalContent: data.content,
          modalQuestion: data.question,
        },
      })
      .afterClosed()
      .toPromise();
  }

  openAlertModal(data): Promise<any> {
    return this.dialog
      .open(AlertModalComponent, {
        data: {
          modalTitle: data.title,
          modalContent: data.content,
          modalType: data.modalType,
        },
      })
      .afterClosed()
      .toPromise();
  }

  openEclModal(data): Promise<any> {
    return this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          modalTitle: data.title,
          modalContent: data.content,
          modalType: data.modalType,
        },
      })
      .afterClosed()
      .toPromise();
  }

  openCrewNoteModal(data): Promise<any> {
    return this.dialog
      .open(CrewNoteModalComponent, {
        data: {
          modalTitle: data.title,
          note: data.note || '',
          toOccEnabled: data.toOccEnabled ? data.toOccEnabled : false,
        },
      })
      .afterClosed()
      .toPromise();
  }

  openPromptModal() {
    const dialogRef = this.dialog.open(HolModalPromptComponent, {
      data: { name: 'name', animal: 'animal', contextText: 'context' },
    });
    return dialogRef.afterClosed().toPromise();
  }

  openFormModal(data): Promise<any> {
    const dialogRef = this.dialog.open(FormModalComponent, {
      height: '80%',
      width: '80%',
      data: {
        formIoCredentials: data.formIoCredentials,
        isHolder: data.isHolder,
        task: data.task,
        translateKey: data.translateKey,
      },
    });
    return dialogRef.afterClosed().toPromise();
  }
}
