import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
})
export class DatetimePickerComponent implements OnInit {
  @Input() useUTC: boolean = false;
  @Input() defaultDate: Date;
  @Output() dateTimeSelected = new EventEmitter<Date>();

  pickerOpen = false;
  displayDateTime: string = '';
  errorMessage: string = '';

  currentYear: number;
  currentMonth: number;
  selectedDate: Date;

  selectedTime: string = '00:00';
  //TODO mettre en loc
  weekDays = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
  months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  calendarDays: Date[] = [];

  constructor() {}

  ngOnInit() {
    let initialDate: Date;

    if (this.defaultDate) {
      initialDate = new Date(this.defaultDate);
    } else {
      initialDate = new Date();
    }

    if (this.useUTC) {
      // Utiliser les méthodes UTC pour extraire les valeurs
      this.currentYear = initialDate.getUTCFullYear();
      this.currentMonth = initialDate.getUTCMonth();
      const day = initialDate.getUTCDate();
      const hours = initialDate.getUTCHours().toString().padStart(2, '0');
      const minutes = initialDate.getUTCMinutes().toString().padStart(2, '0');
      this.selectedTime = `${hours}:${minutes}`;
      this.selectedDate = new Date(Date.UTC(this.currentYear, this.currentMonth, day));
      this.selectedDate.setHours(12, 0, 0, 0);
    } else {
      this.currentYear = initialDate.getFullYear();
      this.currentMonth = initialDate.getMonth();
      const day = initialDate.getDate();
      const hours = initialDate.getHours().toString().padStart(2, '0');
      const minutes = initialDate.getMinutes().toString().padStart(2, '0');
      this.selectedTime = `${hours}:${minutes}`;
      this.selectedDate = new Date(this.currentYear, this.currentMonth, day);
      this.selectedDate.setHours(12, 0, 0, 0);
    }

    this.generateCalendar();
    this.updateDisplayDateTime();
  }

  private updateDisplayDateTime() {
    if (this.selectedDate && this.selectedTime) {
      const timeParts = this.selectedTime.split(':');
      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      let date: Date;
      if (this.useUTC) {
        date = new Date(
          Date.UTC(this.selectedDate.getUTCFullYear(), this.selectedDate.getUTCMonth(), this.selectedDate.getUTCDate(), hours, minutes),
        );
      } else {
        date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), hours, minutes);
      }

      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC',
      };
      this.displayDateTime = new Intl.DateTimeFormat('fr-FR', options).format(date);
    }
  }

  openPicker() {
    if (this.selectedDate && this.selectedTime) {
      // Synchroniser le picker avec la date et l'heure sélectionnées
      this.currentYear = this.useUTC ? this.selectedDate.getUTCFullYear() : this.selectedDate.getFullYear();
      this.currentMonth = this.useUTC ? this.selectedDate.getUTCMonth() : this.selectedDate.getMonth();
      this.generateCalendar();
    }
    this.pickerOpen = true;
  }

  closePicker() {
    this.pickerOpen = false;
  }

  togglePicker(event: Event) {
    event.stopPropagation();
    this.pickerOpen = !this.pickerOpen;
    if (this.pickerOpen) {
      this.openPicker();
    }
  }

  // Optionnel : ouvrir le picker lorsque l'utilisateur clique dans le champ
  onInputFocus() {
    // this.pickerOpen = true; // Décommentez si vous voulez que le picker s'ouvre au focus
  }

  onInputChange(event: Event) {
    let input = (event.target as HTMLInputElement).value;

    // Supprimer tous les caractères non numériques
    const numericInput = input.replace(/\D/g, '');

    // Appliquer le masque
    let maskedInput = '';
    const parts = [2, 2, 4, 2, 2]; // JJ MM AAAA HH MM
    let position = 0;

    for (let i = 0; i < parts.length; i++) {
      if (numericInput.length > position) {
        const part = numericInput.substr(position, parts[i]);
        maskedInput += part;
        position += parts[i];

        // Ajouter les séparateurs
        if (i === 0 || i === 1) {
          maskedInput += '/';
        } else if (i === 2) {
          maskedInput += ' ';
        } else if (i === 3 && position < numericInput.length + 1) {
          maskedInput += ':';
        }
      }
    }

    this.displayDateTime = maskedInput;
    this.parseInputDateTime(maskedInput);
  }

  // Méthode pour parser la date et l'heure saisies par l'utilisateur
  parseInputDateTime(input: string) {
    // Expression régulière pour le format JJ/MM/AAAA HH:MM
    const dateTimeRegex = /^(\d{2})\/(\d{2})\/(\d{4})\s(\d{2}):(\d{2})$/;
    const match = dateTimeRegex.exec(input);

    if (match) {
      const day = parseInt(match[1], 10);
      const month = parseInt(match[2], 10) - 1; // Les mois commencent à 0
      const year = parseInt(match[3], 10);
      const hours = parseInt(match[4], 10);
      const minutes = parseInt(match[5], 10);

      if (this.isValidDate(day, month, year) && this.isValidTime(hours, minutes)) {
        this.errorMessage = '';
        if (this.useUTC) {
          this.selectedDate = new Date(Date.UTC(year, month, day, 12, 0));
        } else {
          this.selectedDate = new Date(year, month, day, 12, 0);
        }
        this.selectedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        this.confirm();
      } else {
        this.errorMessage = 'Date ou heure invalide.';
      }
    } else {
      if (input.length === 16) {
        this.errorMessage = 'Format invalide. Utilisez JJ/MM/AAAA HH:MM.';
      } else {
        this.errorMessage = '';
      }
    }
  }

  // Méthode pour vérifier la validité de la date
  isValidDate(day: number, month: number, year: number): boolean {
    const date = new Date(year, month, day);
    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  }

  // Méthode pour vérifier la validité de l'heure
  isValidTime(hours: number, minutes: number): boolean {
    return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
  }

  generateCalendar() {
    this.calendarDays = [];
    const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
    const dayOfWeek = firstDayOfMonth.getDay() || 7;
    const daysInPreviousMonth = new Date(this.currentYear, this.currentMonth, 0).getDate();

    // Remplir les jours du mois précédent
    for (let i = dayOfWeek - 2; i >= 0; i--) {
      this.calendarDays.push(new Date(this.currentYear, this.currentMonth - 1, daysInPreviousMonth - i));
    }

    const daysInMonth = new Date(this.currentYear, this.currentMonth + 1, 0).getDate();

    // Remplir les jours du mois courant
    for (let i = 1; i <= daysInMonth; i++) {
      this.calendarDays.push(new Date(this.currentYear, this.currentMonth, i));
    }

    // Remplir les jours du mois suivant
    const remainingDays = 42 - this.calendarDays.length;
    for (let i = 1; i <= remainingDays; i++) {
      this.calendarDays.push(new Date(this.currentYear, this.currentMonth + 1, i));
    }
  }

  changeMonth(offset: number) {
    this.currentMonth += offset;
    if (this.currentMonth < 0) {
      this.currentMonth = 11;
      this.currentYear--;
    } else if (this.currentMonth > 11) {
      this.currentMonth = 0;
      this.currentYear++;
    }
    this.generateCalendar();
  }

  selectDate(date: Date) {
    this.selectedDate = date;
    this.selectedDate.setHours(12, 0, 0, 0);
  }

  isSelectedDate(date: Date): boolean {
    return this.selectedDate && date.getTime() === this.selectedDate.getTime();
  }

  isCurrentDate(date: Date): boolean {
    const now = new Date();
    return date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
  }

  confirm() {
    if (this.selectedDate != null && this.selectedTime != null) {
      const timeParts = this.selectedTime.split(':');
      if (timeParts.length !== 2) {
        alert('Veuillez saisir une heure valide au format HH:mm.');
        return;
      }

      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        alert('Veuillez saisir une heure valide entre 00:00 et 23:59.');
        return;
      }

      let date: Date;
      if (this.useUTC) {
        date = new Date(
          Date.UTC(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), hours, minutes),
        );
      } else {
        date = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth(), this.selectedDate.getDate(), hours, minutes);
      }

      this.updateDisplayDateTime();
      this.dateTimeSelected.emit(date);
      this.closePicker();
    } else {
      alert('Veuillez sélectionner une date et une heure.');
    }
  }
}
