import { cloneDeep } from 'lodash';
import { LayoutActions, LayoutActionTypes } from './ecl-layout.action';
import { EclLayout } from '../../ecl.model';

const layoutInitialState = {
  tasksFilterFunction: 'ALL',
  tasksFilterStatus: [],
  searchValueFilter: '',
  filterPanelOpened: false,
};

export function EclLayoutReducer(state: EclLayout = layoutInitialState, action: LayoutActions): EclLayout {
  switch (action.type) {
    case LayoutActionTypes.SET_TASKS_FILTER:
      return {
        ...cloneDeep(state),
        ...action.payload,
      };
      break;
    case LayoutActionTypes.SET_SEARCH_VALUE_FILTER:
      return {
        ...cloneDeep(state),
        searchValueFilter: action.payload,
      };
      break;
    case LayoutActionTypes.SET_FILTERS_PANEL_STATUS:
      return {
        ...cloneDeep(state),
        filterPanelOpened: action.payload,
      };
      break;
    default:
      return state;
  }
}
