import { Pipe, PipeTransform } from '@angular/core';
import { HolObject } from '../../models/hol-object';
import { OclGroup } from '../../../ocl/models/ocl-group.model';
import { GocStopover } from 'src/app/goc/models/goc-stopover.model';
import { HolUserWithCompanies } from '../../models/hol-user.model';
import { KeyValue } from '@angular/common';
import { EclFunction, EclUserFunction, EclUserFunctionRef } from '../../../ecl/models/ecl-function';
import { EclTeamDisplayLineByFunction } from '../../../ecl/models/ecl-team-display-line-by-function';
import { HolDocument } from '../../models/hol-document';

@Pipe({
  name: 'aclGroupFilter',
})
export class AclGroupFilterPipe implements PipeTransform {
  transform(values: OclGroup<HolObject>[], companiesRolesFilter?: string[]): any {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      if (!companiesRolesFilter || !companiesRolesFilter.length) {
        return values;
      }
      return values.filter(o => {
        if (!o.items || !o.items.length) {
          return true;
        }
        return o.items.find(item => {
          if (!item.companies || !item.companies.length) {
            return true;
          }
          return !!item.companies.find(c => {
            return companiesRolesFilter.indexOf(c) > -1;
          });
        });
      });
    }
    return null;
  }
}

@Pipe({
  name: 'aclStopoverFilter',
})
export class AclStopoverFilterPipe implements PipeTransform {
  transform(values: GocStopover[], companiesRolesFilter?: string[]): any {
    if (values && companiesRolesFilter && companiesRolesFilter.length) {
      if (!companiesRolesFilter || !companiesRolesFilter.length) {
        return values;
      }
      return values.filter(item => {
        if (!item.inFlight.companies || !item.inFlight.companies.length || !item.outFlight.companies || !item.outFlight.companies.length) {
          return true;
        }
        return (
          !!item.inFlight.companies.find(c => {
            return companiesRolesFilter.indexOf(c) > -1;
          }) &&
          !!item.outFlight.companies.find(c => {
            return companiesRolesFilter.indexOf(c) > -1;
          })
        );
      });
    }
    return null;
  }
}

@Pipe({
  name: 'aclFilter',
})
export class AclFilterPipe implements PipeTransform {
  transform(values: HolObject[], companiesRolesFilter?: string[]): any {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      return values.filter(item => {
        if (!item.companies || !item.companies.length) {
          return true;
        }
        return !!item.companies.find(c => {
          return companiesRolesFilter.indexOf(c) > -1;
        });
      });
    }
    //return an empty array to have the possibility to clear the filter and no data showen
    return [];
  }
}

@Pipe({
  name: 'aclFilterUser',
})
export class AclFilterUserPipe implements PipeTransform {
  transform(
    values: Array<KeyValue<HolUserWithCompanies, EclUserFunctionRef[]>>,
    companiesRolesFilter?: string[],
  ): Array<KeyValue<HolUserWithCompanies, EclUserFunctionRef[]>> {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      return values.filter(entry => {
        if (!entry.key.companies || !entry.key.companies.length) {
          return true;
        }
        return !!entry.key.companies.find(c => {
          return companiesRolesFilter.indexOf(c.name) > -1;
        });
      });
    }
    return values;
  }
}

@Pipe({
  name: 'aclFilterFunction',
})
export class AclFilterFunctionPipe implements PipeTransform {
  transform(
    values: Array<KeyValue<EclFunction, EclUserFunction>>,
    companiesRolesFilter?: string[],
  ): Array<KeyValue<EclFunction, EclUserFunction>> {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      return values.filter(entry => {
        if (!entry.key.companies || !entry.key.companies.length) {
          return true;
        }
        return !!entry.key.companies.find(c => {
          return companiesRolesFilter.indexOf(c) > -1;
        });
      });
    }
    return values;
  }
}

@Pipe({
  name: 'aclFilterArrayFunction',
})
export class AclFilterArrayFunctionPipe implements PipeTransform {
  transform(
    values: Array<KeyValue<EclFunction, EclUserFunction[]>>,
    companiesRolesFilter?: string[],
  ): Array<KeyValue<EclFunction, EclUserFunction[]>> {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      return values.filter(entry => {
        if (!entry.key.companies || !entry.key.companies.length) {
          return true;
        }
        return !!entry.key.companies.find(c => {
          return companiesRolesFilter.indexOf(c) > -1;
        });
      });
    }
    return values;
  }
}

@Pipe({
  name: 'aclFilterEclDisplayLineFunction',
})
export class AclFilterEclDisplayLineFunctioPipe implements PipeTransform {
  transform(values: EclTeamDisplayLineByFunction[], companiesRolesFilter?: string[]): EclTeamDisplayLineByFunction[] {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      return values.filter(entry => {
        if (!entry.eclFunction.companies || !entry.eclFunction.companies.length) {
          return true;
        }
        return !!entry.eclFunction.companies.find(c => {
          return companiesRolesFilter.indexOf(c) > -1;
        });
      });
    }

    return [];
  }
}

@Pipe({
  name: 'aclFilterDocument',
})
export class AclFilterDocumentPipe implements PipeTransform {
  transform(values: HolDocument[], companiesRolesFilter?: string[]): HolDocument[] {
    if (values && values.length && companiesRolesFilter && companiesRolesFilter.length) {
      return values.filter(entry => {
        if (!entry.item.companies || !entry.item.companies.length) {
          return true;
        }
        return !!entry.item.companies.find(c => {
          return companiesRolesFilter.indexOf(c) > -1;
        });
      });
    }
    return values;
  }
}
