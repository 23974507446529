import { HolObject } from '../../common/models/hol-object';
import { HolUser, HolUserWithCompanies } from '../../common/models/hol-user.model';
import { EclCrisis } from './ecl-crisis';

export class EclFunction extends HolObject {
  functionId: string;
  title: string;
  shortTitle: string;
  tagId: string;
  tag?: EclFunctionTag;
  tasksSummary: string;
  phone: string;
  email: string;
  deleted: boolean;
  isHolder: boolean;
  taskCode: string;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.taskCode = parseObject.get('taskCode');
    this.functionId = parseObject.get('functionId');
    this.title = parseObject.get('title');
    this.shortTitle = parseObject.get('shortTitle');
    this.tagId = parseObject.get('tagId');
    this.tasksSummary = parseObject.get('tasksSummary');
    this.phone = parseObject.get('phone');
    this.email = parseObject.get('email');
    this.deleted = parseObject.get('deleted') ? parseObject.get('deleted') : false;
    this.isHolder = parseObject.get('isHolder') ? parseObject.get('isHolder') : false;
  }
}

export class EclUserFunction extends HolObject {
  functionId: string;
  userId: string;
  isHolder: boolean;
  // fields for direct access to function and user
  function: EclFunction;
  user: HolUser;
  crisis: EclCrisis;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.functionId = parseObject.get('functionId');
    this.userId = parseObject.get('userId');
    this.isHolder = parseObject.get('isHolder') ? parseObject.get('isHolder') : false;
    this.crisis = new EclCrisis(parseObject.get('crisis'));
  }

  get shortTitle(): string {
    return this.function && this.function.shortTitle;
  }
}

export class EclUserFunctionRef extends EclUserFunction {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
  }
}

export class EclFunctionTag extends HolObject {
  tagId: string;
  title: string;
  order: number;

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.tagId = parseObject.get('tagId');
    this.title = parseObject.get('title');
    this.order = parseObject.get('order');
  }
}

export interface EclOwnerNotificationData {
  owner: HolUserWithCompanies;
  users: HolUserWithCompanies[];
  acl: string;
  functionName: string;
  eclCrisis: EclCrisis;
}
