import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclFunction } from '../../models/ecl-function';
import { EclDecision } from '../../models/ecl-decision';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { EclCrisisTask, EclVisiblityStatus } from '../../models/ecl-crisis-task-ref';
import { FormsModule, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { SectionWithTitleComponent } from '../../../common/components/section-with-title/section-with-title.component';
import { LMarkdownEditorModule, MarkdownEditorComponent, MdEditorOption } from 'ngx-markdown-editor';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AclSelectorComponent } from '../../../common/components/acl-selector/acl-selector.component';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { orderBy } from 'lodash';
import { EclCrisisStoreManager } from '../../store/crisis/crisis.store-manager';
import { EclCrisisTaskService } from '../../services/ecl-crisis-task-service/ecl-crisis-task.service';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';
import { take } from 'rxjs/operators';
import { AclIndicatorComponent } from '../../../common/components/acl-indicator/acl-indicator.component';
import { HelperService } from '../../../common/services/helper.service';
import { ToasterService } from '../../../common/services/toaster/toaster.service';
import { LoadingComponent } from '../../../common/components/loading/loading.component';

@Component({
  selector: 'app-ecl-task-create-modal',
  templateUrl: './ecl-task-create-modal.component.html',
  styleUrls: ['./ecl-task-create-modal.component.scss'],
  standalone: true,
  imports: [
    SectionWithTitleComponent,
    MatToolbarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    TranslateModule,
    LMarkdownEditorModule,
    MatIconModule,
    ReactiveFormsModule,
    AclSelectorComponent,
    NgIf,
    NgForOf,
    MatButtonModule,
    AclIndicatorComponent,
    LoadingComponent,
  ],
})
export class EclTaskCreateModalComponent implements OnInit, OnDestroy {
  public options: MdEditorOption = {
    resizable: false,
    showBorder: false,
    hideIcons: ['FullScreen', 'Image'],
    showPreviewPanel: false,
  };
  protected eclCrisis: EclCrisis;
  protected eclFunctions: EclFunction[] = [];
  protected eclDecision?: EclDecision;
  protected functionActivated: EclFunction[] = [];
  protected crisisTask: EclCrisisTask;
  protected form: UntypedFormGroup;
  protected functionsUserForCrisisSelected: EclFunction;
  protected functionUser: EclFunction[];
  defaultTitlePlaceHolder: string;
  titlePlaceHolder: string;
  defaultOutputDataLabelPlaceHolder: string;
  outputDataLabelPlaceHolder: string;
  defaultOutputTitlePlaceHolder: string;
  outputTitlePlaceHolder: string;
  public acl: Parse.ACL;
  public isSaving = false;
  public saveLater: boolean = false;

  @ViewChild('mdEditor', { static: false }) mdEditor: MarkdownEditorComponent;
  @ViewChild('aclSelector', { static: false }) aclSelector: AclSelectorComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<EclTaskCreateModalComponent>,
    protected moduleConfig: ModuleConfigService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private eclStore: EclCrisisStoreManager,
    private taskService: EclCrisisTaskService,
    private eclFunctionSM: EclFunctionStoreManager,
    private helperService: HelperService,
    private toaster: ToasterService,
  ) {}

  ngOnInit() {
    this.eclCrisis = this.data.crisis;
    this.saveLater = this.data.saveLater;
    this.eclDecision = this.data.decision;

    this.crisisTask = new EclCrisisTask();
    this.crisisTask.crisis = this.eclCrisis;
    this.acl = this.eclCrisis.acl;

    this.eclFunctions = this.data.functions;

    this.eclFunctionSM.$eclFunctionState.subscribe(eclFunctionState => {
      this.functionUser = eclFunctionState.currentUserFunctions;
    });

    let filterFunc: EclFunction[] = [];
    // Patch si pas de functionIdToNotify, alors toutes les fonctions sont engagés.
    if (this.eclCrisis.functionsToNotify.length === 0) {
      filterFunc = this.eclFunctions;
    } else {
      filterFunc = this.eclFunctions.filter(f => this.eclCrisis.functionsToNotify.includes(f.functionId));
    }
    filterFunc = filterFunc
      .map(f => {
        f.parseACL(this.helperService.intersectACLs(f.acl, this.eclCrisis.acl));
        return f;
      })
      .filter(f => {
        return f.companies.length > 0;
      });

    this.functionActivated = orderBy(filterFunc, 'shortTitle');

    this.defaultLabel();
    this.form = new UntypedFormGroup({});
    this.initTask();
  }

  private defaultLabel() {
    this.defaultOutputDataLabelPlaceHolder = this.translate.instant('ECL.MODALS.TASK.OUTPUT_DATA_LABEL_PLACEHOLDER');
    this.outputDataLabelPlaceHolder = this.defaultOutputDataLabelPlaceHolder;
    this.defaultOutputTitlePlaceHolder = this.translate.instant('ECL.MODALS.TASK.OUTPUT_TITLE_PLACEHOLDER');
    this.outputTitlePlaceHolder = this.defaultOutputTitlePlaceHolder;
    this.defaultTitlePlaceHolder = this.translate.instant('ECL.MODALS.TASK.TASK_TITLE_PLACEHOLDER');
    this.titlePlaceHolder = this.defaultTitlePlaceHolder;
  }

  private initTask() {
    this.crisisTask.isEditable = true;
    this.crisisTask.status = 'TODO';
    this.crisisTask.crisisTypeId = this.eclCrisis.erpType?.crisisTypeId;
    this.crisisTask.taskTitle = '';
    this.crisisTask.taskDescription = this.eclDecision
      ? this.eclDecision.contentText +
        ' (' +
        this.translate.instant('ERP.MODALS.DECISION.NEW_TASK_TITLE', {
          date: this.datePipe.transform(new Date(), 'dd/MM/yyyy').toString().toLocaleUpperCase(),
        }) +
        ')'
      : '';
    this.crisisTask.functionId = '';
    this.crisisTask.order = 0;
    this.crisisTask.outputDataLabel = '';
    this.crisisTask.outputTitle = '';
    this.crisisTask.code = '';
    this.crisisTask.isDocumentOnly = false;
    this.crisisTask.isEditable = true;
    this.crisisTask.visibleBy = EclVisiblityStatus.all;
    this.crisisTask.attachments = this.eclDecision ? this.eclDecision.attachments : null;
    this.crisisTask.tags = this.eclDecision ? this.eclDecision.tags : [];

    this.eclFunctionSM.$eclFunctionState.pipe(take(1)).subscribe(eclFunctionState => {
      this.selectTaskCode(eclFunctionState.selectedUserFunction);
    });
  }

  get userFunctions(): EclFunction[] {
    return this.functionActivated.filter(f => this.functionUser.some(uf => uf.functionId === f.functionId));
  }

  get otherFunctions(): EclFunction[] {
    return this.functionActivated.filter(f => !this.functionUser.some(uf => uf.functionId === f.functionId));
  }

  ngOnDestroy() {}

  selectTaskCode(event: EclFunction) {
    this.functionsUserForCrisisSelected = this.functionActivated.find(f => f.functionId == event.functionId);

    this.crisisTask.acl = this.functionsUserForCrisisSelected.acl;
    this.acl = this.functionsUserForCrisisSelected.acl;

    if (this.aclSelector) {
      this.aclSelector.deselectAll();
    }
    setTimeout(() => {
      this.aclSelector.selectAll();
    }, 500);
  }

  outputDataLabelChange(event: string) {
    this.crisisTask.outputDataLabel = event;
  }

  async setCrisisTaskValues() {
    const selectedFn = this.functionsUserForCrisisSelected;

    if (selectedFn) {
      this.crisisTask.code = selectedFn.taskCode;
      this.crisisTask.functionId = selectedFn.functionId;
      this.crisisTask.order = 1;
      this.crisisTask.subOrder = '';
    }

    let allTask = await this.eclStore.$eclCrisisTasks.pipe(take(1)).toPromise();

    allTask = allTask.filter(t => t.crisis.objectId === this.eclCrisis.objectId);

    const crisisTasksOrdered = orderBy(
      allTask.filter(t => {
        const taskComp = this.getCompaniesNameFromAcl(t.acl);
        const newTaskComp = this.getCompaniesNameFromAcl(this.crisisTask.acl);
        const intersection = taskComp.filter((x: string) => newTaskComp.includes(x));
        return t.functionId === selectedFn.functionId && t.status !== 'DONE' && intersection.length > 0;
      }),
      ['order', 'subOrder'],
      ['asc', 'asc'],
    );

    if (crisisTasksOrdered.length) {
      const selectedTasks = crisisTasksOrdered.filter(c => {
        return c.order === crisisTasksOrdered[0].order;
      });
      const taskModel = selectedTasks[selectedTasks.length - 1];
      this.crisisTask.order = taskModel.order;
      this.crisisTask.code = taskModel.code;
      this.crisisTask.subOrder = this.nextChar(taskModel.subOrder);
      this.crisisTask.acl = this.functionsUserForCrisisSelected.acl;
    }
  }

  nextChar(c: string) {
    return c === '' ? 'a' : c == 'z' ? 'a' : c == 'Z' ? 'A' : String.fromCharCode(c.charCodeAt(0) + 1);
  }

  getCompaniesNameFromAcl(acl: Parse.ACL): string[] {
    const ROLE_PARTS_REGEXP = /^([A-Z]+)_([A-Z]+)(?:-([A-Z]+))?_(READ|WRITE)/;
    let comp: string[] = [];
    if (!acl) {
      return [];
    }
    comp = Object.keys(acl.permissionsById).map(el => {
      const r = el.replace('role:', '');
      if (ROLE_PARTS_REGEXP.test(r)) {
        const parts = ROLE_PARTS_REGEXP.exec(r);
        if (parts) {
          return parts[2];
        } else {
          return '';
        }
      } else {
        return '';
      }
    });
    comp = comp.filter(el => el !== '');
    comp = this.removeDuplicates(comp);
    return comp;
  }

  removeDuplicates(arr: string[]): string[] {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  protected async saveCrisisTask() {
    this.isSaving = true;
    await this.setCrisisTaskValues();

    if (this.saveLater) {
      this.dialogRef.close(this.crisisTask);
    } else {
      this.taskService
        .save(
          this.crisisTask,
          {
            notifications: [],
            functionToNotify: undefined,
          },
          '',
          null,
        )
        .then(task => {
          if (task) {
            this.eclStore.createOneCrisisTask(task);
            this.dialogRef.close();
          } else {
            // ereur ?
            this.isSaving = false;
          }
        });
    }
  }

  notInFunc(eclFuncion: EclFunction) {
    return !this.functionUser.map(f => f.functionId).includes(eclFuncion.functionId);
  }
}
