import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../common/components/base/base.component';
import { EclFunction } from '../../models/ecl-function';
import { EclFunctionStoreManager } from '../../store/function/function.store-manager';

@Component({
  selector: 'app-ecl-user-function-detail',
  template: ` <div class="box" [class.filter]="activeFilter" (click)="!readonly && toggleSelected($event)">
    <span>{{ function.shortTitle || function.title }}</span>
  </div>`,
  styleUrls: ['./ecl-user-function-detail.component.scss'],
})
export class EclUserFunctionDetailComponent extends BaseComponent {
  @Input()
  public function?: EclFunction = undefined;
  @Input() public readonly = false;
  @Input() public localFilter = false;
  private filterFunction?: EclFunction;
  @Output() public onSelectFunction?: EventEmitter<EclFunction> = new EventEmitter<EclFunction>();

  constructor(private functionStoreManager: EclFunctionStoreManager) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.localFilter) {
      this.functionStoreManager.$filterFunction.subscribe(ff => (this.filterFunction = ff));
    }
  }

  public get activeFilter(): boolean {
    return this.filterFunction && this.filterFunction.functionId === this.function.functionId;
  }

  public toggleSelected(event: Event) {
    event.stopPropagation();
    if (!this.localFilter) {
      const newFunction: EclFunction = this.activeFilter ? undefined : this.function;
      this.functionStoreManager.updateFilterFunction(newFunction);
    } else {
      this.filterFunction = this.activeFilter ? null : this.function;
      this.onSelectFunction.emit(this.filterFunction);
    }
  }
}
