import { Component, Input, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-section-with-title',
  templateUrl: './section-with-title.component.html',
  styleUrls: ['./section-with-title.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class SectionWithTitleComponent implements OnInit {
  @Input() icon: string;
  @Input() titleKey: string;
  @Input() customImgUrl?: string;

  constructor() {}

  ngOnInit(): void {}
}
