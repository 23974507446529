import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { HOLOptions, HolOptionsService } from '../../services/hol-options.service';
import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { StateService, TransitionService, UIRouter } from '@uirouter/core';
import { UIRouterModule } from '@uirouter/angular';
import { MatListModule } from '@angular/material/list';
import { OptionsService } from '../../services/options.service';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-menu-nav',
  templateUrl: 'menu-nav.component.html',
  styleUrls: ['menu-nav.component.scss'],
  standalone: true,
  imports: [NgForOf, TranslateModule, UIRouterModule, MatListModule, MatIconModule, ReactiveFormsModule, FormsModule, NgIf],
})
export class MenuNavComponent extends BaseComponent implements OnInit, OnDestroy {
  protected options: HOLOptions;
  protected activeModules: ModuleNames[];
  protected otherModules: ModuleNames[];
  protected currentModule: ModuleNames;
  protected companyLogoUrl: string;
  protected dropdownOpen: boolean = true;

  public constructor(
    private readonly holOptions: HolOptionsService,
    private readonly gdcOptions: OptionsService,
    private readonly state: StateService,
    private router: UIRouter,
    private transitionService: TransitionService,
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.options = await this.holOptions.get();

    this.companyLogoUrl = this.gdcOptions.getCompanyLogoUrl();
    // Filtrer les modules valides et les trier par ordre
    this.activeModules = this.options.activeModules
      .filter((module): module is ModuleNames => Object.values(ModuleNames).includes(module as ModuleNames))
      .sort((a, b) => ModuleMetadata[a].order - ModuleMetadata[b].order);

    // Écouter les changements d’état avec UI-Router
    this.transitionService.onSuccess({}, () => {
      const state = this.router.globals.current.name; // Récupérer l'état actuel
      this.updateCurrentModule(state);
    });

    // Initialiser le module courant au démarrage
    this.updateCurrentModule(this.router.globals.current.name);
    /*
    // Trouver un module dont le state correspond
    const matchingModule = this.activeModules.find((module) => this.state.includes(ModuleMetadata[module].state));

    // Définir le module courant : correspondance > premier actif > valeur par défaut
    this.currentModule = matchingModule ?? this.activeModules[0] ?? ModuleNames.ECL;
    this.otherModules = this.activeModules.filter((module) => module !== this.currentModule);
    */
  }

  isActive(state: string): boolean {
    return this.router.globals.current.name.includes(state);
  }

  private updateCurrentModule(state: string): void {
    const matchingModule = this.activeModules.find(module => state.includes(ModuleMetadata[module].state));
    this.currentModule = matchingModule ?? this.activeModules[0] ?? ModuleNames.ECL;
    this.otherModules = this.activeModules.filter(module => module !== this.currentModule);
  }

  setCurrentModule(state: string): void {
    this.router.stateService.go(ModuleMetadata[state].state + '.dashboard');
    // this.currentModule = this.activeModules.find((mod) => ModuleMetadata[mod]. === state) || this.activeModules[0];
  }

  public ngOnDestroy(): void {
    // Do something
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  protected readonly ModuleMetadata = ModuleMetadata;
}

export enum ModuleNames {
  ECL = 'ECL',
  CREW = 'CREW',
  MCC = 'MCC',
  GOC = 'GOC',
  OPS = 'OPS',
  OCC = 'OCC',
}

export const ModuleMetadata: Record<ModuleNames, { name: string; order: number; state: string }> = {
  [ModuleNames.ECL]: { name: 'ecl', order: 3, state: 'app.ecl' },
  [ModuleNames.CREW]: { name: 'crew', order: 1, state: 'app.crew' },
  [ModuleNames.MCC]: { name: 'mcc', order: 5, state: 'app.mcc' },
  [ModuleNames.GOC]: { name: 'goc', order: 6, state: 'app.goc' },
  [ModuleNames.OPS]: { name: 'ops', order: 4, state: 'app.ops' },
  [ModuleNames.OCC]: { name: 'occ', order: 2, state: 'app.occ' },
};
