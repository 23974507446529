<div class="grid-bloc">
  <ng-container>
    <button [disabled]="isReadOnly" (click)="onClick('isInSummary')" [ngClass]="{ toggled: isInSummary }">
      <span class="material-symbols-outlined iconsize">short_text</span>
      <span>{{ moduleConfig.config.translateKey + '.MODALS.ADD_TO_SUMMARY' | translate }}</span>
    </button>
  </ng-container>
  <ng-container>
    <button [disabled]="isReadOnly || !isInSummary" (click)="onClick('isInBriefing')" [ngClass]="{ toggled: isInBriefing }">
      <span class="material-symbols-outlined iconsize">short_text</span>
      <span>{{ moduleConfig.config.translateKey + '.MODALS.ADD_TO_BRIEFING' | translate }}</span>
    </button>
  </ng-container>
</div>
