import { OclHistoryLog } from '../../ocl/models/ocl-history-log.model';
import { EclCrisis } from './ecl-crisis';
import { HolAttachments } from '../../common/models/hol-attachments.model';
import { OclLogbook } from '../../ocl/models/ocl-logbook.model';
import { OclDecision } from '../../ocl/models/ocl-decision.model';
import { OclEvent } from '../../ocl/models/ocl-event.model';
import { OclTask } from '../../ocl/models/ocl-task';
import { HolVacation } from '../../common/models/hol-vacation.model';
import { EclCrisisTask } from './ecl-crisis-task-ref';

export class EclHistoryLog extends OclHistoryLog {
  public crisis: EclCrisis;
  public eclAnnouncement: Parse.Object;
  public eclTask: EclCrisisTask;
  public details?: string[];

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.crisis = new EclCrisis(parseObject.get('crisis'));
    this.eclAnnouncement = parseObject.get('eclAnnouncement');
    this.details = parseObject.get('details') ? parseObject.get('details').split('|') : [];
    this.eclTask = parseObject.get('task') ? new EclCrisisTask(parseObject.get('task')) : null;
  }

  static create(
    comment: string,
    type: 'logbook' | 'decision' | 'event' | 'managers' | 'holTask' | 'flightsStatus' | 'holVacation' | 'task',
    subType?: string,
    attachments?: HolAttachments,
    jsonObject?: any,
    acl?: Parse.ACL,
    object?: Parse.Object,
    details?: string[],
  ): EclHistoryLog {
    const log = new EclHistoryLog();
    log.comment = comment;
    log.type = type;
    log.subType = subType;
    log.attachments = attachments;
    log.jsonObject = jsonObject;
    log.acl = acl;
    if (type === 'logbook') {
      log.logbook = new OclLogbook(object);
    } else if (type === 'decision') {
      log.decision = new OclDecision(object);
    } else if (type === 'event') {
      log.event = new OclEvent(object);
    } else if (type === 'holTask') {
      log.eclTask = new EclCrisisTask(object);
      log.details = details;
    } else if (type === 'holVacation') {
      log.vacation = new HolVacation(object);
    }
    return log;
  }
}
