<div
  (click)="openEditModal($event, event)"
  *ngIf="event"
  class="hol-event-item hol-clickable"
  [class.no-click]="onlyHeader"
  [class.event-used-for-activate-ecl]="eventUseForActivateECL"
  [style.border-style]="event.linkedData ? 'solid' : 'none'"
  [style.border-color]="!event.linkedData ? '' : event.linkedData?.isParent ? event.linkedData?.color : (event.linkedData?.linkedColors)[0]"
  [style.border-width]="!event.linkedData ? '' : event.linkedData?.isParent ? '4px' : '2px'"
  [style.background-color]="
    !event.linkedData ? '' : (event.linkedData?.isParent ? event.linkedData?.color : (event.linkedData?.linkedColors)[0]) + '52'
  "
>
  <button
    [ngClass]="{ selected: linkedUid }"
    class="occ-linked-button"
    (click)="showLinkedItems($event)"
    *ngIf="linkedIds && linkedIds[event.objectId]"
  >
    <img *ngIf="!linkedUid" class="occ-linked-icon" src="/assets/images/link-icon-blue.svg" />
    <img *ngIf="linkedUid" class="occ-linked-icon" src="/assets/images/link-icon-white.svg" />
  </button>
  <div class="left-addon-container">
    <ng-content select=".left-addon"></ng-content>
  </div>

  <div class="event-container">
    <div class="event-item__title">
      <div class="create-infos">
        <app-acl-indicator [object]="{ companies: event.companies, companiesObj: [] }"></app-acl-indicator>
        <div>
          <div>{{ event.createdAt | timeOrDay : isUtc }}</div>
          <div *ngIf="event.createdBy" [matTooltip]="event.createdBy?.fullName">
            <strong>{{ event.createdBy && event.createdBy.monogram() }}&nbsp;</strong>
          </div>
        </div>
        <div>
          <span *ngIf="fromModule" class="status-icons">
            <img class="icon" src="/assets/images/icon_paperplan.svg" />
            <span> {{ fromModule }}</span>
          </span>
          <span *ngIf="toModule" class="status-icons">
            <span> {{ toModule }}</span>
            <img class="icon" src="/assets/images/icon_paperplan.svg" />
          </span>
        </div>
      </div>
      <div class="event-title-container">
        <div class="event-title-header">
          <ng-content select=".event-goc-addon"></ng-content>
          <app-tag-chips [tags]="event.tags"></app-tag-chips>
          <ng-content select=".event-title-header-addon"></ng-content>
          <ng-content select=".event-ecl-addon"></ng-content>
        </div>
        <div class="event-title">
          <strong>{{ event.scenario?.code }}{{ event.order }}</strong>
          <strong> - </strong>
          <strong [title]="event.scenario?.title">{{ event.scenario?.title }}</strong>
        </div>
        <div>
          <strong class="event-description">{{ event.description }}</strong>
        </div>
        <div *ngIf="!onlyHeader">
          <app-file-and-image-icons-display [attachments]="event.attachments"></app-file-and-image-icons-display>
        </div>
      </div>
      <div class="event-title-addon">
        <ng-content select=".title-addon"></ng-content>
      </div>
    </div>
    <div *ngIf="!onlyHeader" class="event-item__infos">
      <!--NOT CLOSED-->
      <div *ngIf="event.closeReason === undefined">
        <div *ngIf="event.getNextInfo()">
          <div>
            <strong *ngIf="event.getNextInfo() && event.getNextInfo().createdBy" class="text-muted">{{
              event.getNextInfo().createdBy.monogram()
            }}</strong
            >&nbsp;{{ event.getNextInfo().message }}
          </div>
          <app-file-and-image-icons-display [attachments]="event.getNextInfo().attachments"></app-file-and-image-icons-display>

          <div class="status-container">
            <div
              *ngIf="event.getNextInfo() !== undefined"
              [class.almost-elapsed]="isAlmostElapsed(event.getNextInfo().nextInfoTime)"
              [class.elapsed]="isElapsed(event.getNextInfo().nextInfoTime)"
              class="text-right text-muted next-info"
            >
              <span>{{ event.getNextInfo().nextInfoTime | nextInfoTime : isUtc }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="!event.getNextInfo()" class="text-right">
          <div class="status-container">
            <strong *ngIf="isNextInfoMandatory" class="hol-error next-info">Missing next info</strong>
          </div>
        </div>
        <div *ngIf="event.fromGOC && event.flight && event.flight.flightNumber" class="item-dasboard-occ-flight-linked">
          <span>{{ event.flight.flightNumber }}/{{ event.flight.std | date : 'ddMMM' }}</span>
        </div>
      </div>

      <!--CLOSED-->
      <div *ngIf="event.closeReason !== undefined">
        <div class="status-container">
          <span class="archive text-right">
            <img class="icon" src="/assets/images/lock.svg" />
            <span *ngIf="event.closeDate" class="text-muted">{{
              'GOC.MODALS.FLIGHT_DETAILS_MODAL.ARCHIVED_DATE'
                | translate : { date: event.closeDate | date : 'dd/MM' : (isUtc ? '' : 'utc') }
            }}</span>
            <span *ngIf="!event.closeDate" class="text-muted">{{ 'GOC.MODALS.FLIGHT_DETAILS_MODAL.CLOSED' | translate }}</span>
          </span>
        </div>
        <div *ngIf="event.fromGOC && event.flight && event.flight.flightNumber" class="item-dasboard-occ-flight-linked">
          <span>{{ event.flight.flightNumber }}/{{ event.flight.std | date : 'ddMMM' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
