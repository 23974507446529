import { OclGlobalInstruction } from './ocl-global-instruction.model';
import { OclGroup } from './ocl-group.model';

export class OclGlobalInstructionGroup<T extends OclGlobalInstruction = OclGlobalInstruction> extends OclGroup<T> {
  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    const itemsParse: T[] =
      parseObject.get('globalInstructions') && parseObject.get('globalInstructions').length
        ? parseObject.get('globalInstructions').map(lg => new OclGlobalInstruction(lg) as T)
        : [];
    this.items = itemsParse.sort((a, b) => {
      const dateA = new Date(a.customCreatedAt || a.createdAt).getTime() || 0;
      const dateB = new Date(b.createdAt).getTime() || 0;
      return dateB - dateA;
    });
  }

  public getMostRecentGlobalInstruction(): T {
    return this.items.length > 0 ? this.items[0] : null;
  }

  protected newItem(parseObject?: Parse.Object): T {
    return new OclGlobalInstruction(parseObject) as T;
  }
}
