<div class="hol-dialog-wrapper confirmation-modal">
  <mat-toolbar>
    <div mat-dialog-title>
      {{ titleTranslateKey | translate }}
    </div>
    <button mat-dialog-close mat-icon-button tabindex="-1">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content>
    <div class="content-grid">
      <div class="section">
        <div></div>
        <div class="content">
          <mat-form-field>
            <input
              #input
              [maxlength]="maxLengthTitle"
              [(ngModel)]="tmpTitle"
              matInput
              placeholder="{{ placeholderTranslateKey | translate }} ({{ fileName }})"
              type="text"
            />
            <mat-hint align="end">{{ input.value.length }}/{{ maxLengthTitle }}</mat-hint>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-bar">
      <button (click)="closeModal()" class="action action-refuse">
        <span class="material-symbols-outlined">cancel</span>
        {{ 'COMMON.CANCEL' | translate | uppercase }}
      </button>
      <button (click)="saveAttachment()" [disabled]="tmpTitle === ''" class="action action-validate">
        <span class="material-symbols-outlined">check</span>
        <span>{{ 'COMMON.EDIT' | translate | uppercase }}</span>
      </button>
    </div>
  </mat-dialog-actions>
</div>
