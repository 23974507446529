import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule, DoBootstrap, Injector } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { downgradeComponent, downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';
import { MatIconRegistry } from '@angular/material/icon';
import { UrlService } from '@uirouter/core';

import * as angular from 'angular';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/browser';
import { NgHybridStateDeclaration, UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { ReferentialService } from './admin/services/referential.service';
import { CommonComponentsModule } from './common/common.components.module';
import { AclFilterComponent } from './common/components/acl-filter/acl-filter.component';
import { AclSelectorComponent } from './common/components/acl-selector/acl-selector.component';
import { ChecklistVacationComponent } from './common/components/checklist-vacation/checklist-vacation.component';

import { CustomTabComponent } from './common/components/custom-tabs/custom-tab.component';
import { CustomTabsComponent } from './common/components/custom-tabs/custom-tabs.component';
import { FileAndImageIconsDisplayComponent } from './common/components/file-and-image-icons-display/file-and-image-icons-display.component';
import { FileAndImageUploadComponent } from './common/components/file-and-image-upload/file-and-image-upload.component';
import { HeaderComponent } from './common/components/header/header.component';
import { HolNextInfoItemComponent } from './common/components/hol-next-info-item/hol-next-info-item.component';
import { LoadingComponent } from './common/components/loading/loading.component';
import { CrisisService } from './common/services/crisis/crisis.service';
import { CurrentUserService } from './common/services/current-user.service';
import { DialogService } from './common/services/dialog/dialog.service';
import { HelperService } from './common/services/helper.service';
import { NotificationsService } from './common/services/notifications/notifications.service';
import { ParseMapperService } from './common/services/parse-mapper.service';
import { PollingService } from './common/services/polling/polling.service';
import { RequestService } from './common/services/request.service';
import { RolesService } from './common/services/roles.service';
import { VacationService } from './common/services/vacation.service';
import { OclGlobalInstructionService } from './ocl/services/ocl-global-instruction-service/ocl-global-instruction.service';
import { OptionsService } from './common/services/options.service';
import { CommonStoreManager } from './common/store/common.store-manager';
import { TirednessIndicatorComponent } from './crew/common/tiredness-indicator/tiredness-indicator.component';
import { CrewEventsBoxComponent } from './crew/components/crew-events-box/crew-events-box.component';
import { CrewModule } from './crew/crew.module';
import { TirednessIndicatorService } from './crew/services/tiredness-indicator.service';
import { CrewManagersSelectors } from './crew/store/crew-managers.selectors';
import { EclEventService } from './ecl/services/ecl-event-service/ecl-event.service';
import { EclOptionsService } from './ecl/services/ecl-options-service/ecl-options.service';
import { ErpCrisisAnnouncementService } from './erp/services/erp-crisis-announcement.service';
import { ErpCrisisApiService } from './erp/services/erp-crisis-api.service';
import { ErpCrisisDataService } from './erp/services/erp-crisis-data.service';
import { ErpCrisisNewsService } from './erp/services/erp-crisis-news.service';
import { ErpCrisisTaskService } from './erp/services/erp-crisis-task.service';
import { ErpCrisisTypeService } from './erp/services/erp-crisis-type.service';
import { ErpCrisisService } from './erp/services/erp-crisis.service';
import { ErpFunctionsService } from './erp/services/erp-functions.service';
import { ErpHistoryService } from './erp/services/erp-history.service';
import { ErpMailService } from './erp/services/erp-mail.service';
import { ErpSmsService } from './erp/services/erp-sms.service';
import { ErpUsersService } from './erp/services/erp-users.service';
import { ModalsService } from './erp/services/modals.service';
import { CrisisStoreManager } from './erp/store/crisis/crisis.store-manager';
import { FunctionsStoreManager } from './erp/store/functions/functions.store-manager';
import { AuthenticationService } from './login/services/authentication.service';
import { MccAtaCodeService } from './mcc/services/mcc-ata-code.service';
import { MccEventService } from './mcc/services/mcc-event.service';
import { OccEventService } from './occ/services/occ-event-service/occ-event.service';
import { OccOptionsService } from './occ/services/occ-options-service/occ-options.service';
import { OclEventItemComponent } from './ocl/components/ocl-event-item/ocl-event-item.component';
import { OclGlobalInstructionBoxComponent } from './ocl/components/ocl-global-instruction-box/ocl-global-instruction-box.component';
import { OpsOptionsService } from './ops/services/ops-options-service/ops-options.service';
import { getReducers, metaReducers, REDUCER_TOKEN } from './store';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HolMsalModule } from './msal/hol-msal.module';
import { getMsalConfig } from './msal/hol-msal-config';
import { MailService } from './common/services/mail/mail.service';
import { SmsService } from './common/services/sms/sms.service';
import { EclFunctionService } from './ecl/services/ecl-function-service/ecl-function-service';
import { EclCrisisService } from './ecl/services/ecl-crisis-service/ecl-crisis.service';
import { EclCrisisStoreManager } from './ecl/store/crisis/crisis.store-manager';
import { WeatherService } from './common/services/weather/weather.service';
import { EclFunctionStoreManager } from './ecl/store/function/function.store-manager';
import { EclCrisisDirectorStoreManager } from './ecl/store/crisis-director/ecl-crisis-director.store-manager';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TagFilterComponent } from './common/components/tag-filter/tag-filter.component';
import { EclAnnoucementService } from './ecl/services/ecl-annoucement.service';
import { HolAnnouncementService } from './common/services/hol-announcements.service';
import { PasswordStrengthComponent } from './admin/components/admin-password/password-strength.component';
import { EclUsersService } from './ecl/services/ecl-users-service/ecl-users.service';
import { HolOptionsService } from './common/services/hol-options.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateBridgeService } from './bridge/translate-bridge.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MenuNavComponent } from './common/components/menu-side-nav/menu-nav.component';

export const gocRootStates: NgHybridStateDeclaration = {
  name: 'app.goc.**',
  url: '/goc',
  loadChildren: () => import('./goc/goc.module').then(m => m.GocModule),
};

export const erpRootStates: NgHybridStateDeclaration = {
  name: 'app.crisis.**',
  url: '/crisis',
  loadChildren: () => import('./erp/erp.module').then(m => m.ERPModule),
};

export const crewRootStates: NgHybridStateDeclaration = {
  name: 'app.crew.**',
  url: '/crew',
  loadChildren: () => import('./crew/crew.module').then(m => m.CrewModule),
};
export const occRootStates: NgHybridStateDeclaration = {
  name: 'app.occ.**',
  url: '/occ',
  loadChildren: () => import('./occ/occ.module').then(m => m.OccModule),
};

export const eclRootStates: NgHybridStateDeclaration = {
  name: 'app.ecl.**',
  url: '/ecl',
  loadChildren: () => import('./ecl/ecl.module').then(m => m.EclModule),
};

export const opsRootStates: NgHybridStateDeclaration = {
  name: 'app.ops.**',
  url: '/ops',
  loadChildren: () => import('./ops/ops.module').then(m => m.OpsModule),
};

export const mccRootStates: NgHybridStateDeclaration = {
  name: 'app.mcc.**',
  url: '/mcc',
  loadChildren: () => import('./mcc/mcc.module').then(m => m.MccModule),
};

export const adminRootStates: NgHybridStateDeclaration = {
  name: 'app.admin.**',
  url: '/admin',
  loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
};

export const loginRootStates: NgHybridStateDeclaration = {
  name: 'login.**',
  url: '/login',
  loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
};

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

const myGestionDeCriseApp = angular.module('gestiondecriseApp');

export const enum USER_RIGHTS {
  READ = 'READ',
  WRITE = 'WRITE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  EXTERNAL_READ = 'EXTERNAL_READ',
  EXTERNAL_WRITE = 'EXTERNAL_WRITE',
}

export function initializeUIRouter(urlService: UrlService) {
  return () => {
    urlService.listen();
    urlService.sync();
  };
}

export function initializeApp(translate: TranslateService, referentialService: ReferentialService): () => Promise<any> {
  return async (): Promise<any> => {
    let config = await getMsalConfig();
    translate.setDefaultLang('fr_FR');
    const langToUse = 'fr_FR';
    return translate.use(langToUse).toPromise();
  };
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/locale-', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    RouterModule,
    UpgradeModule,
    CommonComponentsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr_FR',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    CrewModule,
    HttpClientModule,
    HolMsalModule,
    UIRouterUpgradeModule.forRoot({
      states: [
        loginRootStates,
        gocRootStates,
        adminRootStates,
        crewRootStates,
        erpRootStates,
        occRootStates,
        eclRootStates,
        opsRootStates,
        mccRootStates,
      ],
    }),
    StoreModule.forRoot(REDUCER_TOKEN, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false },
    }),
    StoreDevtoolsModule.instrument({
      name: '[HOLIS]',
      maxAge: 50, // Retains last 25 states
    }),
  ],
  providers: [
    Location,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TranslateService, ReferentialService],
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: REDUCER_TOKEN,
      useFactory: getReducers,
    },
    //{ provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
    {
      provide: 'CrewLogsService',
      useFactory: ($injector: any) => $injector.get('CrewLogsService'),
      deps: ['$injector'],
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: '$mdDialog',
      useFactory: ($injector: any) => $injector.get('$mdDialog'),
      deps: ['$injector'],
    },
    {
      provide: '$event',
      useFactory: ($injector: any) => $injector.get('$event'),
      deps: ['$injector'],
    },
    {
      provide: 'RequestService',
      useFactory: ($injector: any) => $injector.get('RequestService'),
      deps: ['$injector'],
    },
    {
      provide: 'UserService',
      useFactory: ($injector: any) => $injector.get('UserService'),
      deps: ['$injector'],
    },
    {
      provide: '$rootScope',
      useFactory: ($injector: any) => $injector.get('$rootScope'),
      deps: ['$injector'],
    },
    {
      provide: '$q',
      useFactory: ($injector: any) => $injector.get('$q'),
      deps: ['$injector'],
    },
    {
      provide: 'ParseMapper',
      useFactory: ($injector: any) => $injector.get('ParseMapper'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewUsersService',
      useFactory: ($injector: any) => $injector.get('CrewUsersService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewOptionsService',
      useFactory: ($injector: any) => $injector.get('CrewOptionsService'),
      deps: ['$injector'],
    },
    {
      provide: '$translate',
      useFactory: ($injector: any) => $injector.get('$translate'),
      deps: ['$injector'],
    },
    {
      provide: '$timeout',
      useFactory: ($injector: any) => $injector.get('$timeout'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewFunctionsService',
      useFactory: ($injector: any) => $injector.get('CrewFunctionsService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewEventsService',
      useFactory: ($injector: any) => $injector.get('CrewEventsService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewSituationsService',
      useFactory: ($injector: any) => $injector.get('CrewSituationsService'),
      deps: ['$injector'],
    },
    {
      provide: 'HolOptionsService',
      useFactory: ($injector: any) => $injector.get('HolOptionsService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewTeamService',
      useFactory: ($injector: any) => $injector.get('CrewTeamService'),
      deps: ['$injector'],
    },
    {
      provide: 'CrewHistoryService',
      useFactory: ($injector: any) => $injector.get('CrewHistoryService'),
      deps: ['$injector'],
    },
    {
      provide: '$root',
      useFactory: ($injector: any) => $injector.get('$root'),
      deps: ['$injector'],
    },
    {
      provide: 'FilesService',
      useFactory: ($injector: any) => $injector.get('FilesService'),
      deps: ['$injector'],
    },
    {
      provide: 'CONSTANTS',
      useFactory: ($injector: any) => $injector.get('CONSTANTS'),
      deps: ['$injector'],
    },
    {
      provide: 'CommonService',
      useFactory: ($injector: any) => $injector.get('CommonService'),
      deps: ['$injector'],
    },
    {
      provide: 'localStorage',
      useFactory: ($injector: any) => $injector.get('localStorage'),
      deps: ['$injector'],
    },
    {
      provide: 'ChatService',
      useFactory: ($injector: any) => $injector.get('ChatService'),
      deps: ['$injector'],
    },
    {
      provide: 'FunctionService',
      useFactory: ($injector: any) => $injector.get('FunctionService'),
      deps: ['$injector'],
    },
    {
      provide: '$state',
      useFactory: ($injector: any) => $injector.get('$state'),
      deps: ['$injector'],
    },
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule, private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.iconRegistry.addSvgIcon(
      'flight_departure',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/flight_departure.svg'),
    );
    this.iconRegistry.addSvgIcon('flight_arrival', this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/flight_arrival.svg'));
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
  ngDoBootstrap() {
    const angularModule = angular.module('gestiondecriseApp');
    // @ts-ignore
    angularModule.factory('translate', (translateBridge: TranslateBridgeService) => ({
      instant: (key: string, params?: Object) => translateBridge.instant(key, params),
      get: (key: string, params?: Object) => translateBridge.get(key, params),
    }));
    /*
        this.upgrade.bootstrap(document.body, ['gestiondecriseApp'], { strictDi: true });

        */
  }
}

myGestionDeCriseApp.directive('appMenuNav', downgradeComponent({ component: MenuNavComponent }) as angular.IDirectiveFactory);

myGestionDeCriseApp.directive(
  'appOclGlobalInstructionBox',
  downgradeComponent({ component: OclGlobalInstructionBoxComponent }) as angular.IDirectiveFactory,
);
myGestionDeCriseApp.directive('appLoading', downgradeComponent({ component: LoadingComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive(
  'appFileAndImageUpload',
  downgradeComponent({ component: FileAndImageUploadComponent }) as angular.IDirectiveFactory,
);
myGestionDeCriseApp.directive('appCustomTab', downgradeComponent({ component: CustomTabComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appCustomTabs', downgradeComponent({ component: CustomTabsComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive(
  'appChecklistVacation',
  downgradeComponent({ component: ChecklistVacationComponent }) as angular.IDirectiveFactory,
);
myGestionDeCriseApp.directive(
  'appFileAndImageIconsDisplay',
  downgradeComponent({ component: FileAndImageIconsDisplayComponent }) as angular.IDirectiveFactory,
);
myGestionDeCriseApp.directive('appCrewEventsBox', downgradeComponent({ component: CrewEventsBoxComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive(
  'appTirednessIndicator',
  downgradeComponent({ component: TirednessIndicatorComponent }) as angular.IDirectiveFactory,
);
myGestionDeCriseApp.directive(
  'appHolNextInfoItem',
  downgradeComponent({ component: HolNextInfoItemComponent }) as angular.IDirectiveFactory,
);
myGestionDeCriseApp.directive('appHeader', downgradeComponent({ component: HeaderComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appOclEventItem', downgradeComponent({ component: OclEventItemComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appAclSelector', downgradeComponent({ component: AclSelectorComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appAclFilter', downgradeComponent({ component: AclFilterComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive('appTagSelector', downgradeComponent({ component: TagFilterComponent }) as angular.IDirectiveFactory);
myGestionDeCriseApp.directive(
  'appPasswordStrength',
  downgradeComponent({ component: PasswordStrengthComponent }) as angular.IDirectiveFactory,
);

myGestionDeCriseApp.factory('AuthenticationService', downgradeInjectable(AuthenticationService) as any);
myGestionDeCriseApp.factory('ErpCrisisApiService', downgradeInjectable(ErpCrisisApiService) as any);
myGestionDeCriseApp.factory('CommonStoreManager', downgradeInjectable(CommonStoreManager) as any);
myGestionDeCriseApp.factory('DialogService', downgradeInjectable(DialogService) as any);
myGestionDeCriseApp.factory('EclEventService', downgradeInjectable(EclEventService) as any);
myGestionDeCriseApp.factory('ECLOptionsService', downgradeInjectable(EclOptionsService) as any);
myGestionDeCriseApp.factory('ECLCrisisService', downgradeInjectable(EclCrisisService) as any);
myGestionDeCriseApp.factory('HelperService', downgradeInjectable(HelperService) as any);
myGestionDeCriseApp.factory('NotificationsService', downgradeInjectable(NotificationsService) as any);
myGestionDeCriseApp.factory('OccEventService', downgradeInjectable(OccEventService) as any);
myGestionDeCriseApp.factory('OCCOptionsService', downgradeInjectable(OccOptionsService) as any);
myGestionDeCriseApp.factory('OPSOptionsService', downgradeInjectable(OpsOptionsService) as any);
myGestionDeCriseApp.factory('ParseMapper', downgradeInjectable(ParseMapperService) as any);
myGestionDeCriseApp.factory('PollingService', downgradeInjectable(PollingService) as any);
myGestionDeCriseApp.factory('ReferentialServiceNew', downgradeInjectable(ReferentialService) as any);
myGestionDeCriseApp.factory('RequestService', downgradeInjectable(RequestService) as any);
myGestionDeCriseApp.factory('RolesService', downgradeInjectable(RolesService) as any);
myGestionDeCriseApp.factory('TirednessIndicatorService', downgradeInjectable(TirednessIndicatorService) as any);
myGestionDeCriseApp.factory('VacationService', downgradeInjectable(VacationService) as any);
myGestionDeCriseApp.factory('OclGlobalInstructionService', downgradeInjectable(OclGlobalInstructionService) as any);

myGestionDeCriseApp.factory('MCCATACodeService', downgradeInjectable(MccAtaCodeService) as any);
myGestionDeCriseApp.factory('MCCEventsService', downgradeInjectable(MccEventService) as any);
myGestionDeCriseApp.factory('FunctionsStoreManager', downgradeInjectable(FunctionsStoreManager) as any);
myGestionDeCriseApp.factory('ErpFunctionsService', downgradeInjectable(ErpFunctionsService) as any);
myGestionDeCriseApp.factory('CrisisStoreManager', downgradeInjectable(CrisisStoreManager) as any);
myGestionDeCriseApp.factory('OptionsService', downgradeInjectable(OptionsService) as any);
myGestionDeCriseApp.factory('ModalsService', downgradeInjectable(ModalsService) as any);
myGestionDeCriseApp.factory('ErpCrisisTypeService', downgradeInjectable(ErpCrisisTypeService) as any);
myGestionDeCriseApp.factory('ErpCrisisService', downgradeInjectable(ErpCrisisService) as any);
myGestionDeCriseApp.factory('EclUsersService', downgradeInjectable(EclUsersService) as any);
myGestionDeCriseApp.factory('ErpCrisisNewsService', downgradeInjectable(ErpCrisisNewsService) as any);
myGestionDeCriseApp.factory('ErpCrisisAnnouncementService', downgradeInjectable(ErpCrisisAnnouncementService) as any);
myGestionDeCriseApp.factory('ErpCrisisDataService', downgradeInjectable(ErpCrisisDataService) as any);
myGestionDeCriseApp.factory('ErpHistoryService', downgradeInjectable(ErpHistoryService) as any);
myGestionDeCriseApp.factory('CurrentUserService', downgradeInjectable(CurrentUserService) as any);
myGestionDeCriseApp.factory('ErpUsersService', downgradeInjectable(ErpUsersService) as any);
myGestionDeCriseApp.factory('ErpCrisisTaskService', downgradeInjectable(ErpCrisisTaskService) as any);
myGestionDeCriseApp.factory('ErpMailService', downgradeInjectable(ErpMailService) as any);
myGestionDeCriseApp.factory('ErpSmsService', downgradeInjectable(ErpSmsService) as any);
myGestionDeCriseApp.factory('CrisisService', downgradeInjectable(CrisisService) as any);
myGestionDeCriseApp.factory('CrewManagersSelectors', downgradeInjectable(CrewManagersSelectors) as any);
myGestionDeCriseApp.factory('MailService', downgradeInjectable(MailService) as any);
myGestionDeCriseApp.factory('SmsService', downgradeInjectable(SmsService) as any);
myGestionDeCriseApp.factory('EclFunctionService', downgradeInjectable(EclFunctionService) as any);
myGestionDeCriseApp.factory('EclCrisisStoreManager', downgradeInjectable(EclCrisisStoreManager) as any);
myGestionDeCriseApp.factory('EclFunctionStoreManager', downgradeInjectable(EclFunctionStoreManager) as any);
myGestionDeCriseApp.factory('EclCrisisDirectorStoreManager', downgradeInjectable(EclCrisisDirectorStoreManager) as any);
myGestionDeCriseApp.factory('WeatherService', downgradeInjectable(WeatherService) as any);
myGestionDeCriseApp.factory('EclAnnouncementService', downgradeInjectable(EclAnnoucementService) as any);
myGestionDeCriseApp.factory('HolAnnouncementService', downgradeInjectable(HolAnnouncementService) as any);
myGestionDeCriseApp.factory('HolOptionsService', downgradeInjectable(HolOptionsService) as any);
myGestionDeCriseApp.factory('TranslateService', downgradeInjectable(TranslateService) as any);
