import { HolNextInfo } from './hol-next-info.model';
import { HolTag } from './hol-tag';
import { HolScenario } from './hol-scenario';
import { HolObject } from './hol-object';
import { find, orderBy } from 'lodash';
import { HolNotification } from './hol-notification.model';

export class HolEvent extends HolObject {
  description: string;
  order: number;
  scenario: HolScenario;
  closeReason?: number;
  closeReasonText?: string;
  closeDate?: Date;
  channelId?: string;
  tags: HolTag[];
  infos: HolNextInfo[];
  checklistActivated?: boolean;
  isPrivate: boolean;

  constructor(parseObject?: Parse.Object, tags?: HolTag[], infos?: HolNextInfo[]) {
    super(parseObject);
    if (!parseObject) {
      return;
    }
    this.description = this.getContentText(parseObject);
    this.order = parseObject.get('order');
    this.scenario = new HolScenario(parseObject.get('scenario'));
    this.closeReason = parseObject.get('closeReason');
    this.closeReasonText = parseObject.get('closeReasonText');
    this.channelId = parseObject.get('channelId');
    this.checklistActivated = parseObject.get('checklistActivated');

    this.closeDate = parseObject.get('closeDate');

    this.tags = tags;
    this.infos = infos;
  }

  getNextInfo() {
    if (!this.infos) {
      return null;
    }
    return find(orderBy(this.infos, 'nextInfoTime', 'asc'), i => !i.done);
  }

  protected getContentText(parseObject) {
    return parseObject.get('description');
  }

  getMostCriticalNextInfo() {
    if (!this.infos) {
      return null;
    }
    return find(orderBy(this.infos, 'nextInfoTime', 'asc'), i => !i.done);
  }
}

export interface EventInfos {
  info: HolNextInfo;
  notifications?: HolNotification[];
}
