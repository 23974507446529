<div class="hol-dialog-wrapper event-modal hol-modal">
  <ng-container *ngIf="!loading; else loadingTemplate">
    <mat-toolbar>
      <div *ngIf="isCreate" mat-dialog-title>
        <i class="fa fa-plus"></i>
        <span class="title">{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.NEW_TITLE' | translate }}</span>
        <small *ngIf="!isUtc"> {{ moduleConfig.config.translateKey + '.COMMON.TIMES_LT' | translate }}</small>
        <small *ngIf="isUtc"> {{ moduleConfig.config.translateKey + '.COMMON.TIMES_UTC' | translate }}</small>
      </div>
      <div *ngIf="!isCreate" mat-dialog-title>
        <span>{{ initialEvent.scenario?.code }}</span
        ><span>{{ initialEvent.order }}</span> -
        <span>{{ initialEvent.scenario?.title }}</span>
        <small *ngIf="!isUtc"> {{ moduleConfig.config.translateKey + '.COMMON.TIMES_LT' | translate }}</small>
        <small *ngIf="isUtc"> {{ moduleConfig.config.translateKey + '.COMMON.TIMES_UTC' | translate }}</small>
      </div>

      <app-acl-selector
        class="acl-full-width"
        *ngIf="!isReadOnly"
        [readOnly]="isReadOnly"
        [moduleName]="moduleConfig.config.moduleName"
        [limitTo]="limitTo && limitTo.acl"
        [object]="initialEvent"
        [formGroup]="form"
        [startAllSelected]="isCreate"
        [isCompact]="true"
        alwaysOneSelected="true"
      ></app-acl-selector>
      <button mat-dialog-close mat-icon-button tabindex="-1">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar>
    <mat-dialog-content>
      <form [formGroup]="form">
        <div #scrollTargetModalHol class="content-grid">
          <div class="content-grid-container">
            <!-- SCENARIO -->
            <div *ngIf="!initialEvent?.checklistActivated && !isReadOnly && !fromOtherUnivers">
              <app-section-with-title
                [icon]="'question_exchange'"
                [titleKey]="moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.TYPE_LABEL'"
              >
                <mat-form-field class="full-width">
                  <mat-label>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.TYPE_LABEL' | translate }}</mat-label>
                  <mat-select formControlName="scenario" required>
                    <mat-option *ngFor="let scenario of scenarios" [value]="scenario">
                      {{ scenario.code + ' - ' + scenario.title }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </app-section-with-title>
            </div>
            <!-- DESCRIPTION -->
            <div class="section-disable" [class.disabled]="!form.get('scenario').valid">
              <app-section-with-title [icon]="'demography'" [titleKey]="placeHolderDescription">
                <mat-form-field class="full-width description-textarea" *ngIf="!isReadOnly && !fromOtherUnivers">
                  <textarea
                    formControlName="description"
                    maxlength="200"
                    placeholder="{{ placeHolderDescription }}"
                    type="text"
                    mat-autosize
                    required
                    matInput
                  >
                  </textarea>
                </mat-form-field>
                <p class="description" *ngIf="isReadOnly || fromOtherUnivers">
                  {{ this.initialEvent?.description }}
                </p>
              </app-section-with-title>
            </div>
            <!-- INFOS LIST -->
            <div class="section-disable" [class.disabled]="!form.get('scenario').valid">
              <app-section-with-title [titleKey]="moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ADD_INFO'" [icon]="'start'">
                <div class="occ-table next-infos-list top-space" *ngIf="nextInfos.length > 0">
                  <ul class="hol-list">
                    <li
                      *ngFor="let item of nextInfos"
                      class="hol-clickable hol-animate-enter-left"
                      (click)="openDetailsModal($event, item.info)"
                    >
                      <app-hol-next-info-item
                        (markAsDone)="markAsDone(item.info)"
                        [isUtc]="isUtc"
                        [item]="item.info"
                        [canBeDone]="!isCreate && !fromOtherUnivers"
                        [isReadOnly]="isReadOnly"
                      ></app-hol-next-info-item>
                    </li>
                  </ul>
                </div>
                <br />
                <ng-container *ngIf="!isReadOnly">
                  <input type="hidden" formControlName="hasNI" [required]="!nextInfos.length && isNextInfoMandatory" />

                  <div (click)="openAddNextInfoModal()" class="empty-box">
                    <div class="empty-content">
                      <div class="empty-first-line">
                        <span class="material-symbols-outlined iconsize">add_circle_outline</span>
                        <span> {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ADD_INFO' | translate | uppercase }}</span>
                      </div>
                      <div class="empty-second-line hol-error">
                        <span *ngIf="!nextInfos || nextInfos.length == 0">{{
                          moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ADD_INFO_ALERT' | translate
                        }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </app-section-with-title>
            </div>

            <!-- TAG -->
            <div class="section-disable" [class.disabled]="!form.get('scenario').valid">
              <ng-container *ngIf="selectedTags">
                <ng-container [ngTemplateOutlet]="template_TAG"></ng-container>

                <!--
                <app-tag-selector
                  class="tag-selector"
                  *ngIf="!isReadOnly && !fromOtherUnivers"
                  [readOnly]="isReadOnly"
                  [(selectedTags)]="selectedTags"
                ></app-tag-selector>
                <app-tag-chips
                  class="tag-selector"
                  *ngIf="isReadOnly || fromOtherUnivers"
                  isShowName="true"
                  [tags]="selectedTags"
                ></app-tag-chips>
                -->
              </ng-container>
            </div>
            <!-- NOTE -->
            <ng-container *ngTemplateOutlet="noteContent"></ng-container>

            <!-- NOTIFICATIONS

             *ngIf="
                    showCheckboxes &&
                    !isCreate &&
                    !fromOtherUnivers &&
                    initialEvent?.checklistActivated &&
                    moduleConfig.config.activateEventNotification
                  "s

            -->
            <div class="section hol-dialog-section" [class.disabled]="!form.get('scenario').valid" *ngIf="showCheckboxes">
              <app-section-with-title [icon]="'checklist'" [titleKey]="'Checklist'">
                <div class="checkbox" *ngIf="showCheckboxes && !initialEvent?.checklistActivated && !fromOtherUnivers">
                  <label>
                    <mat-checkbox
                      color="primary"
                      formControlName="hasToActivateCheckLists"
                      [disabled]="moduleConfig.config.forceEventChecklistActivation"
                    >
                      <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_CHECKLISTS' | translate }}</strong>
                    </mat-checkbox>
                  </label>
                </div>
                <div class="checkbox hol-primary" *ngIf="showCheckboxes && initialEvent?.checklistActivated">
                  <i class="icon fa fa-check-square-o"></i>&nbsp;{{
                    moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CHECKLISTS_ACTIVATED' | translate
                  }}
                </div>

                <div
                  class="hol-spaced-line"
                  *ngIf="
                    showCheckboxes &&
                    !isCreate &&
                    !fromOtherUnivers &&
                    initialEvent?.checklistActivated &&
                    moduleConfig.config.activateEventNotification
                  "
                >
                  <div class="checkbox" *ngIf="!moduleConfig.config.forceEventNotification">
                    <label>
                      <mat-checkbox color="primary" formControlName="hasToActivateNotification">
                        <strong>{{
                          moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_NOTIFICATION' | translate
                        }}</strong>
                      </mat-checkbox>
                    </label>
                  </div>
                  <div class="checkbox hol-primary" *ngIf="moduleConfig.config.forceEventNotification">
                    <i class="icon fa fa-check-square-o"></i>&nbsp;{{
                      moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_NOTIFICATION' | translate
                    }}
                  </div>
                </div>
                <div class="section hol-dialog-section" *ngIf="isReadOnly">
                  <div class="checkbox hol-primary" *ngIf="initialEvent?.checklistActivated">
                    <i class="icon fa fa-check-square-o"></i>&nbsp;{{
                      moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CHECKLISTS_ACTIVATED' | translate
                    }}
                  </div>
                </div>
              </app-section-with-title>
              <!--CheckList ATTACHMENTS -->

              <app-section-with-title *ngIf="checkListTasks.length > 0" [icon]="''" [titleKey]="''">
                <div class="occ-table next-infos-list">
                  <ul class="hol-list-check-list">
                    <ng-container *ngFor="let checkListTask of checkListTasks">
                      <app-ocl-file-and-image-display-check-list-task *ngIf="checkListTask !== undefined" [data]="checkListTask">
                      </app-ocl-file-and-image-display-check-list-task>
                    </ng-container>
                  </ul>
                </div>
              </app-section-with-title>

              <!--
                          <div class="full-width">
                            <h2> A Supp</h2>
                            <div class="hol-spaced-line">
                              <div class="checkbox"
                                   *ngIf="showCheckboxes && !initialEvent?.checklistActivated && !fromOtherUnivers">
                                <label>
                                  <mat-checkbox
                                    color="primary"
                                    formControlName="hasToActivateCheckLists"
                                    [disabled]="moduleConfig.config.forceEventChecklistActivation"
                                  >
                                    <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_CHECKLISTS' | translate }}</strong>
                                  </mat-checkbox>
                                </label>
                              </div>
                              <div class="checkbox hol-primary" *ngIf="showCheckboxes && initialEvent?.checklistActivated">
                                <i class="icon fa fa-check-square-o"></i>&nbsp;{{
                                  moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.CHECKLISTS_ACTIVATED' | translate
                                }}
                              </div>
                            </div>
                            <div
                              class="hol-spaced-line"
                              *ngIf="
                                showCheckboxes &&
                                !isCreate &&
                                !fromOtherUnivers &&
                                initialEvent?.checklistActivated &&
                                moduleConfig.config.activateEventNotification
                              "
                            >
                              <div class="checkbox" *ngIf="!moduleConfig.config.forceEventNotification">
                                <label>
                                  <mat-checkbox color="primary" formControlName="hasToActivateNotification">
                                    <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_NOTIFICATION' | translate }}</strong>
                                  </mat-checkbox>
                                </label>
                              </div>
                              <div class="checkbox hol-primary" *ngIf="moduleConfig.config.forceEventNotification">
                                <i class="icon fa fa-check-square-o"></i>&nbsp;{{
                                  moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_NOTIFICATION' | translate
                                }}
                              </div>
                            </div>
                          </div>
              -->
            </div>

            <div
              class="has-to-activate-ecl"
              *ngIf="hasEclAccess && moduleConfig.config.canActivateEclModule && !isReadOnly && !fromOtherUnivers"
            >
              <div class="activate-ecl" *ngIf="!eclInfos?.moduleIsActivated">
                <label>
                  <mat-checkbox color="primary" [(ngModel)]="hasToActivateECL">
                    <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_ECL' | translate }}</strong>
                  </mat-checkbox>
                </label>
                <mat-form-field class="activate-ecl-title">
                  <input
                    type="text"
                    maxlength="15"
                    [(ngModel)]="eclName"
                    placeholder="{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ACTIVATE_ECL_PLACEHOLDER' | translate }}"
                    matInput
                  />
                </mat-form-field>
              </div>

              <div
                class="deactivate-ecl"
                *ngIf="eclInfos?.moduleIsActivated && initialEvent.objectId === eclInfos?.occEventId && !isCreate"
              >
                <span class="ecl-activated"
                  ><i class="icon fa fa-check-square-o"></i>&nbsp;{{
                    moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.ECL_ACTIVATED' | translate
                  }}</span
                >
                <label>
                  <mat-checkbox color="primary" [(ngModel)]="hasToDeactivateECL">
                    <strong>{{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.DEACTIVATE_ECL' | translate }}</strong>
                  </mat-checkbox>
                </label>
              </div>
            </div>

            <!-- SECTION -->
            <div class="hol-dialog-section" [class.disabled]="!form.get('scenario').valid" *ngFor="let section of sections">
              <app-section-with-title [icon]="section.button.icon" [titleKey]="'COMMON.MODALS.BUTTONS.' + section.button.name">
                <ng-container [ngTemplateOutlet]="section.template"></ng-container>
              </app-section-with-title>
            </div>

            <!-- FROMGOC -->
            <div *ngIf="initialEvent.fromGOC" [class.disabled]="!form.get('scenario').valid" class="section-disable">
              <app-section-with-title
                [customImgUrl]="'/assets/images/icon_paperplan.svg'"
                [icon]="'send'"
                [titleKey]="moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.FROM_GOC'"
              >
                <mat-checkbox formControlName="fromGOC" [labelPosition]="'after'" color="primary">
                  {{ moduleConfig.config.translateKey + '.DASHBOARD.EVENTS.MODALS.FROM_GOC' | translate }}
                </mat-checkbox>
              </app-section-with-title>
            </div>
          </div>
          <div class="content-grid-button section-disable" [class.disabled]="!form.get('scenario').valid">
            <ng-container *ngIf="actionMode">
              <button (click)="toggleMode()">
                <span class="material-symbols-outlined iconsize"> arrow_back </span>
                <span>{{ 'COMMON.MODALS.BACK' | translate }}</span>
              </button>
              <ng-container *ngTemplateOutlet="template_NOTIFICATION"></ng-container>
            </ng-container>
            <ng-container *ngIf="!actionMode">
              <ng-container *ngFor="let group of groupButton | keyvalue">
                <p *ngIf="!config.isReadOnly">{{ 'COMMON.MODALS.GROUP_BUTTONS.' + group.key | translate }}</p>
                <ng-container *ngFor="let buttonConfig of group.value">
                  <ng-container *ngIf="buttonConfig.type === 'TOGGLE'; then toggleButton; else otherButton"></ng-container>

                  <ng-template #toggleButton>
                    <button
                      (click)="!config.isReadOnly && onButtonClick(buttonConfig)"
                      *ngIf="!config.isReadOnly || (config.isReadOnly && isButtonToggled(buttonConfig))"
                      [ngClass]="{ toggled: isButtonToggled(buttonConfig) }"
                      class="{{ buttonConfig.customClass }}"
                    >
                      <span class="material-symbols-outlined iconsize">
                        {{ buttonConfig.icon }}
                      </span>
                      <span>{{ 'COMMON.MODALS.BUTTONS.' + buttonConfig.name | translate }}</span>
                    </button>
                  </ng-template>
                  <ng-template #otherButton>
                    <button
                      (click)="onButtonClick(buttonConfig)"
                      *ngIf="!config.isReadOnly"
                      [ngClass]="{ activated: isContentDisplay(buttonConfig.name) }"
                      class="{{ buttonConfig.customClass }}"
                    >
                      <span class="material-symbols-outlined iconsize">
                        {{ buttonConfig.icon }}
                      </span>
                      <span
                        >{{ 'COMMON.MODALS.BUTTONS.' + buttonConfig.name | translate }}
                        {{ buttonConfig.name == 'NOTIFICATION' ? '(' + getNotificationActive() + ')' : '' }}</span
                      >
                    </button>
                  </ng-template>
                </ng-container>
                <br />
              </ng-container>
            </ng-container>
          </div>
        </div>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions>
      <div class="action-bar">
        <button
          *ngIf="!isReadOnly && !isCreate && !fromOtherUnivers && initialEvent.closeReason === undefined"
          (click)="archive()"
          class="action action-validate"
        >
          <i class="icon fa fa-archive"></i>
          {{ 'COMMON.BUTTON.ARCHIVE' | translate }}
        </button>
        <div class="spacer"></div>
        <button *ngIf="isReadOnly && (!event || (event && !event.fromGOC))" class="action action-validate" mat-dialog-close>
          <mat-icon>done</mat-icon>
          <span>{{ 'COMMON.BUTTON.CLOSE' | translate | uppercase }}</span>
        </button>
        <button
          (click)="validate()"
          *ngIf="!isReadOnly || (isReadOnly && event && event.fromGOC)"
          [disabled]="form.invalid || !isNoteSaved"
          class="action action-validate"
        >
          <mat-icon>done</mat-icon>
          <span *ngIf="isCreate">{{ 'GOC.EVENT_MODAL.ADD' | translate | uppercase }}</span>
          <span *ngIf="!isCreate">{{ 'COMMON.BUTTON.OK' | translate | uppercase }}</span>
        </button>
      </div>
    </mat-dialog-actions>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="hol-modal-loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>

<ng-template #template_TAG>
  <app-section-with-title [icon]="'shoppingmode'" [titleKey]="'COMMON.MODALS.BUTTONS.TAG'">
    <app-tag-filter
      [(selectedTags)]="selectedTags"
      [canAddTag]="true"
      [isCabin]="true"
      [isFilterMode]="false"
      [readOnly]="isReadOnly || fromOtherUnivers"
      [showTitle]="false"
      [viewOnly]="config.isReadOnly"
    >
    </app-tag-filter>
  </app-section-with-title>
</ng-template>

<ng-template #template_NOTIFICATION>
  <app-notification-table
    [notifications]="notifications"
    [acl]="initialEvent?.acl"
    (saveNotifications)="saveNotifications($event)"
  ></app-notification-table>
</ng-template>

<ng-template #template_GROUP_LINK>
  <!--
  <div class="section-disable" [class.disabled]="!form.get('scenario').valid">
    <app-section-with-title [icon]="'link'"
                            [titleKey]="moduleConfig.config.translateKey + '.DASHBOARD.LINKED_ITEMS.GROUP_LABEL'">
      <app-group-selector
        class="full-width"
        [type]="'event'"
        [isReadOnly]="isReadOnly"
        [listGroup]="eventGroup"
        (groupChange)="updateLinkedGroup($event)"
      ></app-group-selector>
    </app-section-with-title>
  </div>
  -->
  <app-group-selector
    class="full-width"
    [type]="'event'"
    [isReadOnly]="isReadOnly"
    [listGroup]="eventGroup"
    (groupChange)="updateLinkedGroup($event)"
  ></app-group-selector>
</ng-template>

<ng-template #NOT_IMPLEMENTED>
  <div>Work in progress</div>
</ng-template>

<ng-template #template_ATTACHMENTS>
  <app-file-and-image-upload
    [attachments]="attachments"
    [disabled]="isReadOnly || fromOtherUnivers"
    [multipleFileEnabled]="moduleConfig.config.multipleFileEnabled"
    [context]="{
      module: moduleConfig.config.translateKey,
      category: 'EVENT',
      htmlTitle: form.value.description,
      htmlDate: initialEvent.createdAt,
      htmlScenario: getScenario(),
      htmlTemplate: 'B'
    }"
  ></app-file-and-image-upload>
</ng-template>

<ng-template #noteContent>
  <div *ngIf="!isReadOnly || (isReadOnly && attachments.note)" class="section section-title">
    <div class="icon">
      <span class="material-symbols-outlined iconsize">demography</span>
    </div>
    <div class="title">Note</div>
    <div class="empty">&nbsp;</div>
    <div class="content">
      <app-note-markdown
        (isNoteSave)="isNoteSave($event)"
        [attachment]="attachments.note"
        [attachments]="attachments"
        [context]="context"
        [noteFile]="attachments.noteFile"
        [readOnly]="isReadOnly"
      ></app-note-markdown>
    </div>
  </div>
</ng-template>
