import { Pipe, PipeTransform } from '@angular/core';
import { EclCrisisTask } from '../models/ecl-crisis-task-ref';
import { EclFunction, EclFunctionTag } from '../models/ecl-function';

@Pipe({
  name: 'eclTaskCategoryTitle',
  standalone: true,
})
export class EclTaskCategoryTitlePipe implements PipeTransform {
  public transform(
    eclCrisisTask: EclCrisisTask | Partial<EclCrisisTask>,
    functionsCategory: EclFunctionTag[] = [],
    functionsCrisis: EclFunction[] = [],
  ): string {
    let title = '';
    if (
      functionsCategory &&
      functionsCategory.length &&
      functionsCrisis &&
      functionsCrisis.length &&
      eclCrisisTask &&
      eclCrisisTask.functionId
    ) {
      let fun = functionsCrisis.find(d => d.functionId === eclCrisisTask.functionId);
      const cat = functionsCategory.find(c => c.tagId === fun.tagId);
      return cat.title;
    }
    return eclCrisisTask.code;
  }
}
