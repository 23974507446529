import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipse',
  standalone: true,
})
export class EllipsePipe implements PipeTransform {
  transform(value: string, limit: number = 90): string {
    if (!value) {
      return '';
    }

    return value.length > limit ? value.substring(0, limit) + '...' : value;
  }
}
