<div class="hol-box on-duty-manager-box">
  <div class="hol-box__header">
    <div class="header-title-container title-box-occ">
      <div *ngIf="isClosable">
        <button (click)="onClose.emit()" class="btn-close">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <span class="word-break">{{ managersTitle }} </span>
      <small class="hol-box__subtitle" *ngIf="isReadOnly">{{ moduleConfig.config.translateKey + '.COMMON.TIMES_LT' | translate }}</small>
      <small class="hol-box__subtitle" *ngIf="!isReadOnly">{{ moduleConfig.config.translateKey + '.COMMON.TIMES_UTC' | translate }}</small>
    </div>
  </div>

  <div class="hol-box__content">
    <div class="iscroll-wrapper" iscroll>
      <div class="iscroll-scroller">
        <ul class="hol-list">
          <li
            *ngFor="let item of managerFunctions"
            class="hol-clickable manager-item"
            [class.manager-function]="item.isManagerFunction"
            (click)="openManagerModal($event, item)"
          >
            <i class="icon icon-icon-people"></i>
            <div class="content">
              <div class="manager-title">
                <span>{{ item.title }}</span>
                <a (click)="$event.stopPropagation()" href="mailto:{{ item.email }}" target="_blank" *ngIf="item.email">
                  <mat-icon>mail</mat-icon>
                </a>
                <a (click)="$event.stopPropagation()" href="tel:{{ item.phone }}" target="_blank" *ngIf="item.phone">
                  <mat-icon>phone</mat-icon>
                  <span> {{ item.phone }}</span>
                </a>
              </div>
              <div *ngFor="let company of item.companies" class="manager-company">
                <app-acl-indicator [object]="{ companies: [company.name], companiesObj: [] }"></app-acl-indicator>
                <div *ngIf="company.holder" class="holder">
                  <span class="manager-name">{{ company.holder.user.fullName }} </span>
                  <a
                    (click)="$event.stopPropagation()"
                    href="mailto:{{ company.holder.user.email }}"
                    target="_blank"
                    *ngIf="company.holder.user.email"
                  >
                    <mat-icon>mail</mat-icon>
                  </a>
                  <a
                    (click)="$event.stopPropagation()"
                    href="tel:{{ company.holder.user.phone }}"
                    target="_blank"
                    *ngIf="company.holder.user.phone"
                  >
                    <mat-icon>phone</mat-icon>
                    <span> {{ company.holder.user.phone }}</span>
                  </a>

                  <div
                    *ngIf="!company.holder.expiredAt"
                    class="duty-status"
                    [class.hol-error]="isElapsed(item.minutesBeforeAlert, company.holder.updatedAt)"
                  >
                    <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.MODALS.SINCE_DATE' | translate }}</span>
                    <span>{{ company.holder.updatedAt | time : !isReadOnly }}</span>
                    <span *ngIf="isOnDutyForLessThan24Hours(company.holder.updatedAt)">
                      ({{ holderSince(company.holder.updatedAt) }})
                    </span>
                  </div>

                  <div
                    *ngIf="company.holder.expiredAt && isWithinADay(company.holder)"
                    class="duty-status"
                    [class.hol-warning]="isBetween(15, company.holder.expiredAt)"
                    [class.hol-error]="isElapsed(0, company.holder.expiredAt)"
                  >
                    <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.MODALS.EXPIRATION_DATE' | translate }}</span>
                    {{ company.holder.expiredAt | date : (!isReadOnly ? "HH:mm'Z'" : 'HH:mm') : (!isReadOnly ? 'UTC' : null) }}
                  </div>
                  <div
                    *ngIf="company.holder.expiredAt && !isWithinADay(company.holder)"
                    class="duty-status"
                    [class.hol-warning]="isOnSameDay(company.holder.expiredAt)"
                    [class.hol-error]="isElapsed(0, company.holder.expiredAt)"
                  >
                    <span>{{ moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.MODALS.EXPIRATION_DATE' | translate }}</span>
                    {{ company.holder.expiredAt | date : 'EEE dd/MM' : (!isReadOnly ? 'UTC' : null) }}
                  </div>
                </div>
                <div *ngIf="!company.holder" class="hol-error">
                  {{ moduleConfig.config.translateKey + '.DASHBOARD.MANAGERS.NOBODY' | translate }}
                </div>
              </div>
            </div>
          </li>
          <li *ngIf="!managerFunctions?.length">EMPTY</li>
        </ul>
      </div>
    </div>
  </div>
</div>
