// noinspection ES6UnusedImports

import { EclCrisisState } from '../../ecl.model';
import { EclCrisisActions, EclCrisisActionTypes, UpdateManyEclCrisis } from './crisis.action';
import { EclCrisis, EclCrisisType } from '../../models/ecl-crisis';
import { EclSummary } from '../../models/ecl-summary';
import { EclUserFunction } from '../../models/ecl-function';
import { CrisisActionTypes } from '../../../erp/store/crisis/crisis.actions';
import { cloneDeep } from 'lodash';

const crisisInitialState: Readonly<EclCrisisState> = {
  crisis: [],
  crisisTypes: [],
  selectedCrisis: undefined,
  announcements: [],
  summaries: [],
  userFunction: [],
  tasks: [],
};

export function eclCrisisReducer(state: EclCrisisState = crisisInitialState, action: EclCrisisActions): EclCrisisState {
  switch (action.type) {
    case EclCrisisActionTypes.INIT_ECL_CRISIS:
      // eslint-disable-next-line no-case-declarations
      const { crisisList, crisisTypeList, allCurrentUserFunction, crisisIdSaved } = action.payload;
      const crisisCombined = combineCrisisAndTypes(crisisList, crisisTypeList);
      return {
        ...state,
        crisis: crisisCombined,
        crisisTypes: crisisTypeList,
        //  selectedCrisis: state.selectedCrisis || currentOpenedCrisis(crisisCombined, allCurrentUserFunction, crisisIdSaved),
        selectedCrisis: state.selectedCrisis || null,
        announcements: [],
        summaries: [],
        userFunction: [],
      };
    case EclCrisisActionTypes.SELECT_ECL_CURRENT_CRISIS:
      return {
        ...state,
        selectedCrisis: action.payload,
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_LIST_FROM_POLLING:
      return {
        ...state,
        crisis: action.payload,
      };
    case EclCrisisActionTypes.CREATE_ECL_CRISIS:
      return {
        ...state,
        crisis: combineCrisisAndTypes([action.payload, ...state.crisis], state.crisisTypes),
        selectedCrisis: action.payload,
      };
    case EclCrisisActionTypes.CLOSE_ECL_CRISIS:
      const updatedCrisis = state.crisis.map(c => (c.objectId === action.payload.objectId ? action.payload : c));

      return {
        ...state,
        crisis: updatedCrisis,
        selectedCrisis: action.payload,
      };
    case EclCrisisActionTypes.CREATE_ONE_ECL_CRISIS_ANNOUNCEMENT:
      if (action.payload.objectId && !state.announcements.some(el => el.objectId === action.payload.objectId)) {
        return {
          ...state,
          announcements: [action.payload, ...state.announcements],
        };
      }
      return state;
    case EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS_ANNOUNCEMENT:
      return {
        ...state,
        announcements: state.announcements.map(el => (el.objectId === action.payload.objectId ? { ...el, ...action.payload } : el)),
      };

    case EclCrisisActionTypes.INIT_ECL_CRISIS_ANNOUNCEMENT_LIST:
      return {
        ...state,
        announcements: action.payload,
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_ANNOUNCEMENT_LIST_FROM_POLLING:
      return {
        ...state,
        announcements: action.payload,
      };
    case EclCrisisActionTypes.CREATE_ONE_ECL_CRISIS_SUMMARY:
      if (action.payload.objectId && !state.summaries.some(el => el.objectId === action.payload.objectId)) {
        return {
          ...state,
          summaries: [...state.summaries, action.payload],
        };
      }
      return state;

    case EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS_SUMMARY:
      return {
        ...state,
        summaries: state.summaries.map(s => (s.objectId === action.payload.objectId ? action.payload : s)),
      };

    case EclCrisisActionTypes.UPDATE_MANY_ECL_CRISIS_SUMMARY:
      const updatedSummaries = state.summaries.map(s => {
        const matchingSummary = action.payload.find(summary => summary.objectId === s.objectId);
        return matchingSummary || s;
      });
      const newSummaries = action.payload.filter(summary => !state.summaries.some(s => s.objectId === summary.objectId));

      return {
        ...state,
        summaries: [...updatedSummaries, ...newSummaries],
      };

    case EclCrisisActionTypes.DELETE_ONE_ECL_CRISIS_SUMMARY:
      return {
        ...state,
        summaries: state.summaries.filter(el => el.objectId !== action.payload.objectId),
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_SUMMARY_LIST:
      return {
        ...state,
        summaries: action.payload,
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_SUMMARY_LIST_FROM_POLLING:
      return {
        ...state,
        summaries: action.payload,
      };
    case EclCrisisActionTypes.INIT_ECL_CRISIS_USERFUNCTION_LIST:
      return {
        ...state,
        userFunction: action.payload,
      };

    case EclCrisisActionTypes.UPDATE_ONE_ECL_CRISIS:
      return {
        ...state,
        crisis: state.crisis.map(el => (el.objectId === action.payload.objectId ? { ...el, ...action.payload } : el)),
        selectedCrisis: action.payload,
      };

    case EclCrisisActionTypes.UPDATE_MANY_ECL_CRISIS:
      const updatedManyCrisis = state.crisis.map(c => {
        const matchingCrisis = action.payload.find(crisis => crisis.objectId === c.objectId);
        return matchingCrisis || c;
      });
      const newCrisis = action.payload.filter(crisis => !state.crisis.some(c => c.objectId === crisis.objectId));

      const selectedIndex = updatedManyCrisis.findIndex(c => c.objectId === state.selectedCrisis?.objectId);

      return {
        ...state,
        crisis: [...updatedManyCrisis, ...newCrisis],
        selectedCrisis: selectedIndex !== -1 ? updatedManyCrisis[selectedIndex] : state.selectedCrisis,
      };

    case EclCrisisActionTypes.UPDATE_ONE_CRISIS_TASK:
      return {
        ...state,
        tasks: [
          ...state.tasks.map(el => {
            if (el.objectId === action.crisisTask.objectId) {
              return action.crisisTask;
            }
            return el;
          }),
        ],
      };
    case EclCrisisActionTypes.UPDATE_MANY_CRISIS_TASK:
      const updatedManyTasks = state.tasks.map(c => {
        const matchingCrisis = action.crisisTasks.find(task => task.objectId === c.objectId);
        return matchingCrisis || c;
      });
      const newTask = action.crisisTasks.filter(task => !state.tasks.some(c => c.objectId === task.objectId));

      return {
        ...state,
        tasks: [...updatedManyTasks, ...newTask],
      };

    case EclCrisisActionTypes.CREATE_ONE_CRISIS_TASK:
      if (action.crisisTask.objectId && state.tasks.findIndex(el => el.objectId === action.crisisTask.objectId) === -1) {
        return {
          ...state,
          tasks: [action.crisisTask, ...state.tasks],
        };
      } else {
        return state;
      }
    case EclCrisisActionTypes.CREATE_MANY_CRISIS_TASKS:
      return {
        ...state,
        tasks: state.tasks.concat(action.crisisDataArray),
      };
    case EclCrisisActionTypes.REMOVE_ONE_CRISIS_TASK:
      if (action.crisisTaskObjectId && state.tasks.findIndex(el => el.objectId === action.crisisTaskObjectId) !== -1) {
        return {
          ...state,
          tasks: state.tasks.filter(el => el.objectId !== action.crisisTaskObjectId),
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

function combineCrisisAndTypes(crisisList: EclCrisis[], types: EclCrisisType[]): EclCrisis[] {
  return crisisList
    .filter(crisis => types.some(t => t.crisisTypeId === crisis.crisisTypeId)) // Filtrer les crises sans type valide
    .map(crisis => {
      const matchedType = types.find(t => t.crisisTypeId === crisis.crisisTypeId);
      return {
        ...crisis,
        type: matchedType,
      };
    });
}

function currentOpenedCrisis(crisisList: EclCrisis[], currentUserFunction: EclUserFunction[], crisisIdSaved: string): EclCrisis {
  if (crisisIdSaved) {
    const crisisFinded = crisisList.find(c => c.objectId == crisisIdSaved);
    if (crisisFinded) return crisisFinded;
  }
  return (
    crisisList.find(c => (c.isInPreparation || c.inProgress) && currentUserFunction.some(ucf => ucf.crisis.objectId === c.objectId)) ||
    crisisList[0]
  );
}
