import { Component, inject, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OclEventGroup } from '../../models/ocl-event-group.model';
import { OclLogBookGroup } from '../../models/ocl-logbook-group.model';
import { OclDecisionGroup } from '../../models/ocl-decision-group.model';
import { OclGroupService, OclGroupType } from '../../services/ocl-group-service/ocl-group.service';
import { ModuleConfigService } from '../../../common/services/module-config/module-config.service';
import { OclGlobalInstructionGroup } from '../../models/ocl-global-instruction-group.model';
import { find, intersectionBy, reduce } from 'lodash';
import { OclGroup } from '../../models/ocl-group.model';
import { EclSummaryService } from '../../../ecl/services/ecl-summary-service/ecl-summary-service';
import { EclCrisisStoreManager } from '../../../ecl/store/crisis/crisis.store-manager';
import { take } from 'rxjs/operators';
import { RolesService } from '../../../common/services/roles.service';
import { PollingService } from '../../../common/services/polling/polling.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../../../common/modals/confirmation-modal/confirmation-modal.component';
import moment from 'moment';
import { EclDecisionGroup } from '../../../ecl/models/ecl-decision-group.model';
import { EclLogbookGroup } from '../../../ecl/models/ecl-logbook-group.model';
import { HolTag } from 'src/app/common/models/hol-tag';
import { performSearch } from '../../../common/utils/search-utils';
import { HolObject } from '../../../common/models/hol-object';
import { EclFunction } from '../../../ecl/models/ecl-function';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { HolUTCDisplay, utcEval } from '../../../common/enums/hol-utc-display.enum';

@Component({
  selector: 'app-ocl-group-modal',
  templateUrl: './ocl-group-modal.component.html',
  styleUrls: ['./ocl-group-modal.component.scss'],
})
export class OclGroupModalComponent implements OnDestroy {
  public infoMsgTitle = '';
  public filteredTitleList: any[] = [];
  public isCreate: boolean;
  public isReadOnly: boolean;
  public modalTitle: string;
  public group?: any;
  public groupsFromApi?: any[];
  public loading = false;
  public form: UntypedFormGroup;
  public items: HolObject[];
  public displayItems: any[];
  public isItemsRequired: boolean;
  public isInSummary = false;
  public filterSelectedItemActive = false;
  public filterPinnedItemActive = false;
  public filterNonCheckedItemActive = false;
  public isInBriefing = false;
  public isSummaryActivated = false;
  type: OclGroupType;
  i18nkey: string;
  public addButtonTrad = 'DASHBOARD.MODAL.ADD_BUTTON';
  public updateButtonTrad = 'DASHBOARD.MODAL.UPDATE_BUTTON';
  protected readonly RolesService = RolesService;
  private selectedFunction?: EclFunction = null;
  protected translate = inject(TranslateService);

  public searchCriteria = '';
  public selectedTags: HolTag[] = [];
  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;
  public contentTextLength = 64;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('CONSTANTS') protected CONSTANTS: any,
    private fb: UntypedFormBuilder,
    private groupService: OclGroupService,
    public dialogRef: MatDialogRef<OclGroupModalComponent>,
    private readonly matDialog: MatDialog,
    public moduleConfig: ModuleConfigService,
    public eclSummaryService: EclSummaryService,
    public eclCrisisStoreManager: EclCrisisStoreManager,
    public pollingService: PollingService,
  ) {
    this.pollingService.stopIt();
    this.isCreate = !data.group;
    this.isReadOnly = data.isReadOnly;

    this.group = data.group;
    this.type = data.type == 'GLOBALINSTRUCTION' ? 'GLOBAL_INSTRUCTION' : data.type;
    this.groupsFromApi = data.groupsFromApi;
    this.filteredTitleList = [];
    this.items = data.events || data.logBookItems || data.decisionItems || data.globalInstructionItems;
    this.isItemsRequired = !!data.isItemsRequired;
    this.initForm();
    this.isSummaryActivated = data.isSummaryActivated;

    switch (this.type) {
      case 'EVENT':
        this.i18nkey = 'EVENTS';
        break;
      case 'LOGBOOK':
        this.i18nkey = 'LOGBOOK';
        break;
      case 'DECISION':
        this.i18nkey = 'STATUS_DECISIONS';
        break;
      case 'GLOBAL_INSTRUCTION':
        this.i18nkey = 'GLOBAL_INSTRUCTION';
        break;
      default:
        this.i18nkey = 'GLOBAL_INSTRUCTION';
        break;
    }

    this.modalTitle = this.isCreate
      ? this.translate.instant(moduleConfig.config.translateKey + '.DASHBOARD.' + this.i18nkey + '.MODALS.GROUP_MODAL.CREATE_TITLE')
      : this.translate.instant(moduleConfig.config.translateKey + '.DASHBOARD.' + this.i18nkey + '.MODALS.GROUP_MODAL.UPDATE_TITLE');
    this.form.controls['titleForm'].valueChanges.subscribe(value => {
      if (value.length == 0) {
        this.filteredTitleList = [];
        this.infoMsgTitle = '';
      } else {
        this.filteredTitleList = this.groupsFromApi
          .map(obj => obj.title)
          .filter(option => option && option.toLowerCase().includes(value.toLowerCase()));

        if (this.groupsFromApi.find(item => item.title === value)) {
          this.infoMsgTitle = 'Ce titre de groupe existait déja';
        } else {
          this.infoMsgTitle = '';
        }
      }
    });

    if (this.isSummaryActivated && !this.isCreate) {
      this.isInSummary = !!this.group.summary;
      if (this.isInSummary) {
        this.isInBriefing = (this.group as OclGroup<any>).summary.isDisplayInBriefing;
      }
    }

    this.filterItems();
  }

  ngOnDestroy(): void {
    this.pollingService.startLongPooling();
  }

  get titleForm() {
    return this.form.get('titleForm') as UntypedFormControl;
  }

  get anyItemSelected() {
    return this.items.some(item => this.isInGroup(item.objectId));
  }

  public initForm() {
    const controls = this.items.map(item => {
      const isChecked = this.group ? !!find(this.group.items, el => el.objectId === item.objectId) : false;
      let isInSummary = false;

      if (this.group) {
        if ('logbooksInSummary' in this.group) {
          isInSummary = !!find((this.group as EclLogbookGroup).logbooksInSummary, el => el.objectId === item.objectId);
        } else if ('decisionsInSummary' in this.group) {
          isInSummary = !!find((this.group as EclDecisionGroup).decisionsInSummary, el => el.objectId === item.objectId);
        }
      }

      return this.fb.group({
        objectId: [item.objectId], // ID pour identifier chaque item
        inGroup: [isChecked],
        inSummaryGroup: [isInSummary],
      });
    });

    this.form = this.fb.group({
      titleForm: ['', Validators.required],
      items: this.fb.array(controls), // FormArray pour les items
    });
    if (!this.isCreate) {
      this.titleForm.setValue(this.group.title);
    }
  }

  get itemsArray(): FormArray {
    return this.form.get('items') as FormArray;
  }

  getFormGroupById(objectId: string): UntypedFormGroup {
    const index = this.items.findIndex(item => item.objectId === objectId);
    return this.itemsArray.at(index) as UntypedFormGroup;
  }

  isInGroup(objectId: string): UntypedFormGroup {
    const index = this.items.findIndex(item => item.objectId === objectId);
    return (this.itemsArray.at(index) as UntypedFormGroup).get('inGroup').value;
  }
  isInSummaryGroup(objectId: string): UntypedFormGroup {
    const index = this.items.findIndex(item => item.objectId === objectId);
    return (this.itemsArray.at(index) as UntypedFormGroup).get('inSummaryGroup').value;
  }

  isDisableAddButton() {
    if (this.titleForm.value) {
      const listGroupTitleLowerCase = this.groupsFromApi.map(obj => (obj.title ? obj.title.trim().toLowerCase() : undefined));
      if (this.isCreate) {
        return listGroupTitleLowerCase.includes(this.titleForm.value.trim().toLowerCase());
      } else {
        return listGroupTitleLowerCase
          .filter(item => (item ? item !== this.group.title.trim().toLowerCase() : undefined))
          .includes(this.titleForm.value.trim().toLowerCase());
      }
    } else {
      return this.form.invalid;
    }
  }

  public onCreateGroup(): void {
    this.loading = true;

    let group: OclGroup<any>;

    switch (this.type) {
      case 'EVENT':
        group = new OclEventGroup();
        break;
      case 'LOGBOOK':
        group = new OclLogBookGroup();
        break;
      case 'DECISION':
        group = new OclDecisionGroup();
        break;
      case 'GLOBAL_INSTRUCTION':
        group = new OclGlobalInstructionGroup();
        break;
    }
    group.title = this.titleForm.value;

    group.items = this.items.filter(item => this.isInGroup(item.objectId));

    if (this.isSummaryActivated) {
      const itemsToAddInSummary = group.items.filter(item => this.isInSummaryGroup(item.objectId));

      if (this.moduleConfig.config.moduleName == 'ecl' && this.type === 'LOGBOOK') {
        (group as EclLogbookGroup).logbooksInSummary = itemsToAddInSummary;
      } else if (this.moduleConfig.config.moduleName == 'ecl' && this.type === 'DECISION') {
        (group as EclDecisionGroup).decisionsInSummary = itemsToAddInSummary;
      }
    }

    this.groupService
      .save(group, this.type)
      .then(groupSaved => {
        groupSaved.summary = group.summary;
        if (this.isInSummary) {
          this.eclCrisisStoreManager.$eclSelectedCrisis
            .pipe(take(1))
            .toPromise()
            .then(selectedCrisis => {
              this.eclSummaryService
                .saveNewSummaryFromLogbookOrDecision(
                  groupSaved,
                  groupSaved instanceof OclLogBookGroup ? 'GROUP_LOGBOOK' : 'GROUP_DECISION',
                  selectedCrisis,
                  this.isInBriefing,
                  this.isInSummary,
                )
                .then();
            });
        }

        this.dialogRef.close(groupSaved);
      })
      .catch(error => console.log(error))
      .finally(() => {
        this.loading = false;
      });
  }

  public onUpdateGroup(): void {
    this.loading = true;
    this.group.title = this.titleForm.value;
    this.group.items = this.items.filter(item => this.isInGroup(item.objectId));

    const itemsToAddInSummary = this.group.items.filter(item => this.isInSummaryGroup(item.objectId));
    if ('logbooksInSummary' in this.group) {
      (this.group as EclLogbookGroup).logbooksInSummary = itemsToAddInSummary;
    } else if ('decisionsInSummary' in this.group) {
      (this.group as EclDecisionGroup).decisionsInSummary = itemsToAddInSummary;
    }
    this.groupService
      .save(this.group, this.type)
      .then(groupSaved => {
        if (this.isSummaryActivated) {
          groupSaved.summary = this.group.summary;
          this.eclCrisisStoreManager.$eclSelectedCrisis
            .pipe(take(1))
            .toPromise()
            .then(selectedCrisis => {
              this.eclSummaryService
                .saveNewSummaryFromLogbookOrDecision(
                  groupSaved,
                  groupSaved instanceof OclLogBookGroup ? 'GROUP_LOGBOOK' : 'GROUP_DECISION',
                  selectedCrisis,
                  this.isInBriefing,
                  this.isInSummary,
                )
                .then();
            });
        }
        this.dialogRef.close(groupSaved);
      })
      .catch(error => console.log(error))
      .finally(() => (this.loading = false));
  }

  public onDeleteGroup(): void {
    const dialogRef: MatDialogRef<ConfirmationModalComponent> = this.matDialog.open(ConfirmationModalComponent, {
      data: {
        modalTitle: this.translate.instant('COMMON.MODALS.CONFIRMATION.DELETE_GROUP_TITLE'),
        modalQuestion: this.translate.instant('COMMON.MODALS.CONFIRMATION.QUESTION'),
      },
    });
    dialogRef.afterClosed().subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          if (this.group.summary) {
            this.eclSummaryService.deleteSummary(this.group.summary).then();
          }

          this.groupService
            .delete(this.group, this.type)
            .then(() => {
              const date = moment().subtract(1, 'hours').toDate();
              switch (this.type) {
                case 'EVENT':
                  this.groupService.fetchNewDataEventGroup().then();
                  break;
                case 'LOGBOOK':
                  this.groupService.fetchNewDataLogBookGroup(date).then();
                  break;
                case 'DECISION':
                  this.groupService.fetchNewDataDecisionGroup(date).then();
                  break;
                case 'GLOBAL_INSTRUCTION':
                  this.groupService.fetchNewDataGlobalInstructionGroup().then();
                  break;
                default:
                  this.groupService.fetchNewDataGlobalInstructionGroup().then();
                  break;
              }
              this.dialogRef.close();
            })
            .catch(error => console.log(error))
            .finally(() => (this.loading = false));
        }
      },
    });
  }

  public getAlreadyInGroups(item): any[] {
    return reduce(
      this.groupsFromApi,
      (groups, next) => {
        if (find(next.items, e => e.objectId === item.objectId) && !find(groups, group => group.objectId === next.objectId)) {
          groups.push(next);
        }
        return groups;
      },
      [],
    );
  }

  selectGroup(groupTitleSelected: any) {
    if (this.groupsFromApi.find(item => item.title === groupTitleSelected)) {
      this.group = this.groupsFromApi.find(item => item.title === groupTitleSelected);
    }
    this.isCreate = false;
    this.initForm();
    this.modalTitle = this.translate.instant(
      this.moduleConfig.config.translateKey + '.DASHBOARD.' + this.i18nkey + '.MODALS.GROUP_MODAL.UPDATE_TITLE',
    );
  }

  filterSelectedItemChange() {
    this.filterSelectedItemActive = !this.filterSelectedItemActive;
    this.filterItems();
  }

  filterPinnedItemChange() {
    this.filterPinnedItemActive = !this.filterPinnedItemActive;
    this.filterItems();
  }

  filterNonCheckedItemChange() {
    this.filterNonCheckedItemActive = !this.filterNonCheckedItemActive;
    this.filterItems();
  }

  public onChangeSearchEvent(e: string): void {
    this.searchCriteria = e;
    this.filterItems();
  }

  protected filterItems() {
    this.displayItems = [...this.items];

    if (this.filterSelectedItemActive) {
      this.displayItems = this.displayItems.filter(item => this.isInGroup(item.objectId));
    }
    if (this.filterPinnedItemActive) {
      this.displayItems = this.displayItems.filter(item => item.isPinned);
    }
    if (this.filterNonCheckedItemActive) {
      this.displayItems = this.displayItems.filter(item => !item.done);
    }
    if (this.searchCriteria != '') {
      const fieldsToSearch: (keyof HolObject)[] = ['contentText'];
      this.displayItems = performSearch(this.displayItems, this.searchCriteria, fieldsToSearch);
    }
    if (this.selectedTags.length && this.selectedTags.length > 0) {
      this.displayItems = [
        ...this.displayItems.filter(item => {
          const inter = intersectionBy(item.tags, this.selectedTags, (el: any) => {
            return el.objectId;
          });
          return inter.length > 0 && inter.length === this.selectedTags.length;
        }),
      ];
    }
    if (this.selectedFunction && this.moduleConfig.config.moduleName === 'ecl') {
      this.displayItems = this.displayItems.filter(item => item['function'].objectId === this.selectedFunction.objectId);
    }
  }

  public onSelectFunction(event?) {
    this.selectedFunction = event;
    this.filterItems();
  }

  public get isUtc(): boolean {
    return utcEval(HolUTCDisplay.LOCAL_IF_READONLY, this.isReadOnly);
  }

  public isElapsed(date): boolean {
    return moment(date).isBefore(moment());
  }

  public isAlmostElapsed(date): boolean {
    return moment(date).diff(moment(), 'minutes') < this.CONSTANTS.TIME_BEFORE_WARNING;
  }

  public onSelectedItem(objectId: string) {
    const index = this.items.findIndex(item => item.objectId === objectId);
    if ((this.itemsArray.at(index) as UntypedFormGroup).get('inGroup').value === false) {
      // Unchecked inSummaryGroup
      (this.itemsArray.at(index) as UntypedFormGroup).get('inSummaryGroup').setValue(false);
    }
  }

  protected readonly find = find;
}
