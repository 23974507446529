import { Observable } from 'rxjs';
import { EclFunction, EclUserFunction, EclUserFunctionRef } from '../../models/ecl-function';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store';
import { EclFunctionState } from '../../ecl.model';
import {
  AddECLFunction,
  AddECLUser,
  AddECLUserFunction,
  AddECLUserFunctionRef,
  DeleteECLUserFunction,
  DeleteECLUserFunctionRef,
  InitECLFunctions,
  UpdateECLCurrentUserFunction,
  UpdateECLFilterFunction,
  UpdateECLFunction,
  UpdateECLTeamFunction,
  UpdateECLTeamUser,
  UpdateECLUser,
  UpdateManyECLUser,
  UpdateReferential,
  ChangeSelectedCrisis,
  AddECLUserFunctions,
} from './function.action';
import { Injectable } from '@angular/core';
import { HolUser, HolUserWithCompanies } from 'src/app/common/models/hol-user.model';
import { EclCrisis } from '../../models/ecl-crisis';
import { FunctionUserForCrisis } from '../../../erp/models/erp-functionUser';
import { ErpCrisisTask } from '../../../erp/models/erp-crisisTask';
import { EclCrisisTask } from '../../models/ecl-crisis-task-ref';

@Injectable({
  providedIn: 'root',
})
export class EclFunctionStoreManager {
  $eclFunctionState: Observable<EclFunctionState>;
  $userFunctions: Observable<EclFunction[]>;
  $selectedUserFunction: Observable<EclFunction>;
  $filterFunction: Observable<EclFunction>;

  //private $selectedCrisis: Observable<EclCrisis>;

  constructor(private store: Store<AppState>) {
    this.$eclFunctionState = this.store.select('ecl', 'functionState');
    this.$userFunctions = this.store.select('ecl', 'functionState', 'currentUserFunctions');
    this.$selectedUserFunction = this.store.select('ecl', 'functionState', 'selectedUserFunction');
    this.$filterFunction = this.store.select('ecl', 'functionState', 'filterFunction');
    this._allTasks = this.store.select('ecl', 'crisisState', 'tasks');
    // this.$selectedCrisis = store.select('ecl', 'crisisState', 'selectedCrisis');
  }

  initFunctions(
    baseFs: EclFunction[],
    userFs: EclUserFunction[],
    users: HolUserWithCompanies[],
    userRefFs: EclUserFunctionRef[],
    currentCrisis: EclCrisis,
  ) {
    const currentUser: string = Parse.User.current().get('userId');
    this.store.dispatch(
      new InitECLFunctions({
        allFunctions: baseFs,
        allUserFunctions: userFs,
        allUserFunctionsRef: userRefFs,
        allUsers: users,
        currentUserId: currentUser,
        currentCrisis: currentCrisis,
      }),
    );
  }

  updateSelectedUserFunction(newFunction: EclFunction) {
    this.store.dispatch(new UpdateECLCurrentUserFunction(newFunction));
  }

  changeReferential(newReferential: boolean) {
    this.store.dispatch(new UpdateReferential(newReferential));
  }

  updateFilterFunction(newFunction?: EclFunction) {
    this.store.dispatch(new UpdateECLFilterFunction(newFunction));
  }

  updateTeamSelectedFunction(newFunction?: EclFunction) {
    this.store.dispatch(new UpdateECLTeamFunction(newFunction));
  }

  updateTeamSelectedUser(newUser?: HolUserWithCompanies) {
    this.store.dispatch(new UpdateECLTeamUser(newUser));
  }

  addUserFunction(newUserFunction: EclUserFunction) {
    this.store.dispatch(
      new AddECLUserFunction({
        addUserFunction: newUserFunction,
        addCurrentUserId: Parse.User.current().get('userId'),
      }),
    );
  }

  addUserFunctions(newUserFunctions: EclUserFunction[]) {
    this.store.dispatch(
      new AddECLUserFunctions({
        addUserFunctions: newUserFunctions,
        addCurrentUserIds: Parse.User.current().get('userId'),
      }),
    );
  }

  addUserFunctionRef(newUserFunction: EclUserFunctionRef) {
    this.store.dispatch(
      new AddECLUserFunctionRef({
        userFunctionRef: newUserFunction,
        currentUserId: Parse.User.current().get('userId'),
      }),
    );
  }

  deleteUserFunction(userFunction: EclUserFunction) {
    this.store.dispatch(
      new DeleteECLUserFunction({
        userFunction: userFunction,
        currentUserId: Parse.User.current().get('userId'),
      }),
    );
  }

  deleteUserFunctionRef(userFunction: EclUserFunctionRef) {
    this.store.dispatch(
      new DeleteECLUserFunctionRef({
        delUserFunctionRef: userFunction,
        currentUserId: Parse.User.current().get('userId'),
      }),
    );
  }

  addFunction(newFunction: EclFunction) {
    this.store.dispatch(new AddECLFunction(newFunction));
  }

  updateFunction(existingFunction: EclFunction) {
    this.store.dispatch(new UpdateECLFunction(existingFunction));
  }

  addUser(newUser: HolUserWithCompanies) {
    this.store.dispatch(new AddECLUser(newUser));
  }

  updateUser(updatedUser: HolUserWithCompanies) {
    this.store.dispatch(new UpdateECLUser(updatedUser));
  }

  updateSeveralUser(updatedUsers: HolUser[]) {
    this.store.dispatch(new UpdateManyECLUser(updatedUsers));
  }

  changeSelectedCrisis(userFunctions: EclUserFunction[], baseFs: EclFunction[]) {
    this.store.dispatch(
      new ChangeSelectedCrisis({
        eclFunction: userFunctions,
        baseFs: baseFs,
      }),
    );
  }

  functionsAllowedToAddTask(eclCrisis: EclCrisis): Observable<FunctionUserForCrisis[]> {
    //todo
    return new Observable<FunctionUserForCrisis[]>(observer => {
      observer.next([]);
      observer.complete();
    });
  }

  private readonly _allTasks: Observable<EclCrisisTask[]>;

  get allTasks(): Observable<EclCrisisTask[]> {
    return this._allTasks;
  }
}
