import { InjectionToken } from '@angular/core';
import { ActionReducer, ActionReducerMap, combineReducers, MetaReducer } from '@ngrx/store';
import { ErpStoreState } from '../erp/erp.model';
import { crisisReducer } from '../erp/store/crisis/crisis.reducer';
import { functionsReducer } from '../erp/store/functions/functions.reducer';
import { oclEventsReducer } from '../ocl/store/events/ocl-events.reducer';
import { oclLogBooksReducer } from '../ocl/store/logbooks/ocl-logbooks.reducer';
import { oclLinkedItemsReducer } from '../ocl/store/linked-items/ocl-linked-items.reducer';
import { oclLinkedDisplayedItemsReducer } from '../ocl/store/linked-display-items/ocl-linked-displayed-items.reducer';
import { OclDecisionsReducer } from '../ocl/store/decisions/ocl-decisions.reducer';
import { OclStoreState } from '../ocl/ocl.model';
import { CommonStoreState } from '../common/store/common.model';
import { commonReducer } from '../common/store/common.reducer';
import { MccStoreState } from '../mcc/mcc.model';
import { mccAircraftsReducer } from '../mcc/store/aircrafts/mcc-aircrafts.reducer';
import { mccImpactFilterReducer } from '../mcc/store/impact-filter/mcc-impact-filter.reducer';
import { oclGlobalInstructionsReducer } from '../ocl/store/global-instructions/ocl-global-instructions.reducer';
import { LayoutReducer } from '../erp/store/layout/layout.reducer';
import { oclTasksReducer } from '../ocl/store/tasks/ocl-tasks.reducer';
import { oclGroupsReducer } from '../ocl/store/groups/ocl-groups.reducer';
import { oclTagsReducer } from '../ocl/store/tags/ocl-tags.reducer';
import { oclScenariosReducer } from '../ocl/store/scenarios/ocl-scenarios.reducer';
import { EclStoreState } from '../ecl/ecl.model';
import { eclFunctionsReducer } from '../ecl/store/function/function.reducer';
import { eclCrisisReducer } from '../ecl/store/crisis/crisis.reducer';
import { eclAssetReducer } from '../ecl/store/asset/asset.reducer';
import { EclLayoutReducer } from '../ecl/store/layout/ecl-layout.reducer';

export function getErpReducer(): ActionReducer<any> {
  return combineReducers({
    crisis: crisisReducer,
    functions: functionsReducer,
    layout: LayoutReducer,
  });
}

export function getOclReducer(): ActionReducer<any> {
  return combineReducers({
    events: oclEventsReducer,
    decisions: OclDecisionsReducer,
    logBooks: oclLogBooksReducer,
    linkedItemsId: oclLinkedItemsReducer,
    linkedDisplayedItems: oclLinkedDisplayedItemsReducer,
    globalInstructions: oclGlobalInstructionsReducer,
    tasks: oclTasksReducer,
    groups: oclGroupsReducer,
    tags: oclTagsReducer,
    scenarios: oclScenariosReducer,
  });
}

export function getMccReducer(): ActionReducer<any> {
  return combineReducers({
    aircrafts: mccAircraftsReducer,
    impactFilter: mccImpactFilterReducer,
  });
}

export function getEclReducer(): ActionReducer<any> {
  return combineReducers({
    functionState: eclFunctionsReducer,
    crisisState: eclCrisisReducer,
    assetState: eclAssetReducer,
    layout: EclLayoutReducer,
  });
}

// Le root reducer
const reducers = {
  erp: getErpReducer(),
  ocl: getOclReducer(),
  mcc: getMccReducer(),
  ecl: getEclReducer(),
  common: commonReducer,
};

export interface AppState {
  erp: ErpStoreState;
  ocl: OclStoreState;
  mcc: MccStoreState;
  ecl: EclStoreState;
  common: CommonStoreState;
}

// Nécéssaire pour l'AOT
export function getReducers() {
  return reducers;
}

// Nécéssaire pour l'AOT
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');

export function loggingMetaReducer(reducer) {
  return (state, action) => {
    // tslint:disable-next-line
    if (window['HolisDebug'] === true) {
      console.group(action.type);
      console.log('state:', state);
      console.log('action:', action);
      console.log('nextState:', reducer(state, action));
      console.groupEnd();
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [loggingMetaReducer];
