<div class="sidebar">
  <div class="sidebar__logo">
    <img [src]="companyLogoUrl" alt="logo" />
  </div>
  <div class="module-selector" (mouseenter)="toggleDropdown()" (mouseleave)="toggleDropdown()">
    <div class="selected-module">
      <span>{{ 'MENU.' + currentModule | translate }}</span>
      <mat-icon>chevron_right</mat-icon>
    </div>

    <div class="dropdown-menu-nav" *ngIf="dropdownOpen">
      <div [uiSref]="ModuleMetadata[module].state + '.dashboard'" class="dropdown-item-nav" *ngFor="let module of otherModules">
        {{ 'MENU.' + module | translate }}
      </div>
    </div>
  </div>

  <!--
  <ng-container>
    <div class="menu-item" [uiSref]="ModuleMetadata[currentModule] + '.dashboard'">
      {{ "MENU." + currentModule | translate}}
    </div>

  </ng-container>
  -->
  <ng-container *ngIf="currentModule">
    <div [uiSref]="ModuleMetadata[currentModule].state + '.dashboard'" class="menu-item" [uiSrefActive]="'active'">
      <mat-icon>dashboard</mat-icon>
      <span class="last-child">{{ 'MENU.DASHBOARD' | translate }}</span>
    </div>
    <div [uiSref]="ModuleMetadata[currentModule].state + '.checklist'" class="menu-item" [uiSrefActive]="'active'">
      <mat-icon>lists</mat-icon>
      <span class="last-child">{{ 'MENU.CHECKLISTS' | translate }}</span>
    </div>
    <div [uiSref]="ModuleMetadata[currentModule].state + '.team'" class="menu-item" [uiSrefActive]="'active'">
      <mat-icon>groups_3</mat-icon>
      <span class="last-child">{{ 'MENU.TEAM' | translate }}</span>
    </div>
    <div [uiSref]="ModuleMetadata[currentModule].state + '.list'" class="menu-item" [uiSrefActive]="'active'">
      <mat-icon>groups_3</mat-icon>
      <span class="last-child">{{ 'MENU.LIST_CRISIS' | translate }}</span>
    </div>
    <!--
    <ng-container *ngFor="let module of otherModules">
      <div class="menu-item" [uiSref]="ModuleMetadata[module].state + '.dashboard'">
        <mat-icon>newsmode</mat-icon>
        <span>{{ "MENU." + module | translate}}</span>
      </div>
    </ng-container>
    -->
  </ng-container>
</div>
