<!-- ITEMS IN GROUP SECTION START -->
<ng-container *ngIf="group.objectId && ($state.current.name === 'app.ecl.dashboard' || group.items.length > 0)">
  <mat-expansion-panel
    (closed)="isOpened = false"
    (opened)="isOpened = true"
    *ngIf="group.objectId && group.items | aclFilter : RolesService.companiesRolesFilter; let filteredItems"
    [class.border-with-color]="type !== 'EVENT'"
    [ngClass]="{
      onsummary: isOnSummaryFunc(),
      briefing: isOnSummaryBriefingFunc(),
      summaryNotBrief: isOnSummaryFunc() && !isOnSummaryBriefingFunc()
    }"
    class="occ-event-group-item"
  >
    <mat-expansion-panel-header class="event-group-header" collapsedHeight="auto" expandedHeight="auto">
      <div class="event-item__header">
        <!--In Case inside ECL module-->
        <div *ngIf="moduleConfig.config.moduleName === 'ecl'" class="first-line">
          <button
            (click)="onEditGroup()"
            *ngIf="!isReadOnly && userCanEditCards"
            [matTooltipDisabled]="type === 'EVENT' || type === 'GLOBAL_INSTRUCTION'"
            [matTooltip]="groupSummaryTooltip | translate"
            class="group-btn-invert"
            mat-raised-button
          >
            <mat-icon>scatter_plot</mat-icon>
            <!--    <img src="../../../../assets/images/group-attach-white.svg"/>-->
          </button>
          <span class="event-item-title-big">{{ group.title }}</span>
          <span class="space"></span>
          <button
            (click)="openAddModal($event)"
            *ngIf="!isReadOnly && userCanEditCards"
            aria-label="Add item"
            class="md-icon-button-inverse md-raised"
            mat-icon-button=""
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <!--In Case Other than ECL module-->
        <div *ngIf="moduleConfig.config.moduleName !== 'ecl'" class="first-line">
          <button (click)="onEditGroup()" *ngIf="!isReadOnly" class="group-btn" mat-raised-button>
            <mat-icon>scatter_plot</mat-icon>
            <!-- <img src="../../../../assets/images/group-attach-white.svg"/>-->
          </button>
          <span class="event-item-title-big">{{ group.title }}</span>
          <span class="space"></span>
          <button
            *ngIf="!isReadOnly"
            (click)="openAddModal($event)"
            aria-label="Add item"
            class="md-icon-button-inverse md-raised"
            mat-icon-button=""
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <div *ngIf="mostCriticalNextInfo && mostCriticalNextInfo.message && type === 'EVENT'" class="next-info-message-line">
          {{ mostCriticalNextInfo.message }}
        </div>
        <div *ngIf="decisionToDisplay && decisionToDisplay.message && type === 'DECISION'" class="next-info-message-line">
          {{ decisionToDisplay.message }}
        </div>
        <div *ngIf="mostRecentLogBook && mostRecentLogBook.text && type === 'LOGBOOK'" class="next-info-message-line">
          {{ mostRecentLogBook.text }}
        </div>
        <div
          *ngIf="globalInstructionToDisplay && globalInstructionToDisplay.description && type === 'GLOBAL_INSTRUCTION'"
          class="next-info-message-line"
        >
          {{ globalInstructionToDisplay.description }}
        </div>
        <div *ngIf="!mostCriticalNextInfo && type === 'EVENT'" class="next-info-message-line">No next info</div>
        <div
          *ngIf="decisionToDisplay && decisionToDisplay.nextInfoTime && type === 'DECISION'"
          [class.almost-elapsed]="isAlmostElapsed(decisionToDisplay.nextInfoTime)"
          [class.elapsed]="isElapsed(decisionToDisplay.nextInfoTime)"
          [class.hol-done]="decisionToDisplay.done"
          class="next-info-line"
        >
          <span>{{ decisionToDisplay.nextInfoTime | time : isUtc }}</span>
        </div>
        <div
          *ngIf="mostCriticalNextInfo && type === 'EVENT'"
          [class.almost-elapsed]="isAlmostElapsed(mostCriticalNextInfo.nextInfoTime)"
          [class.elapsed]="isElapsed(mostCriticalNextInfo.nextInfoTime)"
          [class.hol-affected]="mostCriticalNextInfo.done && !mostCriticalNextInfo.manual"
          [class.hol-done]="mostCriticalNextInfo.done && mostCriticalNextInfo.manual"
          class="next-info-line"
        >
          <span> {{ mostCriticalNextInfo.nextInfoTime | nextInfoTime : isUtc }}</span>
        </div>
        <div class="indicator">
          <span *ngIf="filteredItems.length > 1"
            >{{ filteredItems.length }} {{ moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.GROUP_TYPES' | translate }}</span
          >
          <span *ngIf="filteredItems.length === 1 || !filteredItems.length"
            >{{ filteredItems.length }} {{ moduleConfig.config.translateKey + '.DASHBOARD.' + i18nkey + '.GROUP_TYPE' | translate }}</span
          >
          <i *ngIf="filteredItems.length" [class.opened]="isOpened" class="glyphicon glyphicon-menu-down indicator-icon"></i>
        </div>
      </div>
    </mat-expansion-panel-header>
    <ul class="hol-list">
      <ng-container *ngFor="let item of filteredItems; trackBy: trackByFunction">
        <li [@EnterLeave]="'flyIn'" [ngClass]="{ 'not-seen': !isSeen(item) }">
          <ng-container *ngIf="type === 'EVENT'" [ngTemplateOutletContext]="{ item: item }" [ngTemplateOutlet]="eventItem"></ng-container>
          <ng-container
            *ngIf="type === 'DECISION'"
            [ngTemplateOutletContext]="{ item: item }"
            [ngTemplateOutlet]="decisionItem"
          ></ng-container>
          <ng-container
            *ngIf="type === 'LOGBOOK'"
            [ngTemplateOutletContext]="{ item: item }"
            [ngTemplateOutlet]="logBookItem"
          ></ng-container>
          <ng-container
            *ngIf="type === 'GLOBAL_INSTRUCTION'"
            [ngTemplateOutletContext]="{ item: item }"
            [ngTemplateOutlet]="globalInstructionItem"
          ></ng-container>
        </li>
      </ng-container>
    </ul>
  </mat-expansion-panel>
</ng-container>

<!-- ITEMS IN GROUP SECTION END -->

<!-- ITEMS ONLY SECTION -->
<ul *ngIf="!group.objectId" class="hol-list">
  <li [ngClass]="{ 'not-seen': !isSeen(group.items[0]) }">
    <ng-container
      *ngIf="type === 'EVENT'"
      [ngTemplateOutletContext]="{ item: group.items[0] }"
      [ngTemplateOutlet]="eventItem"
    ></ng-container>
    <ng-container
      *ngIf="type === 'DECISION'"
      [ngTemplateOutletContext]="{ item: group.items[0] }"
      [ngTemplateOutlet]="decisionItem"
    ></ng-container>
    <ng-container
      *ngIf="type === 'LOGBOOK'"
      [ngTemplateOutletContext]="{ item: group.items[0] }"
      [ngTemplateOutlet]="logBookItem"
    ></ng-container>
    <ng-container
      *ngIf="type === 'GLOBAL_INSTRUCTION'"
      [ngTemplateOutletContext]="{ item: group.items[0] }"
      [ngTemplateOutlet]="globalInstructionItem"
    ></ng-container>
  </li>
</ul>
<!-- ITEMS ONLY SECTION END-->

<!-- TEMPLATES ITEMS SECTION -->
<ng-template #eventItem let-item="item">
  <app-ocl-event-item
    (updatedEvent)="onEventUpdated($event)"
    [eclInfos]="eclInfos"
    [event]="group.objectId ? item : group.items[0]"
    [isReadOnly]="isReadOnly"
    [linkedIds]="linkedIds"
    [userCanEditCards]="userCanEditCards"
    [utcDisplay]="utcDisplay"
  >
    <div *ngIf="item.function && item.function.objectId" class="event-ecl-addon" style="margin-bottom: 5px">
      <app-ecl-user-function-detail [function]="item.function"></app-ecl-user-function-detail>
    </div>
  </app-ocl-event-item>
</ng-template>

<ng-template #decisionItem let-item="item">
  <app-ocl-decisions-list-item
    [airportsCode]="airportsCode"
    [events]="events"
    [group]="group"
    [isReadOnly]="isReadOnly"
    [isSummaryActivated]="isSummaryActivated"
    [item]="group.objectId ? item : group.items[0]"
    [linkedIds]="linkedIds"
    [logBookItems]="logBookItems"
    [modeLinkIsActivate]="modeLinkIsActivate"
    [summaryGroup]="group.summary"
    [userCanEditCards]="userCanEditCards"
    [utcDisplay]="utcDisplay"
  ></app-ocl-decisions-list-item>
</ng-template>

<ng-template #logBookItem let-item="item">
  <app-ocl-logbook-list-item
    [airportsCode]="airportsCode"
    [decisionItems]="decisionItems"
    [events]="events"
    [group]="group"
    [isReadOnly]="isReadOnly"
    [isSummaryActivated]="isSummaryActivated"
    [item]="group.objectId ? item : group.items[0]"
    [linkedIds]="linkedIds"
    [modeLinkIsActivate]="modeLinkIsActivate"
    [summaryGroup]="group.summary"
    [userCanEditCards]="userCanEditCards"
    [utcDisplay]="utcDisplay"
  >
  </app-ocl-logbook-list-item>
</ng-template>

<ng-template #globalInstructionItem let-item="item">
  <app-ocl-global-instruction-item
    [isReadOnly]="isReadOnly"
    [item]="group.objectId ? item : group.items[0]"
    [notifications]="notifications"
    [userCanEditCards]="userCanEditCards"
    [utcDisplay]="utcDisplay"
  ></app-ocl-global-instruction-item>
</ng-template>
<!-- TEMPLATES ITEMS SECTION END -->
