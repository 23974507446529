import { ISmsMessage, SmsSenderService } from './../../../common/services/sms/sms-sender.service';
import { Inject, Injectable } from '@angular/core';
import { OclSmsService } from '../../../ocl/services/ocl-sms-service/ocl-sms.service';
import { EclModuleConfigService } from '../ecl-module-config/ecl-module-config.service';
import { EclAnnouncement } from '../../models/ecl-annoucement';
import { ErpCrisis } from '../../../erp/models/erp-crisis';
import { HolUser, HolUserWithCompanies } from '../../../common/models/hol-user.model';
import { take } from 'rxjs/operators';
import { EclCrisis } from '../../models/ecl-crisis';
import { EclUsersService } from '../ecl-users-service/ecl-users.service';
import { CommonStoreManager } from '../../../common/store/common.store-manager';
import { HolUserWithFunctions } from '../../../erp/services/erp-users.service';
import { EclCrisisDirectorStoreManager } from '../../store/crisis-director/ecl-crisis-director.store-manager';
import { mergeDirectorsAndUsers } from '../../common/user-utils';
import { EclFunction, EclOwnerNotificationData } from '../../models/ecl-function';
import { TranslateService } from '@ngx-translate/core';
import { OclTask } from '../../../ocl/models/ocl-task';
import { EclCrisisTask } from '../../models/ecl-crisis-task-ref';

@Injectable({
  providedIn: 'root',
})
export class EclSmsService extends OclSmsService {
  constructor(
    protected readonly smsSenderService: SmsSenderService,
    @Inject('CONSTANTS') protected CONSTANTS,
    protected translate: TranslateService,
    protected moduleConfig: EclModuleConfigService,
    protected usersService: EclUsersService,
    protected eclCrisisDirectorStoreManager: EclCrisisDirectorStoreManager,
    protected commonStoreManager: CommonStoreManager,
  ) {
    super(smsSenderService, CONSTANTS, translate, moduleConfig);

    this.translate.onLangChange.subscribe(() => {
      this.SENDER_SUFFIX = this.translate.instant('SMS.ECL.SENDER_SUFFIX') || 'ECL';
    });
  }

  sendCrisisAnnouncementSms(eclAnnouncement: EclAnnouncement, user, phoneNumbersToSend: string[]) {
    const messages: ISmsMessage[] = [];
    for (const phoneNumber of phoneNumbersToSend) {
      messages.push({
        to: phoneNumber,
        text: this.getECLAnnouncementMessage(eclAnnouncement, user),
      });
    }

    this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + '_' + this.SENDER_SUFFIX);
  }

  async sendNewCrisisSms(crisis: EclCrisis, userToNotifyFromUser: string[]) {
    // We're sending emails for people:
    // 1. Who has crisis director rights
    // OR
    // 2. Whose functions are included in crisis.crisisType.functionsToNotify
    return Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]).then(async ([crisisDirectors, usersWithFunctions, currentUser]) => {
      let users = mergeDirectorsAndUsers(crisisDirectors, usersWithFunctions);

      if (userToNotifyFromUser) {
        users = users.filter(us => userToNotifyFromUser.includes(us.userId));
      }
      const messages: ISmsMessage[] = [];
      for (const user of users) {
        if (user.phone) {
          messages.push({
            to: user.phone,
            text: this.getNewCrisisMessage(crisis, user, currentUser),
          });
        }
      }
      if (messages.length > 0) {
        await this.smsSenderService.sendMultipleSms(messages, false, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
      }
    });
  }

  async sendCloseCrisisSms(crisis: EclCrisis) {
    // We're sending emails for people:
    // 1. Who has crisis director rights
    // OR
    // 2. Whose functions are included in crisis.crisisType.functionsToNotify
    return Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
    ]).then(async ([crisisDirectors, usersWithFunctions]) => {
      const users = mergeDirectorsAndUsers(crisisDirectors, usersWithFunctions);
      const messages: ISmsMessage[] = [];
      for (const user of users) {
        if (user.phone) {
          messages.push({
            to: user.phone,
            text: this.translate.instant('SMS.CRISIS_OVER.CONTENT_TEXT', { crisisMainTitle: crisis.mainTitle }),
          });
        }
      }
    });
  }

  async sendSmsAddMemberOfFunction(user: HolUser, crisis: EclCrisis, newFunction: EclFunction) {
    const [directorsToNotify, usersToNotify, currentUser] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]);

    let msg = this.translate.instant('SMS.ECL.CRISIS.MEMBER_ADD.CONTENT_TEXT', {
      crisisMainTitle: crisis.mainTitle,
      functionName: newFunction.title,
      user: currentUser.fullName,
    });
    msg += this.translate.instant('MAIL.ECL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin });

    const messages: ISmsMessage[] = [];
    if (user.phone) {
      messages.push({
        to: user.phone,
        text: msg,
      });
    }
    await this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  async sendAcessDone(user: HolUser, crisis: EclCrisis) {
    const [directorsToNotify, usersToNotify, currentUser] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]);

    let msg = this.translate.instant('SMS.ECL.CRISIS.MEMBER_REMOVE.CONTENT_TEXT', {
      crisisName: crisis.mainTitle,
    });

    msg += this.translate.instant('MAIL.ECL.CRISIS.PLEASE_LOGIN', { appUrl: location.origin });

    const messages: ISmsMessage[] = [];
    if (user.phone) {
      messages.push({
        to: user.phone,
        text: msg,
      });
    }
    await this.smsSenderService.sendMultipleSms(messages, true, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  async sendReopenCrisisSms(crisis: EclCrisis) {
    // We're sending emails for people:
    // 1. Who has crisis director rights
    // OR
    // 2. Whose functions are included in crisis.crisisType.functionsToNotify
    const [crisisDirectors, usersWithFunctions, currentUser] = await Promise.all([
      this.eclCrisisDirectorStoreManager.$eclRealCrisisDirectorsWithFunctions().pipe(take(1)).toPromise(),
      this.usersService.getUsersWithFunctionsForCrisis(crisis),
      this.commonStoreManager.currentUser.pipe(take(1)).toPromise(),
    ]);
    const users = mergeDirectorsAndUsers(crisisDirectors, usersWithFunctions);
    const messages: ISmsMessage[] = [];
    for (const user of users) {
      if (user.phone) {
        messages.push({
          to: user.phone,
          text: this.getNewCrisisMessage(crisis, user, currentUser, true),
        });
      }
    }
    await this.smsSenderService.sendMultipleSms(messages, false, this.CONSTANTS.COMPANY_NAME + this.CONSTANTS.CRISIS_SUFFIX);
  }

  private getECLAnnouncementMessage(eclAnnouncement: EclAnnouncement, user: any): string {
    return this.translate.instant('SMS.ECL.ANNOUNCEMENT.NEW_ENTRY', {
      eclMainTitle: eclAnnouncement.crisisMainTitle,
      eclAnnouncement: eclAnnouncement.message,
      user,
    });
  }

  private getNewCrisisMessage(crisis: EclCrisis, user: HolUserWithFunctions, currentUser: HolUser, isReopen: boolean = false) {
    const separator = '\n';
    let msg =
      (crisis.isTraining
        ? this.translate.instant('SMS.ECL.NEW_CRISIS.EXERCISE', { crisisMainTitle: crisis.mainTitle })
        : this.translate.instant('SMS.ECL.NEW_CRISIS.NOT_EXERCISE', { crisisMainTitle: crisis.mainTitle })) +
      separator +
      separator;
    msg +=
      this.translate.instant(isReopen ? 'SMS.ECL.NEW_CRISIS.REOPEN' : 'SMS.ECL.NEW_CRISIS.ACTIVATION', {
        erdUserName: currentUser.fullName,
        crisis,
      }) +
      separator +
      separator;

    msg +=
      this.translate.instant('SMS.ECL.NEW_CRISIS.FIRST_ACTIONS', {
        appUrl: location.origin,
        erdPhone: currentUser.phone || '',
        erdUserName: currentUser.fullName,
      }) +
      separator +
      separator;

    msg += this.translate.instant('SMS.ECL.NEW_CRISIS.ADVICES', { appUrl: location.origin });
    return msg;
  }

  sendErpActivateCrisisSms(savedCrisis: EclCrisis, holUserWithFunctions: HolUserWithFunctions[]) {
    const separator = '\n';
    let msg =
      (savedCrisis.isTraining
        ? this.translate.instant('SMS.ECL.ERP_ADD.EXERCISE', { crisisMainTitle: savedCrisis.mainTitle })
        : this.translate.instant('SMS.ECL.ERP_ADD.NOT_EXERCISE', { crisisMainTitle: savedCrisis.mainTitle })) +
      separator +
      separator;
    msg += this.translate.instant('SMS.ECL.ERP_ADD.ACTIVATION', { crisis: savedCrisis }) + separator + separator;

    msg +=
      this.translate.instant('SMS.ECL.ERP_ADD.FIRST_ACTIONS', {
        appUrl: location.origin,
      }) +
      separator +
      separator;
    msg += this.translate.instant('SMS.ECL.ERP_ADD.ADVICES', { appUrl: location.origin });

    const messages: ISmsMessage[] = [];
    for (const user of holUserWithFunctions) {
      if (user.phone) {
        messages.push({
          to: user.phone,
          text: msg,
        });
      }
    }
    this.smsSenderService.sendMultipleSms(messages, false, this.CONSTANTS.COMPANY_NAME + '_' + this.SENDER_SUFFIX).then();
  }

  sendNewOwner(data: EclOwnerNotificationData, onlyOwner: boolean = false) {
    const separator = '\n';
    let msg =
      (data.eclCrisis.isTraining
        ? this.translate.instant('SMS.ECL.NEW_CRISIS.EXERCISE', { crisisMainTitle: data.eclCrisis.mainTitle })
        : this.translate.instant('SMS.ECL.NEW_CRISIS.NOT_EXERCISE', { crisisMainTitle: data.eclCrisis.mainTitle })) +
      separator +
      separator;

    let msgAll = msg;

    msg +=
      this.translate.instant('SMS.ECL.OWNER_ADD.YOUR_OWNER', {
        functionName: data.functionName,
        acl: data.acl,
      }) +
      separator +
      separator;
    msgAll +=
      this.translate.instant('SMS.ECL.OWNER_ADD.THIS_OWNER', {
        functionName: data.functionName,
        acl: data.acl,
        userName: data.owner.fullName,
      }) +
      separator +
      separator;

    if (onlyOwner) {
      msg += this.translate.instant('SMS.ECL.OWNER_ADD.ONLY_OWNER_NOTIF') + separator + separator;
    } else {
      msg += this.translate.instant('SMS.ECL.OWNER_ADD.ALL_NOTIF') + separator;
      msgAll += this.translate.instant('SMS.ECL.OWNER_ADD.ALL_NOTIF') + separator;
      for (const user of data.users) {
        if (user.phone) {
          msg += user.fullName + separator;
          msgAll += user.fullName + separator;
        }
      }
      msg += separator + separator;
    }

    msg +=
      this.translate.instant('SMS.ECL.ERP_ADD.FIRST_ACTIONS', {
        appUrl: location.origin,
      }) +
      separator +
      separator;

    msgAll +=
      this.translate.instant('SMS.ECL.ERP_ADD.FIRST_ACTIONS', {
        appUrl: location.origin,
      }) +
      separator +
      separator;

    const messages: ISmsMessage[] = [];

    if (data.owner.phone) {
      messages.push({
        to: data.owner.phone,
        text: msg,
      });
    }

    for (const user of data.users) {
      if (user.phone) {
        messages.push({
          to: user.phone,
          text: msgAll,
        });
      }
    }
    this.smsSenderService.sendMultipleSms(messages, false, this.CONSTANTS.COMPANY_NAME + '_' + this.SENDER_SUFFIX).then();
  }
}
