import { OclGroup } from './ocl-group.model';
import { OclLogbook } from './ocl-logbook.model';
import { orderBy } from 'lodash';

export class OclLogBookGroup<T extends OclLogbook = OclLogbook> extends OclGroup<T> {
  logbooksInSummary: T[];

  constructor(parseObject?: Parse.Object) {
    super(parseObject);
    if (!parseObject) {
      return;
    }

    const itemsParse: T[] =
      parseObject.get('logBooks') && parseObject.get('logBooks').length
        ? parseObject.get('logBooks').map(lg => new OclLogbook(lg) as T)
        : [];
    this.items = itemsParse.sort((a, b) => {
      const dateA = new Date(a.customCreatedAt || a.createdAt).getTime() || 0;
      const dateB = new Date(b.createdAt).getTime() || 0;
      return dateB - dateA;
    });

    this.logbooksInSummary =
      parseObject.get('logbooksInSummary') && parseObject.get('logbooksInSummary').length
        ? orderBy(
            parseObject.get('logbooksInSummary').map(lg => new OclLogbook(lg) as T),
            'createdAt',
            'desc',
          )
        : [];
  }

  getMostRecentLogBook(): OclLogbook {
    return this.items[0];
  }

  protected newItem(parseObject?: Parse.Object): T {
    return new OclLogbook(parseObject) as T;
  }
}
